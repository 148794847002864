import styled from "styled-components";
import Linkify from "linkify-react";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import { AWS_USER_UPLOAD_URL } from "constants";

import doubleTickGreyIcon from "assets/img/icons/icons8-double-tick-grey.svg";
import doubleTickBlueIcon from "assets/img/icons/icons8-double-tick-blue.svg";

const Bubble = styled.div`
  max-width: 440px;
  padding: 11px 16px 8px;
  min-width: 0;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  align-items: stretch;
  gap: 12px;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 9px 14px 6px;
  }

  &:hover {
    cursor: ${(props) => (props.postmessage ? "pointer" : "default")};
    background: ${(props) =>
      props.postmessage
        ? css.FLAT_BOX_HOVER_BACKGROUND
        : css.FLAT_BOX_BACKGROUND};
  }
`;

const LeftPart = styled.div``;

const Image = styled.div`
  width: 140px;
  aspect-ratio: 1.29;

  border-radius: ${css.BOX_RADIUS_SECONDARY};

  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 110px;
    aspect-ratio: 1;
  }
`;

const RightPart = styled.div`
  min-width: 0;

  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const SmallContentBox = styled.div`
  height: 15px;

  display: flex;
  justify-content: flex-end;
  gap: 4px;
`;

const Text = styled.div`
  white-space: pre-line;
  word-wrap: break-word;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.postmessage ? 5 : "none")};
  line-clamp: ${(props) => (props.postmessage ? 5 : "none")};
  -webkit-box-orient: vertical;

  flex: 1;

  > a {
    color: ${css.LIGHT_BLUE};
    text-decoration: none;
  }
`;

const BubbleTime = styled.div`
  font-size: 11px;
  color: ${css.TEXT_GREY};
`;

const ReadIcon = styled.div`
  width: 15px;
  min-width: 15px;
  height: 15px;
`;

export default ({
  children,
  time,
  own,
  read,
  postPreviewId,
  postSnapshotShardNumber,
  image,
  onBubbleClick,
}) => {
  return (
    <Bubble
      onClick={() => onBubbleClick(postPreviewId, postSnapshotShardNumber)}
      postmessage={postPreviewId ? 1 : 0}
    >
      {image && (
        <LeftPart>
          <Image image={AWS_USER_UPLOAD_URL + image} />
        </LeftPart>
      )}
      <RightPart>
        <Linkify
          as={Text}
          options={{
            target: "_blank",
          }}
          postmessage={postPreviewId ? 1 : 0}
        >
          {children}
        </Linkify>
        <SmallContentBox>
          <BubbleTime>{time}</BubbleTime>
          {own && (
            <ReadIcon>
              <img
                src={read ? doubleTickBlueIcon : doubleTickGreyIcon}
                alt="Read"
                style={{ width: "100%" }}
              />
            </ReadIcon>
          )}
        </SmallContentBox>
      </RightPart>
    </Bubble>
  );
};
