import styled from "styled-components";
import { useEffect, useState } from "react";
import GraphemeSplitter from "grapheme-splitter";

import Typewriter from "typewriter-effect";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import scalelupLogo from "assets/img/logo/scalelup.svg";
import coinsIcon from "assets/img/icons/coins-icon.svg";
import returnIcon from "assets/img/icons/return-icon.svg";
import profileImage from "assets/img/landingPage/mockup-profile-image.png";
import smileyIcon from "assets/img/icons/icons8-smiley.svg";
import templateIcon from "assets/img/icons/template-icon.svg";
import chatProfileImage from "assets/img/landingPage/chat-profile-image.jpg";
import dotsEmptyIcon from "assets/img/icons/icons8-dots-empty.svg";
import chatPostImage from "assets/img/landingPage/chat-post-image.jpg";
import doubleTickGreyIcon from "assets/img/icons/icons8-double-tick-grey.svg";
import doubleTickBlueIcon from "assets/img/icons/icons8-double-tick-blue.svg";
import pointsIcon from "assets/img/icons/points-icon.svg";
import dollarIcon from "assets/img/icons/dollar-icon.svg";
import calendarIcon from "assets/img/icons/calendar-icon.svg";
import bulbIcon from "assets/img/icons/tip-bulb.svg";
import discoverIcon from "assets/img/icons/menu-discover-white.svg";
import referralsIcon from "assets/img/icons/menu-referrals.svg";
import rightUpArrowIcon from "assets/img/icons/icons8-right-up-arrow.svg";

const Display = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  width: 230px;
  height: 500px;

  font-family: "Barlow", sans-serif;

  transform: translateX(-50%);

  background-color: #1f2c39;
`;

const Header = styled.div`
  width: 100%;
  margin: 0;

  position: relative;
  backdrop-filter: blur(6px);
  z-index: 10;
`;

const HeaderContent = styled.div`
  padding: 3px 10px;
  margin: 0 auto;
  max-width: 1600px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoWrap = styled.div`
  width: 90px;
`;

const RightPart = styled.div``;

const BalanceBox = styled.div`
  height: 30px;
  padding: 0px 10px;
  min-width: 20px;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const Icon = styled.div`
  width: 10px;
  min-width: 10px;
  height: 10px;

  display: flex;
`;

const Balance = styled.div`
  font-size: 10px;
  font-weight: 500;
`;

const CreatePost = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 7px;

  display: flex;
  flex-direction: column;
`;

const CreatePostHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PageTitle = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};
  margin-bottom: 10px;

  font-size: 16px;
  font-weight: 400;
`;

const RoundActionCircle = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;

  border-radius: 13px;
  background: ${css.ROUND_ICON_BACKGROUND};

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const RoundActionIcon = styled.div`
  padding-top: 1px;
  width: 9px;
  height: 9px;

  display: flex;
`;

const BackIconWrap = styled.div``;

const FormBox = styled.div`
  width: 100%;
  padding: 15px 10px;
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const FormHeader = styled.div`
  display: flex;
  gap: 6px;
`;

const AvatarIconWrap = styled.div`
  width: 36px;
  min-width: 36px;
  aspect-ratio: 1;
`;

const AvatarIcon = styled.img`
  width: 100%;
  border-radius: 50%;
`;

const FormHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.div`
  padding-top: 6px;

  font-size: 11px;
  font-weight: 500;
`;

const PostInfo = styled.div`
  padding-top: 1px;

  font-size: 9px;
  color: ${css.TEXT_GREY};
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  flex: 1;
`;

const TextInputBox = styled.div`
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  background: ${css.DARK_BLUE};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  flex-direction: column;
`;

const TextArea = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  height: 174px;

  font-family: "Roboto";
  font-weight: 300;
  font-size: 10px;
  color: #fff;
  line-height: 125%;

  overflow: hidden;

  background: ${css.DARK_BLUE};
  border: 0px;
  border-radius: 10px;
  resize: none;
`;

const TextInputFooter = styled.div`
  height: 34px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CharacterCounter = styled.div`
  position: relative;
  margin-left: 18px;
`;

const EmptyCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 13px;
  height: 13px;
  transform: translate(-50%, -50%);

  border-radius: 50%;
  background: rgb(255, 255, 255, 0.07);
`;

const OuterCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 13px;
  height: 13px;
  transform: translate(-50%, -50%);

  border-radius: 50%;
  background: ${(props) => (props.atmax ? css.ERROR_RED : css.BRAND_INDIGO)};
  mask: conic-gradient(
    from 0deg,
    #0000,
    #000 1deg ${(props) => props.angle}deg,
    #0000 calc(${(props) => props.angle}deg + 1deg)
  );
`;

const InnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px;
  height: 9px;
  transform: translate(-50%, -50%);

  background: ${css.DARK_BOX_BACKGROUND};
  border-radius: 50%;
`;

const RightControls = styled.div`
  padding: 0 12px 0 15px;

  display: flex;
  align-items: center;
`;

const TextAreaIconCircle = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;

  border-radius: 10px;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextAreaIcon = styled.div`
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  height: ${(props) => props.width};

  display: flex;
`;

const InputWithLabel = styled.div`
  width: ${(props) => props.width};
  margin-top: ${(props) => (props.margintop ? props.margintop : "0px")};

  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  padding-left: 4px;

  display: flex;
  align-items: center;
  gap: 6px;
`;

const LabelText = styled.div`
  font-size: 10px;
  font-weight: 500;
`;

const InputWrap = styled.div`
  position: relative;
  margin-top: 5px;

  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 0px 20px;
  background: ${css.DARK_BOX_BACKGROUND};

  width: 100%;
  height: 26px;

  font-size: 10px;
  font-weight: 300;
  color: #ffffff;

  border: 0px;
  border-radius: 10px;

  text-align: left;
  box-sizing: border-box;
`;

const FileButtonWrap = styled.div`
  position: absolute;
  top: 4px;
  left: 9px;
`;

const ButtonSmall = styled.div`
  box-sizing: border-box;
  height: 18px;
  padding: 0 10px;

  background: ${css.BRAND_YELLOW};
  border-radius: 14px;
  border: 0;

  font-size: 9px;
  font-weight: 400;
  color: ${css.DARK_BLUE};
  white-space: nowrap;
  line-height: 1;

  transition: 0.2s;

  display: flex;
  align-items: center;
`;

const ChatHeader = styled.div`
  width: 100%;
  padding: 0 7px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatMenuIconBox = styled.div``;

const ChatTitle = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;

  font-size: 16px;
  font-weight: 500;

  display: flex;
  align-items: center;
`;

const TitleProfileImage = styled.div`
  margin-right: 7px;

  width: 36px;
  min-width: 36px;
  height: 36px;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 28px;
  }
`;

const ChatBoxWrap = styled.div`
  border-radius: ${css.BOX_RADIUS_PRIMARY};
  overflow: hidden;

  display: flex;
  align-items: flex-start;
`;

const ChatBox = styled.div`
  width: 100%;
  padding: 15px 7px;
  box-sizing: border-box;
  height: calc(100vh - 224px);
  max-height: calc(100vh - 224px);
  min-height: 300px;

  background: ${css.BLUE_BOX_GRADIENT};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_LARGE};
`;

const Messages = styled.div`
  width: 100%;
  padding-right: 10px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
  flex: 1;
`;

const MessageWrap = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const ChatDate = styled.div`
  width: 100%;
  margin-top: 2px;

  text-align: center;
  font-size: 9px;
  color: ${css.TEXT_GREY};
`;

const PartnerMessage = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-end;
  gap: 6px;
`;

const OwnMessage = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;

const ProfileImageWrap = styled.div`
  min-width: 20px;
`;

const ProfileImage = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const MessageSpacer = styled.div`
  min-width: 10px;
`;

const MessageFormWrap = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const ChatInfo = styled.div`
  width: 100%;
  max-width: 420px;

  font-size: 13px;
  color: ${css.TEXT_GREY};
  text-align: center;
  line-height: 125%;
`;

const BadgeWrap = styled.div`
  margin: 2px 0;
  align-self: center;
`;

const BadgeTitle = styled.div`
  box-sizing: border-box;
  height: 24px;
  padding: 0 15px;

  background: ${css.BRAND_INDIGO};
  border-radius: 19px;
  border: 0;

  font-size: 11px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 1;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Bubble = styled.div`
  max-width: 440px;
  padding: 7px 8px 6px 9px;
  min-width: 0;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: 10px;

  display: flex;
  align-items: stretch;
  gap: 8px;
`;

const BubbleLeftPart = styled.div``;

const BubbleImage = styled.div`
  width: 80px;
  aspect-ratio: 1;

  border-radius: 10px;

  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const BubbleRightPart = styled.div`
  min-width: 0;

  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const SmallContentBox = styled.div`
  height: 15px;

  display: flex;
  justify-content: flex-end;
  gap: 4px;
`;

const BubbleText = styled.div`
  white-space: pre-line;
  word-wrap: break-word;

  font-size: 10px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.postmessage ? 5 : "none")};
  line-clamp: ${(props) => (props.postmessage ? 5 : "none")};
  -webkit-box-orient: vertical;

  flex: 1;

  > a {
    color: ${css.LIGHT_BLUE};
    text-decoration: none;
  }
`;

const BubbleTime = styled.div`
  font-size: 9px;
  color: ${css.TEXT_GREY};
`;

const ReadIcon = styled.div`
  width: 10px;
  min-width: 10px;
  height: 10px;
`;

const Contest = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 7px;

  display: flex;
  flex-direction: column;
`;

const ContestPageTitle = styled.div`
  margin-top: 6px;
  margin-bottom: 9px;

  font-size: 16px;
  font-weight: 400;
`;

const ContestBox = styled.div`
  width: 100%;
  padding: 15px 10px 15px;
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ContestBoxTop = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 45px;
`;

const ContestBoxTopLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  flex: 1;
`;

const ContestBoxTitle = styled.div`
  width: 100%;

  font-size: 13px;
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  flex-direction: column;
`;

const ContestText = styled.div`
  line-height: 125%;
  font-size: 11px;
`;

const CollectInfoBox = styled.div`
  width: 100%;
  padding: 10px;
  margin-top: 0px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  gap: 8px;
`;

const CollectInfoBoxIcon = styled.div`
  width: 15px;
  min-width: 15px;
  height: 15px;
`;

const CollectInfoBoxText = styled.div`
  font-size: 11px;
`;

const OngoingContest = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

const OngoingColumns = styled.div`
  display: flex;
  gap: 14px 8px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const OngoingColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DataTitle = styled.div`
  font-weight: 500;
  font-size: 11px;

  display: flex;
  align-items: center;
  gap: 6px;
`;

const DataRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const DataIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataIcon = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;

  display: flex;
`;

const DataText = styled.div`
  white-space: nowrap;
  font-size: 11px;
`;

const ColumnContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const RankHigherInfoBox = styled.div`
  width: 100%;
  padding: 14px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  flex: 1;
`;

const CollectTip = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const CollectTipIcon = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
`;

const CollectTipText = styled.div`
  font-size: 11px;
  font-weight: 400;
`;

const RankHigherInfoRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RankHigherInfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RankHigherInfoIconBox = styled.div``;

const RankHigherInfoIcon = styled.div`
  width: 15px;
  min-width: 15px;
  height: 15px;
`;

const RankHigherInfoText = styled.div`
  font-size: 11px;
`;

const RankHigherInfoButtonWrap = styled.div``;

const RankingBox = styled.div`
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: 10px;

  display: flex;
  gap: 8px;
`;

const RankingAvatarColumn = styled.div``;

const RankingAvatarIconBox = styled.div`
  margin-top: 23px;
`;

const RankingAvatarIcon = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;
`;

const RankingPositionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const RankingPositionsRow = styled.div`
  height: 18px;

  display: flex;
  align-items: center;
  gap: 5px;
`;

const RankingPositionsStripe = styled.div`
  width: 3px;
  height: 100%;

  background: ${(props) => props.color};
`;

const RankingPositionsText = styled.div`
  font-size: 11px;
  font-weight: 400;
  white-space: nowrap;
`;

const ButtonRoundIcon = styled.div`
  width: 12px;
  height: 12px;

  transition: 0.2s;

  background-image: ${`url(${rightUpArrowIcon})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

const ButtonRoundCircle = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  box-sizing: border-box;

  border-radius: 50%;
  background: ${css.BRAND_YELLOW};
  border: 0px;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ({ display }) => {
  const [showTypewriter, setShowTypewriter] = useState(false);
  const [postText, setPostText] = useState(0);

  const stringSplitter = (string) => {
    const splitter = new GraphemeSplitter();
    return splitter.splitGraphemes(string);
  };

  const typeText = {
    0: [
      "🚀 Turn Your Home into a Thriving Business Hub! 🏠💼",
      "<br><br>Tired of the 9-to-5 grind? Discover the ultimate home-based business opportunity and transform your passion into profit! 🌟 Flexible hours, endless growth potential, and the freedom you deserve.",
      "<br><br>👉 Join a community of successful entrepreneurs today!",
    ],
    1: [
      "📈 Start Trading Smarter with QuantumExchange!",
      "<br><br>Unlock the future of trading with our cutting-edge platform, designed for both newcomers and pros! 🔥",
      "<br><br>✅ Real-time market data ✅ Low fees, high security ✅ Advanced trading tools",
      "<br><br>Elevate your trading game now! 🚀",
    ],
    2: [
      "AdViseBot is here to transform your marketing strategy! 🤖📊",
      "<br><br>Say goodbye to guesswork and hello to data-driven success! Our AI-powered tool analyzes trends and optimizes campaigns effortlessly. 🚀",
      "<br><br>And don't miss out on our referral program: share AdViseBot and earn extra cash! 💰",
      "<br><br>#AIMarketing #BoostYourBrand",
    ],
  };

  const onTypeFinish = () => {
    setShowTypewriter(false);

    setTimeout(() => {
      let newPostText = postText + 1;

      if (newPostText > 2) newPostText = 0;

      setPostText(newPostText);
      setShowTypewriter(true);
    }, 300);
  };

  // delay showing Typewriter for it to work properly
  useEffect(() => {
    setTimeout(() => setShowTypewriter(true), 1000);
  });

  // console.log({ postText });

  return (
    <Display>
      <Header>
        <HeaderContent>
          <LogoWrap>
            <img src={scalelupLogo} style={{ width: "100%" }} />
          </LogoWrap>
          <RightPart>
            <BalanceBox>
              <Icon>
                <img src={coinsIcon} style={{ width: "100%" }} />
              </Icon>
              <Balance>1204</Balance>
            </BalanceBox>
          </RightPart>
        </HeaderContent>
      </Header>
      {display === "one" && (
        <CreatePost>
          <CreatePostHeader>
            <PageTitle>Create New Post</PageTitle>
            <BackIconWrap>
              <RoundActionCircle>
                <RoundActionIcon>
                  <img src={returnIcon} style={{ width: "100%" }} />
                </RoundActionIcon>
              </RoundActionCircle>
            </BackIconWrap>
          </CreatePostHeader>
          <FormBox>
            <FormHeader>
              <AvatarIconWrap>
                <AvatarIcon src={profileImage} style={{ width: "100%" }} />
              </AvatarIconWrap>
              <FormHeaderContent>
                <UserName>Julie Simpson</UserName>
                <PostInfo>Post to Anyone</PostInfo>
              </FormHeaderContent>
            </FormHeader>
            <Content>
              <TextInputBox>
                <TextArea name="text" disabled>
                  {showTypewriter && (
                    <Typewriter
                      onInit={(typewriter) => {
                        typewriter
                          .changeDelay(7)
                          .typeString(typeText[postText][0])
                          .pauseFor(300)
                          .typeString(typeText[postText][1])
                          .pauseFor(300)
                          .typeString(typeText[postText][2])
                          .pauseFor(300)
                          .typeString(typeText[postText][3])
                          .pauseFor(800)
                          .callFunction(() => {
                            onTypeFinish();
                          })
                          .start();
                      }}
                      options={{
                        autoStart: true,
                        loop: true,
                        stringSplitter,
                      }}
                    />
                  )}
                </TextArea>
                <TextInputFooter>
                  <CharacterCounter>
                    <EmptyCircle />
                    <OuterCircle angle={270} atmax={0} />
                    <InnerCircle />
                  </CharacterCounter>
                  <RightControls>
                    <TextAreaIconCircle>
                      <TextAreaIcon width="13px">
                        <img src={smileyIcon} style={{ width: "100%" }} />
                      </TextAreaIcon>
                    </TextAreaIconCircle>
                    <TextAreaIconCircle>
                      <TextAreaIcon width="12px">
                        <img src={templateIcon} style={{ width: "100%" }} />
                      </TextAreaIcon>
                    </TextAreaIconCircle>
                  </RightControls>
                </TextInputFooter>
              </TextInputBox>
              <InputWithLabel width="100%" margintop="10px">
                <Label>
                  <LabelText>Add Image (optional)</LabelText>
                </Label>
                <InputWrap margin="0" padding="0">
                  <Input
                    type="text"
                    value="amazing-image.png"
                    readOnly
                    style={{ paddingLeft: "79px" }}
                  />
                  <FileButtonWrap>
                    <ButtonSmall>Choose file</ButtonSmall>
                  </FileButtonWrap>
                </InputWrap>
              </InputWithLabel>
              <InputWithLabel width="100%" margintop="10px">
                <Label>
                  <LabelText>Image Destination Link (optional)</LabelText>
                </Label>
                <InputWrap margin="0" padding="0">
                  <Input type="text" placeholder="https://..." readOnly />
                </InputWrap>
              </InputWithLabel>
            </Content>
          </FormBox>
        </CreatePost>
      )}
      {display === "two" && (
        <>
          <ChatHeader>
            <ChatTitle>
              <TitleProfileImage>
                <img
                  src={chatProfileImage}
                  style={{ width: "100%", borderRadius: "50%" }}
                />
              </TitleProfileImage>
              Chris Taylor
            </ChatTitle>
            <ChatMenuIconBox>
              <RoundActionCircle>
                <RoundActionIcon>
                  <img src={dotsEmptyIcon} style={{ width: "100%" }} />
                </RoundActionIcon>
              </RoundActionCircle>
            </ChatMenuIconBox>
          </ChatHeader>
          <ChatBoxWrap>
            <ChatBox id="chatBox">
              <Messages id="chatMessages">
                <MessageWrap>
                  <ChatDate>Oct 26, 2024</ChatDate>
                  <BadgeWrap>
                    <BadgeTitle>Chris Taylor reacted to your post</BadgeTitle>
                  </BadgeWrap>
                  <OwnMessage>
                    <MessageSpacer />
                    <ProfileImageWrap />
                    <Bubble postmessage={1}>
                      <BubbleLeftPart>
                        <BubbleImage image={chatPostImage} />
                      </BubbleLeftPart>
                      <BubbleRightPart>
                        <BubbleText>
                          Join CareProfit Network and make an impact while
                          securing your financial future.
                        </BubbleText>
                        <SmallContentBox>
                          <BubbleTime>7:06 pm</BubbleTime>
                          <ReadIcon>
                            <img
                              src={doubleTickBlueIcon}
                              alt="Read"
                              style={{ width: "100%" }}
                            />
                          </ReadIcon>
                        </SmallContentBox>
                      </BubbleRightPart>
                    </Bubble>
                  </OwnMessage>
                </MessageWrap>
                <MessageWrap>
                  <PartnerMessage>
                    <ProfileImageWrap>
                      <ProfileImage>
                        <img
                          src={chatProfileImage}
                          style={{ width: "100%", borderRadius: "50%" }}
                        />
                      </ProfileImage>
                    </ProfileImageWrap>
                    <Bubble postmessage={0}>
                      <BubbleRightPart>
                        <BubbleText>
                          Hi. I would like to learn more about this opportunity.
                        </BubbleText>
                        <SmallContentBox>
                          <BubbleTime>7:06 pm</BubbleTime>
                        </SmallContentBox>
                      </BubbleRightPart>
                    </Bubble>
                    <MessageSpacer />
                  </PartnerMessage>
                </MessageWrap>
                <MessageWrap>
                  <ChatDate>Oct 27, 2024</ChatDate>
                  <OwnMessage>
                    <MessageSpacer />
                    <ProfileImageWrap />
                    <Bubble>
                      <BubbleRightPart>
                        <BubbleText>
                          Thank you for reaching out and showing interest in
                          CareProfit Network! I'm excited to share more details
                          about this rewarding opportunity with you. 🌟
                        </BubbleText>
                        <SmallContentBox>
                          <BubbleTime>10:23 am</BubbleTime>
                          <ReadIcon>
                            <img
                              src={doubleTickGreyIcon}
                              alt="Read"
                              style={{ width: "100%" }}
                            />
                          </ReadIcon>
                        </SmallContentBox>
                      </BubbleRightPart>
                    </Bubble>
                  </OwnMessage>
                </MessageWrap>
              </Messages>
            </ChatBox>
          </ChatBoxWrap>
        </>
      )}
      {display === "three" && (
        <Contest>
          <ContestPageTitle>Contest</ContestPageTitle>
          <ContestBox>
            <ContestBoxTop>
              <ContestBoxTopLeft>
                <ContestBoxTitle>
                  Win Cash With Scalelup Contest
                </ContestBoxTitle>
                <ContestText>
                  Be an active user and grab a valuable cash prize.
                </ContestText>
                <CollectInfoBox>
                  <CollectInfoBoxIcon>
                    <img src={pointsIcon} style={{ width: "100%" }} />
                  </CollectInfoBoxIcon>
                  <CollectInfoBoxText>
                    Collect one contest point for each coin you earn
                  </CollectInfoBoxText>
                </CollectInfoBox>
              </ContestBoxTopLeft>
            </ContestBoxTop>
            <OngoingContest>
              <ContestBoxTitle>Ongoing Contest</ContestBoxTitle>
              <OngoingColumns>
                <OngoingColumn>
                  <Data>
                    <DataTitle>Prize Pool</DataTitle>
                    <DataRow>
                      <DataIconBox>
                        <DataIcon>
                          <img src={dollarIcon} style={{ width: "100%" }} />
                        </DataIcon>
                      </DataIconBox>
                      <DataText>2500 USD</DataText>
                    </DataRow>
                  </Data>
                  <Data>
                    <DataTitle>Term</DataTitle>
                    <DataRow>
                      <DataIconBox>
                        <DataIcon width="18px">
                          <img src={calendarIcon} style={{ width: "100%" }} />
                        </DataIcon>
                      </DataIconBox>
                      <DataText>Nov 4 - Nov 11</DataText>
                    </DataRow>
                  </Data>
                  <Data>
                    <DataTitle>Points</DataTitle>
                    <DataRow>
                      <DataIconBox>
                        <DataIcon>
                          <img src={pointsIcon} style={{ width: "100%" }} />
                        </DataIcon>
                      </DataIconBox>
                      <DataText>674</DataText>
                    </DataRow>
                  </Data>
                </OngoingColumn>
                <OngoingColumn>
                  <ColumnContentWrap>
                    <DataTitle>Your Ranking</DataTitle>
                    <RankingBox>
                      <RankingAvatarColumn>
                        <RankingAvatarIconBox>
                          <RankingAvatarIcon>
                            <img
                              src={profileImage}
                              style={{ width: "100%", borderRadius: "50%" }}
                            />
                          </RankingAvatarIcon>
                        </RankingAvatarIconBox>
                      </RankingAvatarColumn>
                      <RankingPositionsColumn>
                        <RankingPositionsRow>
                          <RankingPositionsStripe color={css.BRAND_YELLOW} />
                          <RankingPositionsText>1 - 10</RankingPositionsText>
                        </RankingPositionsRow>
                        <RankingPositionsRow>
                          <RankingPositionsStripe color={css.BRAND_GREEN} />
                          <RankingPositionsText>11 - 100</RankingPositionsText>
                        </RankingPositionsRow>
                        <RankingPositionsRow>
                          <RankingPositionsStripe color={css.BRAND_INDIGO} />
                          <RankingPositionsText>
                            101 - 1000
                          </RankingPositionsText>
                        </RankingPositionsRow>
                        <RankingPositionsRow>
                          <RankingPositionsStripe color={css.BRAND_BLUSH} />
                          <RankingPositionsText>1001+</RankingPositionsText>
                        </RankingPositionsRow>
                      </RankingPositionsColumn>
                    </RankingBox>
                  </ColumnContentWrap>
                </OngoingColumn>
                <OngoingColumn>
                  <ColumnContentWrap>
                    <DataTitle>How to Rank Higher</DataTitle>
                    <RankHigherInfoBox>
                      <CollectTip>
                        <CollectTipIcon>
                          <img src={bulbIcon} style={{ width: "100%" }} />
                        </CollectTipIcon>
                        <CollectTipText>
                          Collect points by earning coins
                        </CollectTipText>
                      </CollectTip>
                      <RankHigherInfoRows>
                        <RankHigherInfoRow>
                          <RankHigherInfoIconBox>
                            <RankHigherInfoIcon>
                              <img
                                src={discoverIcon}
                                style={{ width: "100%" }}
                              />
                            </RankHigherInfoIcon>
                          </RankHigherInfoIconBox>
                          <RankHigherInfoText>
                            Find coins by exploring opportunities
                          </RankHigherInfoText>
                          <RankHigherInfoButtonWrap>
                            <ButtonRoundCircle>
                              <ButtonRoundIcon />
                            </ButtonRoundCircle>
                          </RankHigherInfoButtonWrap>
                        </RankHigherInfoRow>
                      </RankHigherInfoRows>
                    </RankHigherInfoBox>
                  </ColumnContentWrap>
                </OngoingColumn>
              </OngoingColumns>
            </OngoingContest>
          </ContestBox>
        </Contest>
      )}
    </Display>
  );
};
