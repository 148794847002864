import styled from "styled-components";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import { AWS_PROFILE_IMAGES_URL } from "constants";

import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";
import { useNavigate } from "react-router-dom";

const ProfileSettings = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${css.VERTICAL_BOX_MARGIN_LARGE};

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
  }
`;

const SettingsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const SettinsgItemTitle = styled.div`
  width: 100%;
  padding-left: 3px;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  align-items: center;
  gap: 8px;
`;

const SettingsLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_LARGE};
`;

const SettingsItemValue = styled.div`
  padding: 4px 0 4px 3px;
`;

const SettingsItemButtonWrap = styled.div`
  margin-top: 30px;

  display: flex;

  @media (max-width: ${globalConstants.switchMobile}) {
    margin-top: 0;
  }
`;

const ProfileImage = styled.div`
  margin-top: 5px;
  width: 66px;
  min-width: 66px;
  height: 66px;
`;

export default () => {
  const navigate = useNavigate();

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      profileData: user.profileData,
    })
  );

  const { profileData } = useSelector(UserProperties);

  return (
    <ProfileSettings>
      <SettingsLeft>
        <SettingsItem>
          <SettinsgItemTitle>Username</SettinsgItemTitle>
          <SettingsItemValue>{profileData.username}</SettingsItemValue>
        </SettingsItem>
        <SettingsItem>
          <SettinsgItemTitle>Profile Image</SettinsgItemTitle>
          <ProfileImage>
            <img
              src={AWS_PROFILE_IMAGES_URL + profileData.profileImage}
              alt="Profile image"
              style={{ width: "100%", borderRadius: "50%" }}
            />
          </ProfileImage>
        </SettingsItem>
      </SettingsLeft>
      <SettingsItemButtonWrap>
        <ButtonPrimarySmall onClick={() => navigate("/updateprofile")}>
          Update Profile
        </ButtonPrimarySmall>
      </SettingsItemButtonWrap>
    </ProfileSettings>
  );
};
