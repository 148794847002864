import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

const SectionTitle = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MLARGE};
  margin-bottom: 16px;

  font-size: 18px;
  font-weight: 500;
`;

export default ({ children }) => {
  return <SectionTitle>{children}</SectionTitle>;
};
