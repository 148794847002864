import React from "react";
import styled from "styled-components";

import * as css from "styles/CssVariables.js";

const Button = styled.div`
  box-sizing: border-box;
  height: 28px;
  padding: 0 15px;

  background: ${css.BRAND_YELLOW};
  border-radius: 14px;
  border: 0;

  font-size: 13px;
  font-weight: 500;
  color: ${css.DARK_BLUE};
  white-space: nowrap;
  line-height: 1;

  transition: 0.2s;

  display: flex;
  align-items: center;

  ${(props) =>
    !props.disabled &&
    `&:hover {
    cursor: pointer;

    color: #fff;

    background: ${css.BRAND_GREEN};
  }`}
`;

export default (props) => {
  const { onClick, parameters, id, children, disabled } = props;
  return (
    <Button
      onClick={() => {
        if (disabled || !onClick) return;
        onClick(parameters);
      }}
      id={id}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
