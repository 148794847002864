import styled from "styled-components";

import * as css from "styles/CssVariables.js";

const Icon = styled.div`
  width: ${(props) => (props.iconsize ? props.iconsize : "12px")};
  height: ${(props) => (props.iconsize ? props.iconsize : "12px")};

  transition: 0.2s;

  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Circle = styled.div`
  width: ${(props) => (props.buttonsize ? props.buttonsize : "28px")};
  min-width: ${(props) => (props.buttonsize ? props.buttonsize : "28px")};
  height: ${(props) => (props.buttonsize ? props.buttonsize : "28px")};
  box-sizing: border-box;

  border-radius: 50%;
  background: ${(props) => (props.disabled ? css.TEXT_GREY : props.background)};
  border: ${(props) => (props.border ? `1px solid ${props.border}` : "0px")};

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};

    background: ${(props) =>
      props.disabled ? css.TEXT_GREY : props.hoverbackground};
    border: 0px;
  }

  &:hover ${Icon} {
    background-image: ${(props) =>
      `url(${props.disabled ? props.icon : props.hovericon})`};
  }
`;

export default ({
  icon,
  hoverIcon,
  background,
  hoverBackground,
  border,
  onClick,
  parameters,
  buttonSize,
  iconSize,
  disabled,
}) => {
  return (
    <Circle
      onClick={() => {
        if (onClick) onClick(parameters);
        return;
      }}
      icon={icon}
      hovericon={hoverIcon}
      background={background}
      hoverbackground={hoverBackground}
      border={border}
      buttonsize={buttonSize}
      disabled={disabled}
    >
      <Icon icon={icon} iconsize={iconSize} />
    </Circle>
  );
};
