import styled from "styled-components";

import ModalWindow from "./ModalWindow";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import sorryIcon from "assets/img/icons/icons8-sorry.svg";

const MessageModalWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  max-width: 442px;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 2000;
`;

const Content = styled.div`
  width: 100%;
  padding-top: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Message = styled.div`
  margin-bottom: 30px;

  line-height: 125%;
`;

const SorryIcon = styled.div`
  margin: 0px 0 30px 0;

  width: 50px;
  height: 50px;
`;

export default (props) => {
  const { handleModalClose, children, type, title, icon } = props;

  return (
    <MessageModalWrap>
      <ModalWindow
        modal={type}
        title={title ? title : "Error"}
        handleModalClose={handleModalClose}
      >
        {children ? (
          <Content>
            {icon && (
              <SorryIcon>
                <img src={icon} width="100%" alt="Icon" />
              </SorryIcon>
            )}
            <Message>{children}</Message>
            <ButtonPrimary onClick={handleModalClose} parameters={type}>
              Close
            </ButtonPrimary>
          </Content>
        ) : (
          <Content>
            <Message>
              There was an error processing your request. Please try again later
              or contact support.
            </Message>
            <SorryIcon>
              <img src={sorryIcon} width="100%" alt="Error" />
            </SorryIcon>
            <ButtonPrimary onClick={handleModalClose} parameters={type}>
              Close
            </ButtonPrimary>
          </Content>
        )}
      </ModalWindow>
    </MessageModalWrap>
  );
};
