import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import { AWS_PROFILE_IMAGES_URL } from "constants";

const RankingBox = styled.div`
  width: 100%;
  padding: 13px 15px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  gap: 10px;
`;

const RankingAvatarColumn = styled.div``;

const RankingAvatarIconBox = styled.div`
  margin-top: ${(props) => props.margintop};
`;

const RankingAvatarIcon = styled.div`
  width: 26px;
  min-width: 26px;
  height: 26px;
`;

const RankingPositionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const RankingPositionsRow = styled.div`
  height: 28px;

  display: flex;
  align-items: center;
  gap: 7px;
`;

const RankingPositionsStripe = styled.div`
  width: 3px;
  height: 100%;

  background: ${(props) => props.color};
`;

const RankingPositionsText = styled.div`
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
`;

export default ({ rankingGroup, profileImage }) => {
  const rankingAvatarMargin = 1 + (rankingGroup - 1) * 35;

  return (
    <RankingBox>
      <RankingAvatarColumn>
        <RankingAvatarIconBox margintop={rankingAvatarMargin + "px"}>
          <RankingAvatarIcon>
            <img
              src={AWS_PROFILE_IMAGES_URL + profileImage}
              style={{ width: "100%", borderRadius: "50%" }}
            />
          </RankingAvatarIcon>
        </RankingAvatarIconBox>
      </RankingAvatarColumn>
      <RankingPositionsColumn>
        <RankingPositionsRow>
          <RankingPositionsStripe color={css.BRAND_YELLOW} />
          <RankingPositionsText>1 - 10</RankingPositionsText>
        </RankingPositionsRow>
        <RankingPositionsRow>
          <RankingPositionsStripe color={css.BRAND_GREEN} />
          <RankingPositionsText>11 - 100</RankingPositionsText>
        </RankingPositionsRow>
        <RankingPositionsRow>
          <RankingPositionsStripe color={css.BRAND_INDIGO} />
          <RankingPositionsText>101 - 1000</RankingPositionsText>
        </RankingPositionsRow>
        <RankingPositionsRow>
          <RankingPositionsStripe color={css.BRAND_BLUSH} />
          <RankingPositionsText>1001+</RankingPositionsText>
        </RankingPositionsRow>
      </RankingPositionsColumn>
    </RankingBox>
  );
};
