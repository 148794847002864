import styled from "styled-components";
import AppleLogin from "react-apple-login";
import FacebookLogin from "@greatsumini/react-facebook-login";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";
import InputText from "components/forms/InputText";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import RollingBox from "components/other/RollingBox";
import Tip from "components/other/Tip";

import appleIcon from "assets/img/icons/icons8-apple.svg";
import googleIcon from "assets/img/icons/icons8-google.svg";
import facebookIcon from "assets/img/icons/facebook.png";
import ToggleSwitch from "components/forms/ToggleSwitch";

const AccountSettings = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_XLARGE};
`;

const SettingsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const SettinsgItemTitle = styled.div`
  width: 100%;
  padding-left: 3px;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  align-items: center;
  gap: 8px;
`;

const SettingsItemLine = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${css.VERTICAL_BOX_MARGIN};

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SettingsItemValue = styled.div`
  padding: 4px 0 4px 3px;
`;

const SettingsItemButtonWrap = styled.div``;

const SettingsHidingBox = styled.div`
  padding-bottom: 10px;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const InputWrap = styled.div`
  width: 100%;
  max-width: 250px;
  margin-top: 5px;
`;

const ButtonWrap = styled.div`
  display: flex;
`;

const ErrorText = styled.div`
  max-width: 340px;

  font-weight: 500;
  color: ${css.ERROR_RED};
`;

const PendingBox = styled.div`
  margin-top: 8px;
  padding-top: 11px !important;
  padding: ${css.BOX_PADDING_TERTIARY};
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const PendingEmail = styled.div`
  line-height: 1;
`;

const PendingBadge = styled.div`
  height: 24px;
  padding: 0px 12px;

  font-size: 13px;
  color: ${css.DARK_BLUE};

  border-radius: 12px;
  background: ${css.BRAND_BLUSH};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const PendingInfo = styled.div`
  font-size: 13px;
`;

const SuccessText = styled.div`
  max-width: 340px;

  font-weight: 500;
  color: ${css.POSITIVE_GREEN};
`;

const BoxTitle = styled.div`
  width: 100%;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  align-items: center;
  gap: 6px;
`;

const SocialAccounts = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const SocialAccountButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const SocialAccountButton = styled.div`
  box-sizing: border-box;
  height: 28px;
  width: 170px;
  padding: 0 15px;

  background: #fff;
  border-radius: 21px;
  border: 0;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  ${(props) =>
    !props.disabled &&
    `&:hover {
    cursor: pointer;

    color: #fff;

    background: ${css.BRAND_BLUSH};
  }`}
`;

const SocialAccountButtonIcon = styled.div`
  width: 18px;
  height: 18px;
`;

const SocialAccountButtonText = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${css.DARK_BLUE};
`;

const SocialAccountConnected = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`;

const SocialAccountConnectedLeftPart = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SocialAccountIconCircle = styled.div`
  width: 28px;
  height: 28px;

  border-radius: 50%;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const SocialAccountIcon = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;
`;

const SocialAccountConnectedText = styled.div`
  font-size: 15px;
`;

const Notifications = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const NotificationsLine = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const NotificationsLabel = styled.div`
  line-height: 1;
`;

export default ({
  data,
  changeEmailForm,
  changeEmailFormToggle,
  emailInput,
  handleEmailInputChange,
  warnings,
  changePasswordForm,
  changePasswordFormToggle,
  passwordInput,
  handlePasswordInputChange,
  loadingChangePassword,
  addNewEmail,
  addEmailMessage,
  loadingAddEmail,
  changeAccountPassword,
  changePasswordMessage,
  changePasswordSuccess,
  connectAppleAccount,
  connectMessage,
  connectGoogleAccount,
  connectFacebookAccount,
  onNotificationsToggleClick,
  loadingNotificationsToggle,
}) => {
  const { user, account } = data.accountSettingsData;
  const {
    apple_id,
    apple_email,
    google_id,
    google_email,
    facebook_id,
    facebook_email,
  } = account;

  const { pending_emails, notifications_newsletter } = user;

  return (
    <AccountSettings>
      <RollingBox>
        <SettingsItem>
          <SettinsgItemTitle>Email Address</SettinsgItemTitle>
          <SettingsItemLine>
            <SettingsItemValue>{user.email}</SettingsItemValue>
            <SettingsItemButtonWrap>
              <ButtonPrimarySmall onClick={changeEmailFormToggle}>
                {changeEmailForm ? "Close" : "Change Email"}
              </ButtonPrimarySmall>
            </SettingsItemButtonWrap>
          </SettingsItemLine>
          {pending_emails[0] && (
            <PendingBox>
              <PendingEmail>{pending_emails[0].pending_email}</PendingEmail>
              <PendingBadge>Pending Verification</PendingBadge>
              <PendingInfo>
                Please check your inbox (including the spam folder) and verify
                the new email address.
              </PendingInfo>
            </PendingBox>
          )}
          {changeEmailForm && (
            <SettingsHidingBox>
              <InputWrap>
                <InputText
                  type="email"
                  name="email"
                  value={emailInput}
                  placeholder="Enter new email address"
                  onChange={handleEmailInputChange}
                  maxLength={100}
                  warning={warnings.includes("email")}
                  margin="5px 0 0 0"
                />
              </InputWrap>
              {addEmailMessage && <ErrorText>{addEmailMessage}</ErrorText>}
              <ButtonWrap>
                <ButtonPrimary onClick={addNewEmail} disabled={loadingAddEmail}>
                  Add Email
                </ButtonPrimary>
              </ButtonWrap>
            </SettingsHidingBox>
          )}
        </SettingsItem>
      </RollingBox>
      <RollingBox>
        <SettingsItem>
          <SettinsgItemTitle>Password</SettinsgItemTitle>
          <SettingsItemLine>
            <SettingsItemValue>********</SettingsItemValue>
            <SettingsItemButtonWrap>
              <ButtonPrimarySmall onClick={changePasswordFormToggle}>
                {changePasswordForm ? "Close" : "Change Password"}
              </ButtonPrimarySmall>
            </SettingsItemButtonWrap>
          </SettingsItemLine>
          {changePasswordForm && (
            <SettingsHidingBox>
              <InputWrap>
                <InputText
                  type="password"
                  name="password"
                  value={passwordInput.password}
                  placeholder="Enter new password"
                  onChange={handlePasswordInputChange}
                  maxLength={100}
                  warning={warnings.includes("password")}
                />
              </InputWrap>
              <InputWrap>
                <InputText
                  type="password"
                  name="confirmPassword"
                  value={passwordInput.confirmPassword}
                  placeholder="Confirm password"
                  onChange={handlePasswordInputChange}
                  maxLength={100}
                  warning={warnings.includes("confirmPassword")}
                  margin="-8px 0 0 0"
                />
              </InputWrap>
              {changePasswordMessage && (
                <ErrorText>{changePasswordMessage}</ErrorText>
              )}
              <ButtonWrap>
                <ButtonPrimary
                  onClick={changeAccountPassword}
                  disabled={loadingChangePassword}
                >
                  Save
                </ButtonPrimary>
              </ButtonWrap>
            </SettingsHidingBox>
          )}
          {changePasswordSuccess && (
            <SuccessText>
              The password has been successfully changed.
            </SuccessText>
          )}
        </SettingsItem>
      </RollingBox>
      <SocialAccounts>
        <BoxTitle>
          Social Accounts
          <Tip tip="Connect your account with your Apple, Google, or Facebook account for easier login from anywhere. The connection does not allow us to post anything on your social accounts." />
        </BoxTitle>
        <SocialAccountButtons>
          {apple_id && (
            <SocialAccountConnected>
              <SocialAccountConnectedLeftPart>
                <SocialAccountIconCircle>
                  <SocialAccountIcon>
                    <img
                      src={appleIcon}
                      alt="Apple"
                      style={{ width: "100%" }}
                    />
                  </SocialAccountIcon>
                </SocialAccountIconCircle>
                <SocialAccountConnectedText>
                  Connected
                </SocialAccountConnectedText>
              </SocialAccountConnectedLeftPart>
              <SocialAccountConnectedText>
                ({apple_email})
              </SocialAccountConnectedText>
            </SocialAccountConnected>
          )}
          {google_id && (
            <SocialAccountConnected>
              {" "}
              <SocialAccountConnectedLeftPart>
                <SocialAccountIconCircle>
                  <SocialAccountIcon>
                    <img
                      src={googleIcon}
                      alt="Google"
                      style={{ width: "100%" }}
                    />
                  </SocialAccountIcon>
                </SocialAccountIconCircle>
                <SocialAccountConnectedText>
                  Connected
                </SocialAccountConnectedText>
              </SocialAccountConnectedLeftPart>
              <SocialAccountConnectedText>
                ({google_email})
              </SocialAccountConnectedText>
            </SocialAccountConnected>
          )}
          {facebook_id && (
            <SocialAccountConnected>
              <SocialAccountConnectedLeftPart>
                <SocialAccountIconCircle>
                  <SocialAccountIcon>
                    <img
                      src={facebookIcon}
                      alt="Facebook"
                      style={{ width: "100%" }}
                    />
                  </SocialAccountIcon>
                </SocialAccountIconCircle>
                <SocialAccountConnectedText>
                  Connected
                </SocialAccountConnectedText>
              </SocialAccountConnectedLeftPart>
              <SocialAccountConnectedText>
                ({facebook_email})
              </SocialAccountConnectedText>
            </SocialAccountConnected>
          )}
          {!apple_id && (
            <AppleLogin
              clientId="com.scalelup.service"
              redirectURI="https://scalelup.com"
              usePopup={true}
              callback={connectAppleAccount}
              scope="email name"
              responseMode="query"
              render={(renderProps) => (
                <SocialAccountButton onClick={renderProps.onClick}>
                  <SocialAccountButtonIcon>
                    <img
                      src={appleIcon}
                      alt="Connect Apple"
                      style={{ width: "100%" }}
                    />
                  </SocialAccountButtonIcon>
                  <SocialAccountButtonText>
                    Connect Apple
                  </SocialAccountButtonText>
                </SocialAccountButton>
              )}
            />
          )}
          {!google_id && (
            <SocialAccountButton onClick={connectGoogleAccount}>
              <SocialAccountButtonIcon>
                <img
                  src={googleIcon}
                  alt="Connect Google"
                  style={{ width: "100%" }}
                />
              </SocialAccountButtonIcon>
              <SocialAccountButtonText>Connect Google</SocialAccountButtonText>
            </SocialAccountButton>
          )}
          {!facebook_id && (
            <FacebookLogin
              appId="1596483107932488"
              onSuccess={(response) => {
                connectFacebookAccount(response);
              }}
              onFail={(error) => {
                //console.log("Login Failed!", error);
              }}
              render={({ onClick }) => (
                <SocialAccountButton onClick={onClick}>
                  <SocialAccountButtonIcon>
                    <img
                      src={facebookIcon}
                      alt="Connect Facebook"
                      style={{ width: "100%" }}
                    />
                  </SocialAccountButtonIcon>
                  <SocialAccountButtonText>
                    Connect Facebook
                  </SocialAccountButtonText>
                </SocialAccountButton>
              )}
            />
          )}
        </SocialAccountButtons>
        {connectMessage && <ErrorText>{connectMessage}</ErrorText>}
      </SocialAccounts>
      <Notifications>
        <BoxTitle>Notifications</BoxTitle>
        <NotificationsLine>
          <ToggleSwitch
            on={notifications_newsletter}
            onClick={() => onNotificationsToggleClick("newsletter")}
            loading={loadingNotificationsToggle}
          />
          <NotificationsLabel>Newsletter</NotificationsLabel>
        </NotificationsLine>
      </Notifications>
    </AccountSettings>
  );
};
