export const DEFAULT_USERNAMES = [
  "Darth Vader",
  "Frodo Baggins",
  "Harry Potter",
  "James Bond",
  "Sherlock Holmes",
  "Batman",
  "Superman",
  "Luke Skywalker",
  "Homer Simpson",
  "Mickey Mouse",
  "Indiana Jones",
  "Spider-Man",
  "Walter White",
  "Iron Man",
  "The Joker",
  "Elsa",
  "Jon Snow",
  "Hannibal Lecter",
  "Rocky Balboa",
  "Yoda",
  "Forrest Gump",
  "Optimus Prime",
  "Wolverine",
  "Daenerys Targaryen",
  "Marty McFly",
  "Captain Jack Sparrow",
  "Gollum",
  "Lara Croft",
  "Terminator",
  "Simba",
  "Scarlett O'Hara",
  "Michael Corleone",
  "Buzz Lightyear",
  "E.T.",
  "Captain America",
  "SpongeBob SquarePants",
  "Voldemort",
  "John McClane",
  "Hermione Granger",
  "Neo",
  "Jules Winnfield",
  "Woody",
  "Buffy Summers",
  "Samwise Gamgee",
  "Katniss Everdeen",
  "Princess Leia",
  "Rick Grimes",
  "Mr. Spock",
  "The Dude",
  "Bugs Bunny",
  "Freddy Krueger",
  "Jason Bourne",
  "Mulan",
  "Atticus Finch",
  "Jack Torrance",
  "Dory",
  "Shrek",
  "Gandalf",
  "Kermit the Frog",
  "Rambo",
  "Pikachu",
  "Zorro",
  "James T. Kirk",
  "Charlie Brown",
  "Tony Montana",
  "Hannibal Smith",
  "Norman Bates",
  "Mowgli",
  "Mulder",
  "George Bailey",
  "Vito Corleone",
  "Anakin Skywalker",
  "Popeye",
  "Beatrix Kiddo",
  "Ferris Bueller",
  "Michael Myers",
  "Ash Williams",
  "Rick Blaine",
  "Pennywise",
  "Eric Cartman",
  "Willy Wonka",
  "Veronica Mars",
  "Blondie",
  "Mary Poppins",
  "R2-D2",
  "Doctor Who",
  "Dexter Morgan",
  "Gomez Addams",
  "Mad Max",
  "Norma Desmond",
  "El Mariachi",
  "Shaggy Rogers",
  "Jack Skellington",
  "Bender",
  "John Wick",
  "Mr. Bean",
  "Pippi Longstocking",
  "Legolas",
  "Max Rockatansky",
  "Howard Beale",
  "Napoleon Dynamite",
  "Vincent Vega",
  "Sarah Connor",
  "Inigo Montoya",
  "Travis Bickle",
  "Furiosa",
  "Dr. Emmett Brown",
  "Holly Golightly",
  "Tyler Durden",
  "Mathilda",
  "Amélie Poulain",
  "Alex DeLarge",
  "Vito Scaletta",
  "Lisabeth Salander",
  "Robocop",
  "Edward Scissorhands",
  "Beetlejuice",
  "H.I. McDunnough",
  "Zaphod Beeblebrox",
  "Napoleon Solo",
  "Clementine Kruczynski",
  "Rick Deckard",
  "Nikita",
  "Randle McMurphy",
  "Harley Quinn",
  "Frank Underwood",
  "Red Reddington",
  "Snake Plissken",
  "Rigoletto Rizzoli",
  "Malcolm Reynolds",
  "Jane Eyre",
  "Juno MacGuff",
  "Dr. Gregory House",
  "Tommy Shelby",
  "Dale Cooper",
  "Patrick Jane",
  "David Rose",
  "Marty Byrde",
  "Thomas Shelby",
  "Elle Woods",
  "Frank Abagnale Jr.",
  "Rory Gilmore",
  "Raymond Reddington",
  "Fletcher Reede",
  "Jane Tennison",
  "Jesse Pinkman",
  "Geralt of Rivia",
];
