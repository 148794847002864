import React from "react";
import styled from "styled-components";
import MoonLoader from "react-spinners/MoonLoader";

const SmallSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default () => {
  return (
    <SmallSpinner>
      <MoonLoader color="#000" size={18} />
    </SmallSpinner>
  );
};
