import { useEffect } from "react";

import { AUTH_ID, AUTH_TOKEN, AUTH_WS_TOKEN, SHARD_NUMBER } from "constants";
import LoadingPage from "components/other/LoadingPage";

export default () => {
  useEffect(() => {
    localStorage.removeItem(AUTH_ID);
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_WS_TOKEN);
    localStorage.removeItem(SHARD_NUMBER);

    window.location = "/";
  }, []);

  return <LoadingPage />;
};
