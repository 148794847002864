import { useEffect } from "react";
import styled from "styled-components";
import Confetti from "react-confetti";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import { AWS_PROFILE_IMAGES_URL } from "constants";
import Layout from "components/Layout";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import PostContent from "components/posts/PostContent";
import ButtonBig from "components/buttons/ButtonBig";
import CoinsProbability from "./CoinsProbability";
import RewardBox from "./RewardBox";
import TryAgainBox from "./TryAgainBox";

const Discover = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

const PostBox = styled.div`
  position: relative;
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;
  z-index: 1;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Counter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;

  background-color: ${css.BRAND_INDIGO};
`;

const CounterBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.width}%;
  height: 100%;

  background-color: ${css.BRAND_GREEN};
`;

const PostContentWrap = styled.div`
  margin-top: 12px;
`;

const BottomContent = styled.div`
  position: relative;
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
  min-height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NextButtonWrap = styled.div`
  position: absolute;
  top: 0;

  display: none;
  opacity: 0;
`;

export default ({
  post,
  counterWidth,
  showNextPost,
  container,
  probability,
  reward,
  tryAgain,
  onTryAgainClick,
  linkClicked,
  onNewMessageClick,
  likePostClick,
  postLiked,
  likes,
}) => {
  // set page Title
  useEffect(() => {
    document.title = "Discover | Bizmatcher";
  }, []);

  return (
    <Layout>
      <Discover ref={container}>
        {!tryAgain && (
          <PostBox id="postBox">
            {!post && !reward && <BoxLoadingSpinner />}
            {post && !reward && (
              <>
                <Counter>
                  <CounterBar width={counterWidth} />
                </Counter>
                <PostContentWrap>
                  <PostContent
                    avatar={AWS_PROFILE_IMAGES_URL + post.user.profile_image}
                    username={post.user.username}
                    createdAt={post.created_at}
                    text={post.text}
                    image={post.image}
                    imageLink={post.image_link}
                    linkClicked={linkClicked}
                    onNewMessageClick={onNewMessageClick}
                    likePostClick={likePostClick}
                    postLiked={postLiked}
                    likes={likes}
                  />
                </PostContentWrap>
              </>
            )}
            {reward && <RewardBox reward={reward} />}
          </PostBox>
        )}
        {tryAgain && (
          <PostBox id="postBox">
            <TryAgainBox onTryAgainClick={onTryAgainClick} />
          </PostBox>
        )}
        <BottomContent>
          <CoinsProbability probability={probability} />
          <NextButtonWrap id="nextButton">
            <ButtonBig onClick={showNextPost}>Show Next Post</ButtonBig>
          </NextButtonWrap>
        </BottomContent>
      </Discover>
    </Layout>
  );
};
