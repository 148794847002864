import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import MenuFooterItem from "./menu/MenuFooterItem";

const Footer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;

  background-color: ${(props) => props.bgcolor};

  align-self: flex-end;
`;

const FooterLinks = styled.div`
  padding: 40px 0 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 40px 0 90px;
  }
`;

const FooterCopyright = styled.div`
  /* margin-bottom: 80px; */

  color: ${css.TEXT_GREY};
  font-size: 13px;
`;

export default () => {
  const location = useLocation();

  const { pathname } = location;
  const bgColor =
    pathname === "/" ? css.LANDING_PAGE_BACKGROUND : css.PAGE_BACKGROUND;

  return (
    <Footer bgcolor={bgColor}>
      <FooterLinks>
        <MenuFooterItem to="/terms">Terms</MenuFooterItem>
        <MenuFooterItem to="/privacy-policy">Privacy Policy</MenuFooterItem>
        <FooterCopyright>© 2024 Scalelup</FooterCopyright>
      </FooterLinks>
    </Footer>
  );
};
