import styled from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import InputText from "components/forms/InputText";
import ButtonRoundIcon from "components/buttons/ButtonRoundIcon";
import Tip from "components/other/Tip";

import copyIcon from "assets/img/icons/copy-icon.svg";
import copyWhiteIcon from "assets/img/icons/copy-white-icon.svg";
import checkIcon from "assets/img/icons/icons8-check.svg";
import checkWhiteIcon from "assets/img/icons/icons8-check-white.svg";

const ReferralLinkBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  justify-content: space-between;
  gap: 5vw;

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
  }
`;

const BoxTop = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
`;

const BoxTopLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};

  flex: 1;
`;

const BoxTitle = styled.div`
  width: 100%;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  align-items: center;
  gap: 6px;
`;

const Text = styled.div``;

const InputWrap = styled.div`
  position: relative;
  max-width: 400px;
`;

const CopyIconBox = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;

  transform: translateY(-50%);
`;

const BoxTopRight = styled.div``;

const BoxBottom = styled.div``;

const ButtonWrap = styled(Link)`
  margin-top: ${css.VERTICAL_BOX_MARGIN_XLARGE};

  text-decoration: none;

  display: flex;
  align-self: flex-start;

  @media (max-width: ${globalConstants.switchMobile}) {
    margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
  }
`;

export default () => {
  const [copied, setCopied] = useState(false);

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      profileData: user.profileData,
    })
  );

  const { profileData } = useSelector(UserProperties);
  //console.log("profileData", profileData);

  const linkCopied = () => {
    setCopied(true);

    setTimeout(() => setCopied(false), 2000);
  };

  console.log({ copied });

  return (
    <ReferralLinkBox>
      <BoxTop>
        <BoxTopLeft>
          <BoxTitle>
            Share Your Link
            <Tip tip="Users who register with your link will become your referrals." />
          </BoxTitle>
          <Text>Use this link to refer new users to Scalelup.</Text>
          <InputWrap>
            <InputText
              type="text"
              value={`https://scalelup.com/?r=${profileData.referralCode}`}
              readOnly={true}
            />
            <CopyIconBox>
              <CopyToClipboard
                text={`https://scalelup.com/?r=${profileData.referralCode}`}
                onCopy={linkCopied}
              >
                <ButtonRoundIcon
                  icon={copied ? checkIcon : copyIcon}
                  hoverIcon={copied ? checkWhiteIcon : copyWhiteIcon}
                  background={css.BRAND_YELLOW}
                  hoverBackground={css.BRAND_GREEN}
                  buttonSize="24px"
                  iconSize="10px"
                />
              </CopyToClipboard>
            </CopyIconBox>
          </InputWrap>
        </BoxTopLeft>
      </BoxTop>
    </ReferralLinkBox>
  );
};
