import { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import Layout from "components/Layout";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import PageTitle from "components/text/PageTitle";
import PostListBox from "components/posts/PostListBox";
import PostListBoxFixed from "components/posts/PostListBoxFixed";
import ButtonPrimaryIcon from "components/buttons/ButtonPrimaryIcon";
import ModalWrap from "components/other/ModalWrap";
import Backdrop from "components/other/Backdrop";
import ModalWindow from "components/other/ModalWindow";

import blockIcon from "assets/img/icons/icons8-unavailable.svg";
import plusIcon from "assets/img/icons/plus-sign.svg";
import plusWhiteIcon from "assets/img/icons/plus-sign-white.svg";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import ButtonWhite from "components/buttons/ButtonWhite";

const Posts = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

const PostBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const NoResults = styled.div`
  width: 100%;
  height: 210px;
  margin-top: 75px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const NoResultsIcon = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
`;

const NoResultsText = styled.div``;

const NoResultButtonWrap = styled(Link)`
  margin-top: ${css.VERTICAL_BOX_MARGIN};

  text-decoration: none;
`;

const ConfirmDelete = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  transform: translateX(-50%) translateY(-50%);

  width: 100%;
  max-width: 442px;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 200;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 100%;
  }
`;

const ConfirmDeleteWrap = styled.div`
  margin-top: 60px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConfirmDeleteText = styled.div``;

const ConfirmDeleteButtons = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_XLARGE};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${css.BUTTONS_GAP};
`;

export default ({
  loading,
  data,
  onSwitchClick,
  switchLoading,
  onEditClick,
  onDeleteClick,
  confirmDelete,
  closeDeleteModal,
  onConfirmDelete,
  loadingDelete,
  onDuplicateClick,
}) => {
  // set page Title
  useEffect(() => {
    document.title = "Posts | Bizmatcher";
  }, []);

  const posts = data ? data.posts : [];

  console.log({ posts, switchLoading });

  const list = posts.map((post) => {
    return (
      <PostListBox
        key={post.id}
        post={post}
        onSwitchClick={onSwitchClick}
        switchLoading={switchLoading}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        loadingDelete={loadingDelete}
        onDuplicateClick={onDuplicateClick}
      />
    );
  });

  let postToConfirm;

  console.log({ confirmDelete });

  if (data && confirmDelete) {
    postToConfirm = posts.find((post) => post.id === confirmDelete);
    console.log({ postToConfirm });
  }

  return (
    <Layout>
      <Posts>
        <PageTitle marginBottom="0">Posts</PageTitle>
        {loading && (
          <PostBox>
            <BoxLoadingSpinner height="285px" />
          </PostBox>
        )}
        {!loading && list}
        {!loading && list.length === 0 && (
          <PostBox>
            <NoResults>
              <NoResultsIcon>
                <img src={blockIcon} style={{ width: "100%" }} />
              </NoResultsIcon>
              <NoResultsText>You don't have any posts.</NoResultsText>
              <NoResultButtonWrap to="/modify-post">
                <ButtonPrimaryIcon icon={plusIcon} iconHover={plusWhiteIcon}>
                  Create Post
                </ButtonPrimaryIcon>
              </NoResultButtonWrap>
            </NoResults>
          </PostBox>
        )}
        {postToConfirm && (
          <ModalWrap>
            <Backdrop
              onClick={closeDeleteModal}
              background="rgba(0, 0, 0, 0.7)"
            />
            <ConfirmDelete>
              <ModalWindow
                title="Delete Post"
                handleModalClose={closeDeleteModal}
                fullScreenHeight={true}
              >
                <ConfirmDeleteWrap>
                  <ConfirmDeleteText>
                    Do you really want to delete the post "{postToConfirm.name}
                    "?
                  </ConfirmDeleteText>
                  <ConfirmDeleteButtons>
                    <ButtonWhite onClick={closeDeleteModal}>Cancel</ButtonWhite>
                    <ButtonPrimary onClick={onConfirmDelete}>
                      Delete
                    </ButtonPrimary>
                  </ConfirmDeleteButtons>
                </ConfirmDeleteWrap>
              </ModalWindow>
            </ConfirmDelete>
          </ModalWrap>
        )}
      </Posts>
    </Layout>
  );
};
