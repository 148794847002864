import styled from "styled-components";

const ModalWrap = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export default ({ children }) => {
  return <ModalWrap>{children}</ModalWrap>;
};
