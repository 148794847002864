import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import coinsIcon from "assets/img/icons/coins-icon.svg";

const CoinsProbabilityBox = styled.div`
  padding: ${css.BOX_PADDING_SECONDARY};
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  justify-content: space-between;
  gap: 15px;

  transform: translateY(-180px);
  opacity: 0;
`;

const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CoinsIcon = styled.div`
  width: 24px;
  min-width: 24px;
  aspect-ratio: 1;
`;

const Title = styled.div`
  font-weight: 500;
`;

const Info = styled.div`
  max-width: 135px;

  font-size: 13px;
`;

const RightPart = styled.div`
  width: 50px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProbabilityCounter = styled.div`
  position: relative;
  height: 62px;
`;

const EmptyCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 58px;
  height: 58px;
  transform: translate(-50%, -50%);

  border-radius: 50%;
  background: rgb(255, 255, 255, 0.07);
`;

const OuterCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 58px;
  height: 58px;
  transform: translate(-50%, -50%);

  border-radius: 50%;
  background: ${css.BRAND_YELLOW};
  mask: conic-gradient(
    from 0deg,
    #0000,
    #000 1deg ${(props) => props.angle}deg,
    #0000 calc(${(props) => props.angle}deg + 1deg)
  );
`;

const InnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 44px;
  height: 44px;
  transform: translate(-50%, -50%);

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: 50%;
`;

const Counter = styled.div`
  position: absolute;
  padding-bottom: 2px;
  padding-left: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 14px;
  font-weight: 500;
`;

export default ({ probability }) => {
  const angle = (360 / 100) * (probability * 2);

  return (
    <CoinsProbabilityBox id="probabilityBox">
      <LeftPart>
        <TitleSection>
          <CoinsIcon>
            <img src={coinsIcon} style={{ width: "100%" }} />
          </CoinsIcon>
          <Title>Coins Reward</Title>
        </TitleSection>
        <Info>Chance of finding coins in the next post</Info>
      </LeftPart>
      <RightPart>
        <ProbabilityCounter>
          <EmptyCircle />
          <OuterCircle angle={angle} />
          <InnerCircle />
          <Counter>{probability}%</Counter>
        </ProbabilityCounter>
      </RightPart>
    </CoinsProbabilityBox>
  );
};
