import styled from "styled-components";
import { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { isEmail } from "validator";
import { useGoogleLogin } from "@react-oauth/google";

import { validatePasswordString } from "modules/functions";
import AccountSettings from "./AccountSettings";
import LoadingBox from "components/other/LoadingBox";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import Backdrop from "components/other/Backdrop";
import MessageWindow from "components/other/MessageWindow";

const ModalWrap = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export default () => {
  const [changeEmailForm, setChangeEmailForm] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [warnings, setWarnings] = useState([]);
  const [changePasswordForm, setChangePasswordForm] = useState(false);
  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const [loadingAddEmail, setLoadingAddEmail] = useState(false);
  const [addEmailMessage, setAddEmailMessage] = useState(null);
  const [errorWindow, setErrorWindow] = useState(false);
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [changePasswordMessage, setChangePasswordMessage] = useState(null);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [loadingConnectGoogle, setLoadingConnectGoogle] = useState(false);
  const [loadingConnectApple, setLoadingConnectApple] = useState(false);
  const [loadingConnectFacebook, setLoadingConnectFacebook] = useState(false);
  const [connectMessage, setConnectMessage] = useState(null);
  const [loadingNotificationsToggle, setLoadingNotificationsToggle] =
    useState(false);

  // Fetch data
  const ACCOUNT_SETTINGS_DATA = gql`
    query GetAccountSettingsData {
      accountSettingsData {
        user {
          id
          email
          pending_emails {
            pending_email
          }
          notifications_newsletter
        }
        account {
          apple_id
          apple_email
          google_id
          google_email
          facebook_id
          facebook_email
        }
      }
    }
  `;

  const { loading, data, error, refetch } = useQuery(ACCOUNT_SETTINGS_DATA, {
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching settings data error", error);
    //captureException(error);
  }

  const changeEmailFormToggle = () => {
    setChangeEmailForm(!changeEmailForm);
    setChangePasswordForm(false);
  };

  const handleEmailInputChange = (e) => {
    setEmailInput(e.target.value);
  };

  const changePasswordFormToggle = () => {
    setChangePasswordForm(!changePasswordForm);
    setChangeEmailForm(false);
  };

  const handlePasswordInputChange = (e) => {
    setPasswordInput({ ...passwordInput, [e.target.name]: e.target.value });
  };

  // Error handlig
  const openErrorWindow = () => {
    setErrorWindow(true);
  };

  const handleModalClose = (modal) => {
    if (modal === "error") {
      setErrorWindow(false);
    }
  };

  // handle Add email address
  const ADD_EMAIL = gql`
    mutation AddEmail($email: String!) {
      addEmail(email: $email) {
        resultCode
      }
    }
  `;

  const [addEmail] = useMutation(ADD_EMAIL);

  // handle Add New Email
  const addNewEmail = async () => {
    try {
      if (loadingAddEmail) {
        return;
      }

      //setSocialAccountMessage(null);
      console.log("addNewEmail");

      //validate email
      let newWarnings = [];

      if (
        emailInput.trim().length < 5 ||
        emailInput.trim().length > 100 ||
        !isEmail(emailInput)
      ) {
        newWarnings.push("email");
      }

      if (newWarnings.length !== 0) {
        setWarnings(newWarnings);
        return;
      }

      setLoadingAddEmail(true);
      setWarnings([]);

      const result = await addEmail({
        variables: { email: emailInput },
      });

      console.log({ result });

      const { resultCode } = result.data.addEmail;
      setLoadingAddEmail(false);

      if (resultCode === 6) {
        setAddEmailMessage("The email address is already in use.");
        setWarnings(["email"]);
        return;
      }

      if (resultCode === 7) {
        setAddEmailMessage(
          "You recently entered a new email address that hasn't been verified yet. You can enter a different address in a few minutes."
        );
        return;
      }

      if (resultCode === 8) {
        setAddEmailMessage(
          "This email address cannot be used. Please enter a different email."
        );
        setWarnings(["email"]);
        return;
      }

      if (resultCode !== 0) {
        setAddEmailMessage(null);
        openErrorWindow();
        return;
      }

      setAddEmailMessage(null);
      setChangeEmailForm(false);
      setEmailInput("");
      setWarnings([]);

      setTimeout(() => refetch(), 800);
      //refetch();
    } catch (error) {
      //captureException(error);
      setAddEmailMessage(null);
      openErrorWindow();
      setLoadingAddEmail(false);
      return;
    }
  };

  // handle Change Password
  const CHANGE_PASSWORD = gql`
    mutation ChangePassword($password: String!) {
      changePassword(password: $password) {
        resultCode
      }
    }
  `;

  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const changeAccountPassword = async () => {
    try {
      if (loadingChangePassword) {
        return;
      }

      setChangePasswordMessage(null);
      //setSocialAccountMessage(null);

      //validate password
      let newWarnings = [];
      console.log("1");

      if (
        passwordInput.password.trim().length > 100 ||
        !validatePasswordString(passwordInput.password)
      ) {
        newWarnings.push("password");
        setChangePasswordMessage(
          "The password must contain at least 8 characters, one lowercase letter, one uppercase letter, and a digit."
        );
      } else if (
        passwordInput.confirmPassword.trim() !== passwordInput.password.trim()
      ) {
        newWarnings.push("confirmPassword");
        setChangePasswordMessage("The passwords do not match.");
      }

      if (newWarnings.length !== 0) {
        setWarnings(newWarnings);
        return;
      }
      console.log("2");

      setLoadingChangePassword(true);
      setWarnings([]);
      console.log("3", passwordInput.password);

      const result = await changePassword({
        variables: { password: passwordInput.password.trim() },
      });
      console.log({ result });

      const { resultCode } = result.data.changePassword;
      setLoadingChangePassword(false);

      if (resultCode !== 0) {
        openErrorWindow();
        return;
      }

      setChangePasswordForm(false);
      setPasswordInput({
        password: "",
        confirmPassword: "",
      });
      setWarnings([]);
      setChangePasswordSuccess(true);

      setTimeout(() => setChangePasswordSuccess(false), 5000);
    } catch (error) {
      console.log("changeAccountPassword error", error);
      setLoadingChangePassword(false);
      //captureException(error);
      openErrorWindow();
      return;
    }
  };

  // handle Google connection
  const CONNECT_GOOGLE = gql`
    mutation ConnectGoogle($accessToken: String!) {
      connectGoogle(accessToken: $accessToken) {
        resultCode
      }
    }
  `;

  const [connectGoogle] = useMutation(CONNECT_GOOGLE);

  const connectGoogleAccount = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        console.log({ codeResponse });

        if (
          loadingConnectGoogle ||
          loadingConnectApple ||
          loadingConnectFacebook
        )
          return;

        setConnectMessage(null);
        setLoadingConnectGoogle(true);

        const result = await connectGoogle({
          variables: {
            accessToken: codeResponse.access_token,
          },
        });

        console.log("result", result);

        const { resultCode } = result.data.connectGoogle;

        console.log({ resultCode });

        if (resultCode === 7) {
          // this Google account is already connected to another account
          setConnectMessage(
            "This Google account is already connected to another Scalelup account."
          );
          setLoadingConnectGoogle(false);
          return;
        }

        if (resultCode !== 0) {
          // account connection failed
          setLoadingConnectGoogle(false);
          handleModalClose();
          openErrorWindow();

          return;
        }

        // connection successfull
        refetch();
        return;
      } catch (error) {
        //captureException(error);
        setLoadingConnectGoogle(false);
        handleModalClose();
        openErrorWindow();
        return;
      }
    },
  });

  // handle Apple connection
  const CONNECT_APPLE = gql`
    mutation ConnectApple($code: String!) {
      connectApple(code: $code) {
        resultCode
      }
    }
  `;

  const [connectApple] = useMutation(CONNECT_APPLE);

  const connectAppleAccount = async (message) => {
    try {
      console.log("message", message);
      if (!message.authorization) return;

      if (loadingConnectGoogle || loadingConnectApple || loadingConnectFacebook)
        return;

      setConnectMessage(null);
      setLoadingConnectApple(true);

      const result = await connectApple({
        variables: {
          code: message.authorization.code,
          // code: "c20c0fe6794234de1a52eb6d603775ecb.0.rxvv.463ntMT2Kt3lprjM7zUaYQ",
        },
      });

      console.log("result", result);

      const { resultCode } = result.data.connectApple;

      console.log({ resultCode });

      if (resultCode === 7) {
        // this Apple account is already connected to another account
        setConnectMessage(
          "This Apple account is already connected to another Scalelup account."
        );
        setLoadingConnectApple(false);
        return;
      }

      if (resultCode !== 0) {
        // account connection failed
        setLoadingConnectApple(false);
        handleModalClose();
        openErrorWindow();

        return;
      }

      // connection successfull
      refetch();
      return;
    } catch (error) {
      //captureException(error);
      setLoadingConnectApple(false);
      handleModalClose();
      openErrorWindow();
      return;
    }
  };

  // handle Facebook connection
  const CONNECT_FACEBOOK = gql`
    mutation ConnectFacebook($accessToken: String!) {
      connectFacebook(accessToken: $accessToken) {
        resultCode
      }
    }
  `;

  const [connectFacebook] = useMutation(CONNECT_FACEBOOK);

  const connectFacebookAccount = async (response) => {
    try {
      console.log("response.accessToken", response.accessToken);
      if (!response.accessToken) return;

      if (loadingConnectGoogle || loadingConnectApple || loadingConnectFacebook)
        return;

      setConnectMessage(null);
      setLoadingConnectFacebook(true);

      const result = await connectFacebook({
        variables: {
          accessToken: response.accessToken,
        },
      });

      console.log("result", result);

      const { resultCode } = result.data.connectFacebook;

      console.log({ resultCode });

      if (resultCode === 7) {
        // this Facebook account is already connected to another account
        setConnectMessage(
          "This Facebook account is already connected to another Scalelup account."
        );
        setLoadingConnectFacebook(false);
        return;
      }

      if (resultCode !== 0) {
        // account connection failed
        setLoadingConnectFacebook(false);
        handleModalClose();
        openErrorWindow();

        return;
      }

      // connection successfull
      refetch();
      return;
    } catch (error) {
      //captureException(error);
      setLoadingConnectFacebook(false);
      handleModalClose();
      openErrorWindow();
      return;
    }
  };

  // handle Notifications settings
  const TOGGLE_NOTIFICATIONS_SWITCH = gql`
    mutation ToggleNotificationsSwitch($notification: String!) {
      toggleNotificationsSwitch(notification: $notification) {
        id
        notifications_newsletter
      }
    }
  `;

  const [toggleNotificationsSwitch] = useMutation(TOGGLE_NOTIFICATIONS_SWITCH);

  const onNotificationsToggleClick = async (notification) => {
    try {
      setLoadingNotificationsToggle(true);

      const result = await toggleNotificationsSwitch({
        variables: {
          notification,
        },
      });

      console.log("result", result);
      setLoadingNotificationsToggle(false);
    } catch (error) {
      //captureException(error);
      setLoadingNotificationsToggle(false);
      handleModalClose();
      openErrorWindow();
      return;
    }
  };

  // console.log({ data });
  // console.log({ warnings, connectMessage });
  return (
    <>
      {!data && (
        <LoadingBox>
          <BoxLoadingSpinner />
        </LoadingBox>
      )}
      {data && (
        <AccountSettings
          data={data}
          changeEmailForm={changeEmailForm}
          changeEmailFormToggle={changeEmailFormToggle}
          emailInput={emailInput}
          handleEmailInputChange={handleEmailInputChange}
          warnings={warnings}
          changePasswordForm={changePasswordForm}
          changePasswordFormToggle={changePasswordFormToggle}
          passwordInput={passwordInput}
          handlePasswordInputChange={handlePasswordInputChange}
          addNewEmail={addNewEmail}
          addEmailMessage={addEmailMessage}
          loadingAddEmail={loadingAddEmail}
          changeAccountPassword={changeAccountPassword}
          changePasswordMessage={changePasswordMessage}
          changePasswordSuccess={changePasswordSuccess}
          connectAppleAccount={connectAppleAccount}
          connectMessage={connectMessage}
          connectGoogleAccount={connectGoogleAccount}
          connectFacebookAccount={connectFacebookAccount}
          onNotificationsToggleClick={onNotificationsToggleClick}
          loadingNotificationsToggle={loadingNotificationsToggle}
          loadingChangePassword={loadingChangePassword}
        />
      )}
      {errorWindow && (
        <ModalWrap>
          <Backdrop
            onClick={handleModalClose}
            parameters="error"
            background="rgba(0, 0, 0, 0.7)"
          />
          <MessageWindow handleModalClose={handleModalClose} type="error" />
        </ModalWrap>
      )}
    </>
  );
};
