import styled from "styled-components";
import { useEffect, useRef, useState } from "react";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import { dateFormatted, getTimeAMPM } from "modules/functions";
import { AWS_PROFILE_IMAGES_URL } from "constants";
import Layout from "components/Layout";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import Bubble from "./Bubble";
import MessageFormContainer from "./MessageFormContainer";
import ModalWrap from "components/other/ModalWrap";
import Backdrop from "components/other/Backdrop";
import PostPreviewContainer from "./PostPreviewContainer";
import BadgeTitle from "components/other/BadgeTitle";
import RoundActionIcon from "components/buttons/RoundActionIcon";
import ContextMenu from "components/menu/ContextMenu";
import TextLink from "components/text/TextLink";

import dotsEmptyIcon from "assets/img/icons/icons8-dots-empty.svg";
import dotsIcon from "assets/img/icons/icons8-dots.svg";
import blockIcon from "assets/img/icons/icons8-unavailable.svg";
import checkmarkIcon from "assets/img/icons/icons8-checkmark.svg";

const Chat = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

const Header = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuWrap = styled.div`
  position: relative;
`;

const MenuIconBox = styled.div``;

const ChatMenu = styled.div`
  position: absolute;
  top: 42px;
  right: 0;

  transition: 0.2s;

  opacity: ${(props) => (props.opened ? 100 : 0)};
`;

const ChatTitle = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};
  margin-bottom: 16px;

  font-size: 36px;
  font-weight: 500;

  display: flex;
  align-items: center;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 26px;
  }
`;

const TitleProfileImage = styled.div`
  margin-right: 15px;

  width: 48px;
  min-width: 48px;
  height: 48px;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 28px;
  }
`;

const ChatBoxWrap = styled.div`
  border-radius: ${css.BOX_RADIUS_PRIMARY};
  overflow: hidden;

  display: flex;
  align-items: flex-start;
`;

const ChatBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;
  height: calc(100vh - 224px);
  max-height: calc(100vh - 224px);
  min-height: 300px;

  background: ${css.BLUE_BOX_GRADIENT};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_LARGE};

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
  }

  @media (max-width: ${globalConstants.switchTablet}) {
    height: calc(100vh - 290px);
    max-height: calc(100vh - 290px);
  }
`;

const Messages = styled.div`
  width: 100%;
  padding-right: 10px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
  flex: 1;
`;

const MessageWrap = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ChatDate = styled.div`
  width: 100%;
  margin-top: 5px;

  text-align: center;
  font-size: 13px;
  color: ${css.TEXT_GREY};
`;

const PartnerMessage = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

const OwnMessage = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const ProfileImageWrap = styled.div`
  min-width: 32px;
`;

const ProfileImage = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
`;

const MessageSpacer = styled.div`
  min-width: 20px;
`;

const MessageFormWrap = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const ChatInfo = styled.div`
  width: 100%;
  max-width: 420px;

  font-size: 13px;
  color: ${css.TEXT_GREY};
  text-align: center;
  line-height: 125%;
`;

const BadgeWrap = styled.div`
  margin: 2px 0;

  align-self: center;
`;

export default ({
  data,
  chatId,
  subscribeToNewMessages,
  onInputFocus,
  onMessageSent,
  partnerShardNumber,
  postId,
  onChatMenuClick,
  closeChatMenu,
  chatMenuOpened,
  onBlockUser,
  blockingUser,
}) => {
  const chatMessagesRef = useRef();

  const shardNumber = localStorage.getItem("sn");

  const [postPreviewModal, setPostPreviewModal] = useState(false);
  const [postSnapshotId, setPostSnapshotId] = useState(null);
  const [postSnapshotShardNumber, setPostSnapshotShardNumber] = useState(null);

  useEffect(() => {
    if (data) {
      if (subscribeToNewMessages) subscribeToNewMessages();

      if (chatMessagesRef.current) {
        chatMessagesRef.current.scrollTo({
          top: chatMessagesRef.current.scrollHeight,
        });
      }
    }
  }, [data]);

  console.log("data", data);

  // handle Post message preview click
  const openPostPreview = (postSnapshotId, postSnapshotShardNumber) => {
    if (!postSnapshotId) return;

    setPostSnapshotId(postSnapshotId);
    setPostSnapshotShardNumber(postSnapshotShardNumber);
    setPostPreviewModal(true);
  };

  const onCloseModal = () => {
    setPostPreviewModal(false);
  };

  const menuIconRef = useRef();

  let list = [];

  let currentDate = dateFormatted(new Date(0));
  let showDate = false;

  if (data) {
    // create list of Post messages
    list = data.chat.messages.map((message, index) => {
      const ownMessage = message.chat_id === chatId;

      const date = new Date(message.created_at);
      const messageDate = dateFormatted(date);
      const messageTime = getTimeAMPM(date);

      if (currentDate !== messageDate) {
        showDate = true;
        currentDate = messageDate;
      } else {
        showDate = false;
      }

      if (ownMessage) {
        return (
          <MessageWrap key={message.id}>
            {showDate && <ChatDate>{messageDate}</ChatDate>}
            {index === 0 && data.chat.partnerReaction && (
              <BadgeWrap>
                <BadgeTitle background={css.BRAND_INDIGO}>
                  {data.chat.username} reacted to your post
                </BadgeTitle>
              </BadgeWrap>
            )}
            <OwnMessage>
              <MessageSpacer />
              <ProfileImageWrap />
              <Bubble
                time={messageTime}
                own={true}
                read={message.read}
                postPreviewId={postId ? postId : message.post_snapshot_id}
                postSnapshotShardNumber={shardNumber}
                image={message.image}
                onBubbleClick={openPostPreview}
                messageId={message.id}
              >
                {message.message}
              </Bubble>
            </OwnMessage>
          </MessageWrap>
        );
      } else {
        return (
          <MessageWrap key={message.id}>
            {showDate && <ChatDate>{messageDate}</ChatDate>}
            <PartnerMessage>
              <ProfileImageWrap>
                <ProfileImage>
                  <img
                    src={AWS_PROFILE_IMAGES_URL + data.chat.profileImage}
                    alt="Profile image"
                    style={{ width: "100%", borderRadius: "50%" }}
                  />
                </ProfileImage>
              </ProfileImageWrap>
              <Bubble
                time={messageTime}
                postPreviewId={postId ? postId : message.post_snapshot_id}
                postSnapshotShardNumber={partnerShardNumber}
                image={message.image}
                onBubbleClick={openPostPreview}
                messageId={message.id}
              >
                {message.message}
              </Bubble>
              <MessageSpacer />
            </PartnerMessage>
          </MessageWrap>
        );
      }
    });
  }

  console.log({ postId });
  console.log({ partnerShardNumber, shardNumber });
  console.log({ blockingUser });

  return (
    <>
      <Layout>
        <Chat>
          {data && (
            <Header>
              <ChatTitle>
                <TitleProfileImage>
                  <img
                    src={AWS_PROFILE_IMAGES_URL + data.chat.profileImage}
                    alt="Profile image"
                    style={{ width: "100%", borderRadius: "50%" }}
                  />
                </TitleProfileImage>
                {data.chat.username}
              </ChatTitle>
              {!postId && (
                <MenuWrap>
                  <MenuIconBox ref={menuIconRef}>
                    <RoundActionIcon
                      icon={chatMenuOpened ? dotsIcon : dotsEmptyIcon}
                      iconAlt="Chat menu"
                      onClick={onChatMenuClick}
                    />
                  </MenuIconBox>
                  <ChatMenu opened={chatMenuOpened ? 1 : 0}>
                    <ContextMenu
                      items={[
                        {
                          icon: blockingUser ? checkmarkIcon : blockIcon,
                          label: blockingUser ? "Unblock" : "Block user",
                          onClick: onBlockUser,
                        },
                      ]}
                      menuIconRef={menuIconRef}
                      closeMenu={closeChatMenu}
                    />
                  </ChatMenu>
                </MenuWrap>
              )}
            </Header>
          )}
          <ChatBoxWrap>
            <ChatBox id="chatBox">
              {list.length === 0 && <BoxLoadingSpinner height="100%" />}
              {list.length !== 0 && (
                <Messages id="chatMessages" ref={chatMessagesRef}>
                  {list}
                </Messages>
              )}
              {list.length !== 0 && (
                <MessageFormWrap>
                  {!chatId && (
                    <ChatInfo>
                      You can send one message to a user you have never
                      communicated with before. If you receive a reply, you can
                      continue chatting without limitations.
                    </ChatInfo>
                  )}
                  {!blockingUser && (
                    <MessageFormContainer
                      chatId={chatId}
                      onInputFocus={onInputFocus}
                      onMessageSent={onMessageSent}
                      partnerShardNumber={partnerShardNumber}
                      postId={postId}
                    />
                  )}
                  {blockingUser && (
                    <ChatInfo>
                      The user is blocked.{" "}
                      <TextLink onClick={onBlockUser}>Click here</TextLink> to
                      unblock them.
                    </ChatInfo>
                  )}
                </MessageFormWrap>
              )}
            </ChatBox>
          </ChatBoxWrap>
        </Chat>
      </Layout>
      {postPreviewModal && (
        <ModalWrap>
          <Backdrop onClick={onCloseModal} background="rgba(0, 0, 0, 0.7)" />
          <PostPreviewContainer
            postId={postId}
            postSnapshotId={postSnapshotId}
            postShardNumber={
              postId ? partnerShardNumber : postSnapshotShardNumber
            }
            snapshot={postId ? false : true}
            handleModalClose={onCloseModal}
          />
        </ModalWrap>
      )}
    </>
  );
};
