import { SAVE_PROFILE_DATA, SAVE_IP_ADDRESS } from "./actionTypes";

export const saveProfileData = (data) => ({
  type: SAVE_PROFILE_DATA,
  payload: data,
});

export const saveIpAddress = (data) => ({
  type: SAVE_IP_ADDRESS,
  payload: data,
});
