import styled from "styled-components";
import { useState, useEffect } from "react";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { timeSince, daysUntil } from "modules/functions";
import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import Tip from "components/other/Tip";
import RankingBox from "components/contest/RankingBox";
import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";

import pointsIcon from "assets/img/icons/points-icon.svg";
import dollarIcon from "assets/img/icons/dollar-icon.svg";
import flagIcon from "assets/img/icons/flag-icon-green.svg";

const SideContest = styled.div`
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const Row = styled.div`
  min-width: 190px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DataRow = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

const DataIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataIcon = styled.div`
  width: ${(props) => (props.width ? props.width : "20px")};
  min-width: ${(props) => (props.width ? props.width : "20px")};
  height: ${(props) => (props.width ? props.width : "20px")};
`;

const DataText = styled.div`
  white-space: nowrap;
`;

const RankingWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DataTitle = styled.div`
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 6px;
`;

const ContestButtonWrap = styled.div`
  display: flex;
`;

export default ({ data }) => {
  const navigate = useNavigate();

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      profileData: user.profileData,
    })
  );

  const { profileData } = useSelector(UserProperties);

  let ongoingPrizePool = 0;
  let ongoingPoints = 0;
  let ongoingEndsIn = 7;
  let rankingGroup = 4;

  if (data) {
    const { ongoingPrizes, ongoingEnds } = data.contestData;

    ongoingPoints = data.contestData.ongoingPoints;
    ongoingEndsIn = daysUntil(ongoingEnds);
    rankingGroup = data.contestData.rankingGroup;

    for (const prize in ongoingPrizes) {
      ongoingPrizePool += ongoingPrizes[prize];
    }
  }

  return (
    <SideContest>
      {!data && <BoxLoadingSpinner height="200px" padding="0 0 38px 0" />}
      <Row>
        <DataRow>
          <DataIconBox>
            <DataIcon>
              <img src={pointsIcon} style={{ width: "100%" }} />
            </DataIcon>
          </DataIconBox>
          <DataText>{ongoingPoints} points</DataText>
        </DataRow>
        <DataRow>
          <DataIconBox>
            <DataIcon>
              <img src={flagIcon} style={{ width: "100%" }} />
            </DataIcon>
          </DataIconBox>
          <DataText>
            {ongoingEndsIn} day{ongoingEndsIn > 1 ? "s" : ""}
          </DataText>
        </DataRow>
      </Row>
      <RankingWrap>
        <DataTitle>
          Your Ranking
          <Tip tip="Your current position in the contest. Data is updated every hour." />
        </DataTitle>
        <RankingBox
          rankingGroup={rankingGroup}
          profileImage={profileData.profileImage}
        />
      </RankingWrap>
      <DataRow>
        <DataIconBox>
          <DataIcon>
            <img src={dollarIcon} style={{ width: "100%" }} />
          </DataIcon>
        </DataIconBox>
        <DataText>{ongoingPrizePool} USD</DataText>
      </DataRow>
      <ContestButtonWrap>
        <ButtonPrimarySmall onClick={() => navigate("/contest")}>
          Scalelup Contest
        </ButtonPrimarySmall>
      </ContestButtonWrap>
    </SideContest>
  );
};
