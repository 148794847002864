import styled from "styled-components";
import { useState } from "react";

import * as css from "styles/CssVariables.js";
import SideStatsContainer from "./SideStatsContainer";
import SideEventsContainer from "./SideEventsContainer";

import graphIcon from "assets/img/icons/graph-icon.svg";
import playIcon from "assets/img/icons/play-icon.svg";
import dotIcon from "assets/img/icons/icons8-dot.svg";
import dotEmptyIcon from "assets/img/icons/icons8-dot-empty.svg";

const SideContentBox = styled.div`
  width: 260px;
  box-sizing: border-box;

  margin: 0;
  padding: 25px;

  border-radius: ${css.BOX_RADIUS_PRIMARY};
  background: ${css.BLUE_BOX_GRADIENT};

  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderIcon = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;
`;

const ToggleCircle = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;

  border-radius: 18px;
  background: ${css.ROUND_ICON_BACKGROUND};

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    filter: brightness(120%);
    cursor: pointer;
  }
`;

const HeaderDots = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.toggle ? "column" : "column-reverse")};

  align-items: center;
  gap: 2px;
`;

const DotsIcon = styled.div`
  width: 6px;
  min-width: 6px;
  height: 6px;

  display: flex;
`;

export default () => {
  const [display, setDisplay] = useState("stats");

  const onDisplayToggle = () => {
    setDisplay((prevState) => {
      if (prevState === "stats") return "events";
      if (prevState === "events") return "stats";
    });
  };

  return (
    <SideContentBox>
      <Header>
        <HeaderIcon>
          <img
            src={display === "stats" ? graphIcon : playIcon}
            style={{ width: "100%" }}
          />
        </HeaderIcon>
        <ToggleCircle onClick={onDisplayToggle}>
          <HeaderDots toggle={display === "stats" ? 1 : 0}>
            <DotsIcon>
              <img src={dotIcon} style={{ width: "100%" }} />
            </DotsIcon>
            <DotsIcon>
              <img src={dotEmptyIcon} style={{ width: "100%" }} />
            </DotsIcon>
          </HeaderDots>
        </ToggleCircle>
      </Header>
      {display === "stats" && <SideStatsContainer />}
      {display === "events" && <SideEventsContainer />}
    </SideContentBox>
  );
};
