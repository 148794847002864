import afganistanIcon from "../assets/img/icons/countries/icons8-afghanistan-flag.svg";
import albaniaIcon from "../assets/img/icons/countries/icons8-albania-circular.svg";
import algeriaIcon from "../assets/img/icons/countries/icons8-algeria.svg";
import andorraIcon from "../assets/img/icons/countries/icons8-andorra.svg";
import angolaIcon from "../assets/img/icons/countries/icons8-angola.svg";
import antiguaandbarbudaIcon from "../assets/img/icons/countries/icons8-antigua-and-barbuda.svg";
import argentinaIcon from "../assets/img/icons/countries/icons8-argentina.svg";
import armeniaIcon from "../assets/img/icons/countries/icons8-armenia.svg";
import australiaIcon from "../assets/img/icons/countries/icons8-australia.svg";
import austriaIcon from "../assets/img/icons/countries/icons8-austria.svg";
import azerbaijanIcon from "../assets/img/icons/countries/icons8-azerbaijan.svg";
import bahamasIcon from "../assets/img/icons/countries/icons8-bahamas.svg";
import bahrainIcon from "../assets/img/icons/countries/icons8-bahrain-circular.svg";
import bangladeshIcon from "../assets/img/icons/countries/icons8-bangladesh.svg";
import barbadosIcon from "../assets/img/icons/countries/icons8-barbados.svg";
import belarusIcon from "../assets/img/icons/countries/icons8-belarus.svg";
import belgiumIcon from "../assets/img/icons/countries/icons8-belgium.svg";
import belizeIcon from "../assets/img/icons/countries/icons8-belize-circular.svg";
import beninIcon from "../assets/img/icons/countries/icons8-benin.svg";
import bhutanIcon from "../assets/img/icons/countries/icons8-bhutan-circular.svg";
import boliviaIcon from "../assets/img/icons/countries/icons8-bolivia.svg";
import bosniaandherzegovinaIcon from "../assets/img/icons/countries/icons8-bosnia-and-herzegovina.svg";
import botswanaIcon from "../assets/img/icons/countries/icons8-botswana.svg";
import brazilIcon from "../assets/img/icons/countries/icons8-brazil.svg";
import bruneiIcon from "../assets/img/icons/countries/icons8-brunei-darussalam-circular.svg";
import bulgariaIcon from "../assets/img/icons/countries/icons8-bulgaria.svg";
import burkinafasoIcon from "../assets/img/icons/countries/icons8-burkina-faso.svg";
import burundiIcon from "../assets/img/icons/countries/icons8-burundi.svg";
import capeverdeIcon from "../assets/img/icons/countries/icons8-cape-verde-circular.svg";
import cambodiaIcon from "../assets/img/icons/countries/icons8-cambodia-circular.svg";
import cameroonIcon from "../assets/img/icons/countries/icons8-cameroon.svg";
import canadaIcon from "../assets/img/icons/countries/icons8-canada.svg";
import centralafricanrepublicIcon from "../assets/img/icons/countries/icons8-central-african-republic-circular.svg";
import chadIcon from "../assets/img/icons/countries/icons8-chad.svg";
import chileIcon from "../assets/img/icons/countries/icons8-chile.svg";
import chinaIcon from "../assets/img/icons/countries/icons8-china.svg";
import colombiaIcon from "../assets/img/icons/countries/icons8-colombia.svg";
import comorosIcon from "../assets/img/icons/countries/icons8-comoros-circular.svg";
import congoIcon from "../assets/img/icons/countries/icons8-congo.svg";
import costaricaIcon from "../assets/img/icons/countries/icons8-costa-rica.svg";
import croatiaIcon from "../assets/img/icons/countries/icons8-croatia.svg";
import cubaIcon from "../assets/img/icons/countries/icons8-cuba.svg";
import cyprusIcon from "../assets/img/icons/countries/icons8-cyprus.svg";
import czechrepublicIcon from "../assets/img/icons/countries/icons8-czech-republic.svg";
import democraticrepublicofthecongoIcon from "../assets/img/icons/countries/icons8-democratic-republic-congo-flag-circle.svg";
import denmarkIcon from "../assets/img/icons/countries/icons8-denmark.svg";
import djiboutiIcon from "../assets/img/icons/countries/icons8-djibouti.svg";
import dominicaIcon from "../assets/img/icons/countries/icons8-dominica-circular.svg";
import dominicanrepublicIcon from "../assets/img/icons/countries/icons8-dominican-republic.svg";
import ecuadorIcon from "../assets/img/icons/countries/icons8-ecuador.svg";
import egyptIcon from "../assets/img/icons/countries/icons8-egypt.svg";
import elsalvadorIcon from "../assets/img/icons/countries/icons8-el-salvador.svg";
import equatorialguineaIcon from "../assets/img/icons/countries/icons8-equatorial-guinea-circular.svg";
import eritreaIcon from "../assets/img/icons/countries/icons8-eritrea.svg";
import estoniaIcon from "../assets/img/icons/countries/icons8-estonia.svg";
import eswatiniIcon from "../assets/img/icons/countries/icons8-eswatini.svg";
import ethiopiaIcon from "../assets/img/icons/countries/icons8-ethiopia.svg";
import fijiIcon from "../assets/img/icons/countries/icons8-fiji-circular.svg";
import finlandIcon from "../assets/img/icons/countries/icons8-finland.svg";
import franceIcon from "../assets/img/icons/countries/icons8-france.svg";
import gabonIcon from "../assets/img/icons/countries/icons8-gabon.svg";
import gambiaIcon from "../assets/img/icons/countries/icons8-gambia.svg";
import georgiaIcon from "../assets/img/icons/countries/icons8-georgia-circular.svg";
import germanyIcon from "../assets/img/icons/countries/icons8-germany.svg";
import ghanaIcon from "../assets/img/icons/countries/icons8-ghana-circular.svg";
import greeceIcon from "../assets/img/icons/countries/icons8-greece.svg";
import grenadaIcon from "../assets/img/icons/countries/icons8-grenada-circular.svg";
import guatemalaIcon from "../assets/img/icons/countries/icons8-guatemala.svg";
import guineaIcon from "../assets/img/icons/countries/icons8-guinea-circular.svg";
import guineabissauIcon from "../assets/img/icons/countries/icons8-guinea-bissau.svg";
import guyanaIcon from "../assets/img/icons/countries/icons8-guyana.svg";
import haitiIcon from "../assets/img/icons/countries/icons8-the-republic-of-haiti.svg";
import hondurasIcon from "../assets/img/icons/countries/icons8-honduras.svg";
import hungaryIcon from "../assets/img/icons/countries/icons8-hungary.svg";
import icelandIcon from "../assets/img/icons/countries/icons8-iceland.svg";
import indiaIcon from "../assets/img/icons/countries/icons8-india.svg";
import indonesiaIcon from "../assets/img/icons/countries/icons8-indonesia.svg";
import iranIcon from "../assets/img/icons/countries/icons8-iran.svg";
import iraqIcon from "../assets/img/icons/countries/icons8-iraq.svg";
import irelandIcon from "../assets/img/icons/countries/icons8-ireland.svg";
import israelIcon from "../assets/img/icons/countries/icons8-israel.svg";
import italyIcon from "../assets/img/icons/countries/icons8-italy.svg";
import jamaicaIcon from "../assets/img/icons/countries/icons8-jamaica.svg";
import japanIcon from "../assets/img/icons/countries/icons8-japan.svg";
import jordanIcon from "../assets/img/icons/countries/icons8-jordan.svg";
import kazakhstanIcon from "../assets/img/icons/countries/icons8-kazakhstan.svg";
import kenyaIcon from "../assets/img/icons/countries/icons8-kenya.svg";
import kiribatiIcon from "../assets/img/icons/countries/icons8-kiribati-circular.svg";
import kuwaitIcon from "../assets/img/icons/countries/icons8-kuwait.svg";
import kyrgyzstanIcon from "../assets/img/icons/countries/icons8-kyrgyzstan.svg";
import laosIcon from "../assets/img/icons/countries/icons8-laos.svg";
import latviaIcon from "../assets/img/icons/countries/icons8-latvia.svg";
import lebanonIcon from "../assets/img/icons/countries/icons8-lebanon.svg";
import lesothoIcon from "../assets/img/icons/countries/icons8-lesotho.svg";
import liberiaIcon from "../assets/img/icons/countries/icons8-liberia.svg";
import libyaIcon from "../assets/img/icons/countries/icons8-libya.svg";
import liechtensteinIcon from "../assets/img/icons/countries/icons8-liechtenstein-circular.svg";
import lithuaniaIcon from "../assets/img/icons/countries/icons8-lithuania.svg";
import luxembourgIcon from "../assets/img/icons/countries/icons8-luxembourg.svg";
import madagascarIcon from "../assets/img/icons/countries/icons8-madagascar.svg";
import malawiIcon from "../assets/img/icons/countries/icons8-malawi.svg";
import malaysiaIcon from "../assets/img/icons/countries/icons8-malaysia.svg";
import maldivesIcon from "../assets/img/icons/countries/icons8-maldives-circular.svg";
import maliIcon from "../assets/img/icons/countries/icons8-mali.svg";
import maltaIcon from "../assets/img/icons/countries/icons8-malta-circular.svg";
import marshallislandsIcon from "../assets/img/icons/countries/icons8-marshall-islands-circular.svg";
import mauritaniaIcon from "../assets/img/icons/countries/icons8-mauritania.svg";
import mauritiusIcon from "../assets/img/icons/countries/icons8-mauritius.svg";
import mexicoIcon from "../assets/img/icons/countries/icons8-mexico.svg";
import micronesiaIcon from "../assets/img/icons/countries/icons8-micronesia-circular.svg";
import moldovaIcon from "../assets/img/icons/countries/icons8-moldova-circular.svg";
import monacoIcon from "../assets/img/icons/countries/icons8-monaco.svg";
import mongoliaIcon from "../assets/img/icons/countries/icons8-mongolia.svg";
import montenegroIcon from "../assets/img/icons/countries/icons8-montenegro-circular.svg";
import moroccoIcon from "../assets/img/icons/countries/icons8-morocco.svg";
import mozambiqueIcon from "../assets/img/icons/countries/icons8-mozambique.svg";
import myanmarIcon from "../assets/img/icons/countries/icons8-myanmar.svg";
import namibiaIcon from "../assets/img/icons/countries/icons8-namibia.svg";
import nauruIcon from "../assets/img/icons/countries/icons8-nauru-circular.svg";
import nepalIcon from "../assets/img/icons/countries/icons8-nepal-circular.svg";
import netherlandsIcon from "../assets/img/icons/countries/icons8-netherlands.svg";
import newzealandIcon from "../assets/img/icons/countries/icons8-new-zealand.svg";
import nicaraguaIcon from "../assets/img/icons/countries/icons8-nicaragua-circular.svg";
import nigerIcon from "../assets/img/icons/countries/icons8-niger-circular.svg";
import nigeriaIcon from "../assets/img/icons/countries/icons8-nigeria-circular.svg";
import northmacedoniaIcon from "../assets/img/icons/countries/icons8-macedonia.svg";
import norwayIcon from "../assets/img/icons/countries/icons8-norway.svg";
import omanIcon from "../assets/img/icons/countries/icons8-oman.svg";
import pakistanIcon from "../assets/img/icons/countries/icons8-pakistan.svg";
import palestinestateIcon from "../assets/img/icons/countries/icons8-palestine-circular.svg";
import panamaIcon from "../assets/img/icons/countries/icons8-panama.svg";
import papuanewguineaIcon from "../assets/img/icons/countries/icons8-papua-new-guinea-circular.svg";
import paraguayIcon from "../assets/img/icons/countries/icons8-paraguay-circular.svg";
import peruIcon from "../assets/img/icons/countries/icons8-peru.svg";
import philippinesIcon from "../assets/img/icons/countries/icons8-philippines.svg";
import polandIcon from "../assets/img/icons/countries/icons8-poland.svg";
import portugalIcon from "../assets/img/icons/countries/icons8-portugal.svg";
import qatarIcon from "../assets/img/icons/countries/icons8-qatar.svg";
import romaniaIcon from "../assets/img/icons/countries/icons8-romania.svg";
import russiaIcon from "../assets/img/icons/countries/icons8-russian-federation.svg";
import rwandaIcon from "../assets/img/icons/countries/icons8-rwanda-circular.svg";
import saintkittsandnevisIcon from "../assets/img/icons/countries/icons8-saint-kitts-and-nevis-circular.svg";
import saintluciaIcon from "../assets/img/icons/countries/icons8-saint-lucia-circular.svg";
import saintvincentandthegrenadinesIcon from "../assets/img/icons/countries/icons8-saint-vincent-and-the-grenadines-circular.svg";
import samoaIcon from "../assets/img/icons/countries/icons8-samoa-circular.svg";
import sanmarinoIcon from "../assets/img/icons/countries/icons8-san-marino.svg";
import saotomeandprincipeIcon from "../assets/img/icons/countries/icons8-sao-tome-and-principe-circular.svg";
import saudiarabiaIcon from "../assets/img/icons/countries/icons8-saudi-arabia.svg";
import senegalIcon from "../assets/img/icons/countries/icons8-senegal-circular.svg";
import serbiaIcon from "../assets/img/icons/countries/icons8-serbia.svg";
import seychellesIcon from "../assets/img/icons/countries/icons8-seychelles.svg";
import sierraleoneIcon from "../assets/img/icons/countries/icons8-sierra-leone.svg";
import singaporeIcon from "../assets/img/icons/countries/icons8-singapore.svg";
import slovakiaIcon from "../assets/img/icons/countries/icons8-slovakia.svg";
import sloveniaIcon from "../assets/img/icons/countries/icons8-slovenia.svg";
import solomonislandsIcon from "../assets/img/icons/countries/icons8-solomon-islands-circular.svg";
import somaliaIcon from "../assets/img/icons/countries/icons8-somalia.svg";
import southafricaIcon from "../assets/img/icons/countries/icons8-south-africa.svg";
import southkoreaIcon from "../assets/img/icons/countries/icons8-south-korea.svg";
import southsudanIcon from "../assets/img/icons/countries/icons8-south-sudan.svg";
import spainIcon from "../assets/img/icons/countries/icons8-spain.svg";
import srilankaIcon from "../assets/img/icons/countries/icons8-sri-lanka.svg";
import sudanIcon from "../assets/img/icons/countries/icons8-sudan-circular.svg";
import surinameIcon from "../assets/img/icons/countries/icons8-suriname-circular.svg";
import swedenIcon from "../assets/img/icons/countries/icons8-sweden.svg";
import switzerlandIcon from "../assets/img/icons/countries/icons8-switzerland.svg";
import syriaIcon from "../assets/img/icons/countries/icons8-syria.svg";
import tajikistanIcon from "../assets/img/icons/countries/icons8-tajikistan.svg";
import tanzaniaIcon from "../assets/img/icons/countries/icons8-tanzania.svg";
import taiwanIcon from "../assets/img/icons/countries/icons8-taiwan-flag.svg";
import thailandIcon from "../assets/img/icons/countries/icons8-thailand.svg";
import timorlesteIcon from "../assets/img/icons/countries/icons8-timor-leste.svg";
import togoIcon from "../assets/img/icons/countries/icons8-togo.svg";
import tongaIcon from "../assets/img/icons/countries/icons8-tonga-circular.svg";
import trinidadandtobagoIcon from "../assets/img/icons/countries/icons8-trinidad-and-tobago.svg";
import tunisiaIcon from "../assets/img/icons/countries/icons8-tunisia.svg";
import turkeyIcon from "../assets/img/icons/countries/icons8-turkey.svg";
import turkmenistanIcon from "../assets/img/icons/countries/icons8-turkmenistan-circular.svg";
import tuvaluIcon from "../assets/img/icons/countries/icons8-tuvalu-circular.svg";
import ugandaIcon from "../assets/img/icons/countries/icons8-uganda.svg";
import ukraineIcon from "../assets/img/icons/countries/icons8-ukraine.svg";
import unitedarabemiratesIcon from "../assets/img/icons/countries/icons8-united-arab-emirates.svg";
import unitedkingdomIcon from "../assets/img/icons/countries/icons8-great-britain.svg";
import unitedstatesofamericaIcon from "../assets/img/icons/countries/icons8-usa.svg";
import uruguayIcon from "../assets/img/icons/countries/icons8-uruguay.svg";
import uzbekistanIcon from "../assets/img/icons/countries/icons8-uzbekistan.svg";
import vanuatuIcon from "../assets/img/icons/countries/icons8-vanuatu-circular.svg";
import venezuelaIcon from "../assets/img/icons/countries/icons8-venezuela.svg";
import vietnamIcon from "../assets/img/icons/countries/icons8-vietnam.svg";
import yemenIcon from "../assets/img/icons/countries/icons8-yemen.svg";
import zambiaIcon from "../assets/img/icons/countries/icons8-zambia-circular.svg";
import zimbabweIcon from "../assets/img/icons/countries/icons8-zimbabwe.svg";

export const COUNTRY_ICONS = {
  Afghanistan: {
    icon: afganistanIcon,
  },
  Albania: {
    icon: albaniaIcon,
  },
  Algeria: {
    icon: algeriaIcon,
  },
  Andorra: { icon: andorraIcon },
  Angola: { icon: angolaIcon },
  "Antigua and Barbuda": { icon: antiguaandbarbudaIcon },
  Argentina: { icon: argentinaIcon },
  Armenia: { icon: armeniaIcon },
  Australia: { icon: australiaIcon },
  Austria: { icon: austriaIcon },
  Azerbaijan: { icon: azerbaijanIcon },
  Bahamas: { icon: bahamasIcon },
  Bahrain: { icon: bahrainIcon },
  Bangladesh: { icon: bangladeshIcon },
  Barbados: { icon: barbadosIcon },
  Belarus: { icon: belarusIcon },
  Belgium: { icon: belgiumIcon },
  Belize: { icon: belizeIcon },
  Benin: { icon: beninIcon },
  Bhutan: { icon: bhutanIcon },
  Bolivia: { icon: boliviaIcon },
  "Bosnia and Herzegovina": { icon: bosniaandherzegovinaIcon },
  Botswana: { icon: botswanaIcon },
  Brazil: { icon: brazilIcon },
  Brunei: { icon: bruneiIcon },
  Bulgaria: { icon: bulgariaIcon },
  "Burkina Faso": { icon: burkinafasoIcon },
  Burundi: { icon: burundiIcon },
  "Cape Verde": { icon: capeverdeIcon },
  Cambodia: { icon: cambodiaIcon },
  Cameroon: { icon: cameroonIcon },
  Canada: { icon: canadaIcon },
  "Central African Republic": { icon: centralafricanrepublicIcon },
  Chad: { icon: chadIcon },
  Chile: { icon: chileIcon },
  China: { icon: chinaIcon },
  Colombia: { icon: colombiaIcon },
  Comoros: { icon: comorosIcon },
  Congo: { icon: congoIcon },
  "Costa Rica": { icon: costaricaIcon },
  Croatia: { icon: croatiaIcon },
  Cuba: { icon: cubaIcon },
  Cyprus: { icon: cyprusIcon },
  "Czech Republic": { icon: czechrepublicIcon },
  "Democratic Republic of the Congo": {
    icon: democraticrepublicofthecongoIcon,
  },
  Denmark: { icon: denmarkIcon },
  Djibouti: { icon: djiboutiIcon },
  Dominica: { icon: dominicaIcon },
  "Dominican Republic": { icon: dominicanrepublicIcon },
  Ecuador: { icon: ecuadorIcon },
  Egypt: { icon: egyptIcon },
  "El Salvador": { icon: elsalvadorIcon },
  "Equatorial Guinea": { icon: equatorialguineaIcon },
  Eritrea: { icon: eritreaIcon },
  Estonia: { icon: estoniaIcon },
  Eswatini: { icon: eswatiniIcon },
  Ethiopia: { icon: ethiopiaIcon },
  Fiji: { icon: fijiIcon },
  Finland: { icon: finlandIcon },
  France: { icon: franceIcon },
  Gabon: { icon: gabonIcon },
  Gambia: { icon: gambiaIcon },
  Georgia: { icon: georgiaIcon },
  Germany: { icon: germanyIcon },
  Ghana: { icon: ghanaIcon },
  Greece: { icon: greeceIcon },
  Grenada: { icon: grenadaIcon },
  Guatemala: { icon: guatemalaIcon },
  Guinea: { icon: guineaIcon },
  "Guinea Bissau": { icon: guineabissauIcon },
  Guyana: { icon: guyanaIcon },
  Haiti: { icon: haitiIcon },
  Honduras: { icon: hondurasIcon },
  Hungary: { icon: hungaryIcon },
  Iceland: { icon: icelandIcon },
  India: { icon: indiaIcon },
  Indonesia: { icon: indonesiaIcon },
  Iran: { icon: iranIcon },
  Iraq: { icon: iraqIcon },
  Ireland: { icon: irelandIcon },
  Israel: { icon: israelIcon },
  Italy: { icon: italyIcon },
  Jamaica: { icon: jamaicaIcon },
  Japan: { icon: japanIcon },
  Jordan: { icon: jordanIcon },
  Kazakhstan: { icon: kazakhstanIcon },
  Kenya: { icon: kenyaIcon },
  Kiribati: { icon: kiribatiIcon },
  Kuwait: { icon: kuwaitIcon },
  Kyrgyzstan: { icon: kyrgyzstanIcon },
  Laos: { icon: laosIcon },
  Latvia: { icon: latviaIcon },
  Lebanon: { icon: lebanonIcon },
  Lesotho: { icon: lesothoIcon },
  Liberia: { icon: liberiaIcon },
  Libya: { icon: libyaIcon },
  Liechtenstein: { icon: liechtensteinIcon },
  Lithuania: { icon: lithuaniaIcon },
  Luxembourg: { icon: luxembourgIcon },
  Madagascar: { icon: madagascarIcon },
  Malawi: { icon: malawiIcon },
  Malaysia: { icon: malaysiaIcon },
  Maldives: { icon: maldivesIcon },
  Mali: { icon: maliIcon },
  Malta: { icon: maltaIcon },
  "Marshall Islands": { icon: marshallislandsIcon },
  Mauritania: { icon: mauritaniaIcon },
  Mauritius: { icon: mauritiusIcon },
  Mexico: { icon: mexicoIcon },
  Micronesia: { icon: micronesiaIcon },
  Moldova: { icon: moldovaIcon },
  Monaco: { icon: monacoIcon },
  Mongolia: { icon: mongoliaIcon },
  Montenegro: { icon: montenegroIcon },
  Morocco: { icon: moroccoIcon },
  Mozambique: { icon: mozambiqueIcon },
  Myanmar: { icon: myanmarIcon },
  Namibia: { icon: namibiaIcon },
  Nauru: { icon: nauruIcon },
  Nepal: { icon: nepalIcon },
  Netherlands: { icon: netherlandsIcon },
  "New Zealand": { icon: newzealandIcon },
  Nicaragua: { icon: nicaraguaIcon },
  Niger: { icon: nigerIcon },
  Nigeria: { icon: nigeriaIcon },
  "North Macedonia": { icon: northmacedoniaIcon },
  Norway: { icon: norwayIcon },
  Oman: { icon: omanIcon },
  Pakistan: { icon: pakistanIcon },
  "Palestine State": { icon: palestinestateIcon },
  Panama: { icon: panamaIcon },
  "Papua New Guinea": { icon: papuanewguineaIcon },
  Paraguay: { icon: paraguayIcon },
  Peru: { icon: peruIcon },
  Philippines: { icon: philippinesIcon },
  Poland: { icon: polandIcon },
  Portugal: { icon: portugalIcon },
  Qatar: { icon: qatarIcon },
  Romania: { icon: romaniaIcon },
  Russia: { icon: russiaIcon },
  Rwanda: { icon: rwandaIcon },
  "Saint Kitts and Nevis": { icon: saintkittsandnevisIcon },
  "Saint Lucia": { icon: saintluciaIcon },
  "Saint Vincent and the Grenadines": {
    icon: saintvincentandthegrenadinesIcon,
  },
  Samoa: { icon: samoaIcon },
  "San Marino": { icon: sanmarinoIcon },
  "Sao Tome and Principe": { icon: saotomeandprincipeIcon },
  "Saudi Arabia": { icon: saudiarabiaIcon },
  Senegal: { icon: senegalIcon },
  Serbia: { icon: serbiaIcon },
  Seychelles: { icon: seychellesIcon },
  "Sierra Leone": { icon: sierraleoneIcon },
  Singapore: { icon: singaporeIcon },
  Slovakia: { icon: slovakiaIcon },
  Slovenia: { icon: sloveniaIcon },
  "Solomon Islands": { icon: solomonislandsIcon },
  Somalia: { icon: somaliaIcon },
  "South Africa": { icon: southafricaIcon },
  "South Korea": { icon: southkoreaIcon },
  "South Sudan": { icon: southsudanIcon },
  Spain: { icon: spainIcon },
  "Sri Lanka": { icon: srilankaIcon },
  Sudan: { icon: sudanIcon },
  Suriname: { icon: surinameIcon },
  Sweden: { icon: swedenIcon },
  Switzerland: { icon: switzerlandIcon },
  Syria: { icon: syriaIcon },
  Tajikistan: { icon: tajikistanIcon },
  Tanzania: { icon: tanzaniaIcon },
  Taiwan: { icon: taiwanIcon },
  Thailand: { icon: thailandIcon },
  "Timor Leste": { icon: timorlesteIcon },
  Togo: { icon: togoIcon },
  Tonga: { icon: tongaIcon },
  "Trinidad and Tobago": { icon: trinidadandtobagoIcon },
  Tunisia: { icon: tunisiaIcon },
  Turkey: { icon: turkeyIcon },
  Turkmenistan: { icon: turkmenistanIcon },
  Tuvalu: { icon: tuvaluIcon },
  Uganda: { icon: ugandaIcon },
  Ukraine: { icon: ukraineIcon },
  "United Arab Emirates": { icon: unitedarabemiratesIcon },
  "United Kingdom": { icon: unitedkingdomIcon },
  USA: { icon: unitedstatesofamericaIcon },
  Uruguay: { icon: uruguayIcon },
  Uzbekistan: { icon: uzbekistanIcon },
  Vanuatu: { icon: vanuatuIcon },
  Venezuela: { icon: venezuelaIcon },
  Vietnam: { icon: vietnamIcon },
  Yemen: { icon: yemenIcon },
  Zambia: { icon: zambiaIcon },
  Zimbabwe: { icon: zimbabweIcon },
};
