import { useEffect, useState, useRef } from "react";
import { gql, useQuery, useMutation, useSubscription } from "@apollo/client";
import { useSearchParams, useNavigate } from "react-router-dom";

import { AUTH_ID } from "constants";
import Chat from "./Chat";

export default () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const postId = searchParams.get("postid") ? searchParams.get("postid") : null;
  const postShardNumber = searchParams.get("sn")
    ? searchParams.get("sn")
    : null;
  const lastChatCreatedFrom = localStorage.getItem("ch_created_from");

  // if (lastChatCreatedFrom === postId) {
  //   // prevent from returning to already created Chat creating
  //   navigate("/messages");
  // }

  console.log({ postId, postShardNumber });

  useEffect(() => {
    if (!postId || !postShardNumber) {
      // missing Post ID or Post shard number
      navigate("/discover");
    }
  }, []);

  // Fetch post data
  const GET_POST_PREVIEW_DATA = gql`
    query GetPostPreviewData($postId: ID, $shardNumber: Int!) {
      postPreviewData(
        postId: $postId
        postSnapshotId: null
        shardNumber: $shardNumber
      ) {
        id
        text
        image
        image_link
        user {
          id
          username
          profile_image
        }
      }
    }
  `;

  const { loading, data, error } = useQuery(GET_POST_PREVIEW_DATA, {
    variables: {
      postId,
      shardNumber: Number(postShardNumber),
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("error fetching post preview data", error);
    //captureException(error);
    navigate("/discover");
  }

  console.log("data", data);

  let chatData;

  if (data) {
    chatData = {
      chat: {
        profileImage: data.postPreviewData.user.profile_image,
        username: data.postPreviewData.user.username,
        messages: [
          {
            id: "0",
            created_at: new Date(),
            chat_id: "0",
            message: data.postPreviewData.text,
            read: false,
            post_id: postId,
            image: data.postPreviewData.image,
          },
        ],
      },
    };
  }

  console.log("chatData", chatData);

  return (
    <Chat
      data={chatData}
      partnerShardNumber={postShardNumber}
      postId={postId}
    />
  );
};
