import styled from "styled-components";

import * as css from "styles/CssVariables.js";

const SelectionItem = styled.div`
  width: 100%;
  height: 42px;
  padding: 0px 20px;
  box-sizing: border-box;

  background: ${(props) => (props.selected ? "#2477FF" : "transparent")};

  font-size: 15px;
  font-weight: 300;
  color: #ffffff;

  transition: 0.2s;

  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: ${css.BRAND_YELLOW};
    color: ${css.DARK_BLUE};
  }
`;

export default (props) => {
  const { id, selected, value, onClick, children, size } = props;

  return (
    <SelectionItem
      selected={selected ? 1 : 0}
      onClick={() => onClick(value)}
      id={id}
      size={size}
    >
      {children}
    </SelectionItem>
  );
};
