import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Tooltip } from "react-tooltip";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import { dateFormatted, sumImpsCpi } from "modules/functions";
import { AWS_USER_UPLOAD_URL } from "constants";
import { COUNTRY_ICONS } from "constants/countries";
import ToggleSwitch from "components/forms/ToggleSwitch";
import ButtonRoundIcon from "components/buttons/ButtonRoundIcon";

import eyeIcon from "assets/img/icons/eye-icon.svg";
import fingerIcon from "assets/img/icons/finger-icon.svg";
import percentIcon from "assets/img/icons/percent-icon.svg";
import walletIcon from "assets/img/icons/menu-wallet.svg";
import coinsIcon from "assets/img/icons/coins-icon.svg";
import eyeGoldIcon from "assets/img/icons/eye-icon-gold.svg";
import fingerGoldIcon from "assets/img/icons/finger-icon-gold.svg";
import pencilIcon from "assets/img/icons/pencil-icon.svg";
import pencilWhiteIcon from "assets/img/icons/pencil-icon-white.svg";
import copyIcon from "assets/img/icons/copy-icon.svg";
import copyWhiteIcon from "assets/img/icons/copy-icon-white.svg";
import binIcon from "assets/img/icons/bin-icon.svg";
import binWhiteIcon from "assets/img/icons/bin-icon-white.svg";

const PostBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;
  max-width: ${(props) => (props.dashlist ? "450px" : "750px")};

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  transition: 0.2s;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  &:hover {
    cursor: ${(props) => (props.dashlist ? "pointer" : "default")};
    background: ${(props) =>
      props.dashlist ? css.BLUE_BOX_GRADIENT_HOVER : css.BLUE_BOX_GRADIENT};
  }
`;

const BoxHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const PostName = styled.div`
  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};
  overflow-wrap: anywhere;
`;

const HeaderControls = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StatusControl = styled.div`
  display: flex;
  align-items: center;
`;

const StatusBadge = styled.div`
  height: 20px;
  padding: 0 14px 1px;
  margin-left: 6px;

  font-size: 13px;
  color: ${(props) => (props.status === "Paused" ? css.DARK_BLUE : "#fff")};
  white-space: nowrap;

  border-radius: 10px;
  background: ${(props) => {
    switch (props.status) {
      case "In Review":
        return css.BRAND_INDIGO;
      case "Active":
        return css.BRAND_GREEN;
      case "Paused":
        return css.BRAND_BLUSH;
      case "Stopped":
        return css.NEGATIVE_RED;
      default:
        return css.TEXT_GREY;
    }
  }};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ControlButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PostPreview = styled.div`
  width: 100%;
  margin-top: 4px;
  max-height: 160px;

  display: flex;
  gap: 14px;
`;

const Image = styled.div`
  aspect-ratio: 1.3;

  border-radius: ${css.BOX_RADIUS_SECONDARY};

  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  flex: ${(props) => {
    if (props.mobile || props.dashlist) return "0.45";
    else return "0.35";
  }};
`;

const RightPreviewColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  flex: ${(props) => {
    if (!props.hasimage) return "1";

    if (props.mobile || props.dashlist) return "0.55";
    else return "0.65";
  }};
`;

const PostDate = styled.div`
  font-size: 13px;
  color: ${css.TEXT_GREY};
`;

const Text = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.dashlist ? 4 : 6)};
  line-clamp: ${(props) => (props.dashlist ? 4 : 6)};
  -webkit-box-orient: vertical;

  font-family: "Roboto";
  font-size: 15px;
  line-height: 135%;
  white-space: pre-line;
  overflow-wrap: anywhere;

  > a {
    color: ${css.LIGHT_BLUE};
    text-decoration: none;
  }

  @media (max-width: ${globalConstants.switchMobile}) {
    -webkit-line-clamp: ${(props) => (props.dashlist ? 4 : 5)};
    line-clamp: ${(props) => (props.dashlist ? 4 : 5)};
  }
`;

const PostBoxes = styled.div`
  display: flex;
  gap: ${css.VERTICAL_BOX_MARGIN};
  align-self: flex-start;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const StatsBox = styled.div`
  padding: ${css.BOX_PADDING_TERTIARY};
  max-width: 260px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  flex: 0.5;

  display: grid;
  grid-template-columns: 32px 73px 73px 1fr;
  grid-template-rows: 25px 1fr 1fr 1fr;
`;

const StatsLabel = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

const LabelIconCell = styled.div`
  display: flex;
  align-items: center;
`;

const LabelIcon = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
`;

const ValueCell = styled.div`
  height: 24px;

  font-size: 13px;

  display: flex;
  align-items: center;
`;

const ChangeBadge = styled.div`
  height: 16px;
  padding: 0 4px 1px;
  margin-left: 5px;

  font-size: 11px;

  border-radius: 8px;
  background: ${(props) =>
    props.green ? css.POSITIVE_GREEN : css.NEGATIVE_RED};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const SmallBoxes = styled.div`
  flex: 0.5;

  display: flex;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const WalletBox = styled.div`
  padding: ${css.BOX_PADDING_TERTIARY};
  max-width: 150px;
  min-width: 150px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  flex: 0.5;

  display: grid;
  grid-template-columns: 27px 1fr;
  grid-template-rows: 25px 1fr 1fr 1fr;
`;

const BoxTitleCell = styled.div`
  display: flex;
`;

const BoxTitleIcon = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
`;

const BoxTitleInfo = styled.div`
  font-size: 13px;
`;

const CountriesBox = styled.div`
  padding: ${css.BOX_PADDING_TERTIARY};
  max-width: 150px;
  min-width: 150px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  flex: 0.5;

  display: grid;
  grid-template-columns: 28px 22px 1fr;
  grid-template-rows: 25px 1fr 1fr 1fr;
`;

const CountryIcon = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;
`;

const CountryName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ControlsComponent = ({
  postId,
  onEditClick,
  onDeleteClick,
  loadingDelete,
  onDuplicateClick,
}) => {
  return (
    <ControlButtons>
      <ButtonRoundIcon
        icon={pencilIcon}
        hoverIcon={pencilWhiteIcon}
        background={css.BRAND_YELLOW}
        hoverBackground={css.BRAND_GREEN}
        onClick={() => onEditClick(postId)}
        buttonSize="34px"
        iconSize="15px"
      />
      <ButtonRoundIcon
        icon={copyIcon}
        hoverIcon={copyWhiteIcon}
        background={css.BRAND_YELLOW}
        hoverBackground={css.BRAND_GREEN}
        onClick={() => onDuplicateClick(postId)}
        buttonSize="34px"
        iconSize="15px"
      />
      <ButtonRoundIcon
        icon={binIcon}
        hoverIcon={binWhiteIcon}
        background={css.BRAND_YELLOW}
        hoverBackground={css.NEGATIVE_RED}
        onClick={() => onDeleteClick(postId)}
        buttonSize="34px"
        iconSize="15px"
        disabled={loadingDelete}
      />
    </ControlButtons>
  );
};

const LabelIconWithTip = ({ icon, tip }) => {
  return (
    <LabelIcon>
      <img
        src={icon}
        style={{ width: "100%" }}
        data-tooltip-id={`${icon}-tip`}
        data-tooltip-content={tip}
        data-tooltip-place="top"
        data-tooltip-delay-show={500}
      />
      <Tooltip id={`${icon}-tip`} className="tooltip" />
    </LabelIcon>
  );
};

export default ({
  post,
  onSwitchClick,
  switchLoading,
  onEditClick,
  dashList,
  onDeleteClick,
  loadingDelete,
  onDuplicateClick,
}) => {
  console.log("POST", post);
  const mobileScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchMobile})`,
  });

  const {
    id,
    created_at,
    name,
    status,
    text,
    image,
    switched_on,
    impressions,
    posts_stats,
  } = post;

  const statsTo = posts_stats[0] ? posts_stats[0].to : null;
  const statsTotalImpressions = posts_stats[0]
    ? posts_stats[0].total_impressions
    : null;
  const statsTotalInteractions = posts_stats[0]
    ? posts_stats[0].total_interactions
    : null;
  const statsTotalSpent = posts_stats[0] ? posts_stats[0].total_spent : null;

  const displayName = name
    ? name
    : text
        .substring(0, 30)
        .replace(
          /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
          ""
        );

  let statusText;

  if (status === "inReview") {
    statusText = "In Review";
  } else if (status === "active") {
    statusText = "Active";
  } else if (status === "paused") {
    statusText = "Paused";
  } else if (status === "stopped") {
    statusText = "Stopped";
  }

  console.log("Post", id, impressions);

  // Calculate Impressions, Interactions, CTR, CPI and CPC stats
  const dayLimit = new Date();
  dayLimit.setDate(dayLimit.getDate() - 1);
  //console.log("dayLimit.toISOString()", dayLimit.toISOString());

  const twoDaysLimit = new Date();
  twoDaysLimit.setDate(twoDaysLimit.getDate() - 2);
  //console.log("twoDaysLimit.toISOString()", twoDaysLimit.toISOString());

  const weekLimit = new Date();
  weekLimit.setDate(weekLimit.getDate() - 7);
  //console.log("weekLimit.toISOString()", weekLimit.toISOString());

  const last24hoursImps = impressions.filter(
    (imp) => imp.registered_at >= dayLimit.toISOString()
  );
  const last24hoursInts = last24hoursImps.filter((imp) => imp.interacted);
  const last24hoursSpent = sumImpsCpi(last24hoursImps);

  // console.log(
  //   "last24hoursImps",
  //   last24hoursImps.length,
  //   last24hoursInts.length,
  //   last24hoursSpent
  // );

  const previous24hoursImps = impressions.filter(
    (imp) =>
      imp.registered_at < dayLimit.toISOString() &&
      imp.registered_at >= twoDaysLimit.toISOString()
  );
  const previous24hoursInts = previous24hoursImps.filter(
    (imp) => imp.interacted
  );
  const previous24hoursSpent = sumImpsCpi(previous24hoursImps);

  // console.log(
  //   "previous24hoursImps",
  //   previous24hoursImps.length,
  //   previous24hoursInts.length,
  //   previous24hoursSpent
  // );

  const last24hoursImpsChange =
    previous24hoursImps.length > 0
      ? (100 / previous24hoursImps.length) * last24hoursImps.length - 100
      : "-";

  const last24hoursIntsChange =
    previous24hoursInts.length > 0
      ? (100 / previous24hoursInts.length) * last24hoursInts.length - 100
      : "-";

  const last24hoursCTR =
    last24hoursImps.length > 0
      ? Math.round(
          (100 / last24hoursImps.length) * last24hoursInts.length * 10
        ) / 10
      : 0;

  const lastWeekImps = impressions.filter(
    (imp) => imp.registered_at >= weekLimit.toISOString()
  );
  const lastWeekInts = lastWeekImps.filter((imp) => imp.interacted);
  const lastWeekSpent = sumImpsCpi(lastWeekImps);

  // console.log(
  //   "lastWeekImps",
  //   lastWeekImps.length,
  //   lastWeekInts.length,
  //   lastWeekSpent
  // );

  const previousWeekImps = impressions.filter(
    (imp) => imp.registered_at < weekLimit.toISOString()
  );
  const previousWeekInts = previousWeekImps.filter((imp) => imp.interacted);
  const previousWeekSpent = sumImpsCpi(previousWeekImps);

  // console.log(
  //   "previousWeekImps",
  //   previousWeekImps.length,
  //   previousWeekInts.length,
  //   previousWeekSpent
  // );

  const lastWeekImpsChange =
    previousWeekImps.length > 0
      ? (100 / previousWeekImps.length) * lastWeekImps.length - 100
      : "-";

  const lastWeekIntsChange =
    previousWeekInts.length > 0
      ? (100 / previousWeekInts.length) * lastWeekInts.length - 100
      : "-";

  const lastWeekhoursCTR =
    lastWeekImps.length > 0
      ? Math.round((100 / lastWeekImps.length) * lastWeekInts.length * 10) / 10
      : 0;

  const impsFromLastTotalUpdate = statsTo
    ? impressions.filter((imp) => imp.registered_at > statsTo)
    : impressions;

  const totalImpressions =
    statsTotalImpressions + impsFromLastTotalUpdate.length;

  const intsFromLastTotalUpdate = impsFromLastTotalUpdate.filter(
    (imp) => imp.interacted
  );

  const totalInteractions =
    statsTotalInteractions + intsFromLastTotalUpdate.length;

  const totalCTR =
    totalImpressions > 0
      ? Math.round((100 / totalImpressions) * totalInteractions * 10) / 10
      : 0;

  const totalSpent = statsTotalSpent + sumImpsCpi(impsFromLastTotalUpdate);
  const totalCPI =
    totalImpressions !== 0
      ? Math.round((totalSpent / totalImpressions) * 10) / 10
      : 0;
  const totalCPC =
    totalInteractions !== 0
      ? Math.round((totalSpent / totalInteractions) * 10) / 10
      : 0;

  // Calculate Countries stats
  function groupAndSortByCountry(array, key) {
    const grouped = array.reduce((result, currentItem) => {
      const groupKey = currentItem[key];

      if (!result[groupKey]) {
        result[groupKey] = [];
      }

      result[groupKey].push(currentItem);

      return result;
    }, {});

    const groupedArray = Object.entries(grouped).map(([groupKey, items]) => ({
      key: groupKey,
      items: items,
    }));

    groupedArray.sort((a, b) => b.items.length - a.items.length);

    return groupedArray.slice(0, 3);
  }

  let groupedImpressions = groupAndSortByCountry(lastWeekImps, "country");

  // default values for new Posts
  if (groupedImpressions.length === 0) {
    groupedImpressions = [
      {
        key: "USA",
        items: [],
      },
      {
        key: "United Kingdom",
        items: [],
      },
      {
        key: "Australia",
        items: [],
      },
    ];
  }

  const countryList = [];

  if (!dashList) {
    for (const country of groupedImpressions) {
      const percentage =
        lastWeekImps.length > 0
          ? (100 / lastWeekImps.length) * country.items.length
          : 0;

      countryList.push(
        <>
          <ValueCell>{Math.round(percentage, 0)}%</ValueCell>
          <ValueCell>
            <CountryIcon>
              <img
                src={COUNTRY_ICONS[country.key].icon}
                style={{ width: "100%" }}
              />
            </CountryIcon>
          </ValueCell>
          <ValueCell style={{ overflow: "hidden" }}>
            <CountryName>{country.key}</CountryName>
          </ValueCell>
        </>
      );
    }
  }

  const reduceValue = (value) => {
    if (value < 10000) return value;
    return Math.round(value / 1000, 0) + "k";
  };

  const changeBadge = (change) => {
    if (typeof change !== "number") return <></>;

    if (change > 100) return <ChangeBadge green={1}>&gt;100%</ChangeBadge>;

    const roundCoef = change >= 10 || change <= -10 ? 1 : 10;
    let changeRounded = Math.round(change * roundCoef) / roundCoef;

    if (changeRounded >= 0)
      return <ChangeBadge green={1}>+{changeRounded}%</ChangeBadge>;
    else return <ChangeBadge green={0}>{changeRounded}%</ChangeBadge>;
  };

  return (
    <PostBox key={id} dashlist={dashList}>
      <BoxHeader>
        <PostName>{displayName}</PostName>
        <HeaderControls>
          <StatusControl>
            {!dashList && (
              <ToggleSwitch
                on={switched_on}
                onClick={() => onSwitchClick(id)}
                loading={switchLoading.includes(id)}
              />
            )}
            <StatusBadge status={statusText}>{statusText}</StatusBadge>
          </StatusControl>
          {!mobileScreen && !dashList && (
            <ControlsComponent
              postId={id}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              loadingDelete={loadingDelete}
              onDuplicateClick={onDuplicateClick}
            />
          )}
        </HeaderControls>
      </BoxHeader>
      <PostPreview>
        {image && (
          <Image
            image={AWS_USER_UPLOAD_URL + image}
            mobile={mobileScreen ? 1 : 0}
            dashlist={dashList}
          />
        )}
        <RightPreviewColumn
          hasimage={image ? 1 : 0}
          mobile={mobileScreen ? 1 : 0}
          dashlist={dashList}
        >
          <PostDate>{dateFormatted(new Date(created_at))}</PostDate>
          <Text dashlist={dashList}>{text}</Text>
        </RightPreviewColumn>
      </PostPreview>
      <PostBoxes>
        <StatsBox>
          <StatsLabel></StatsLabel>
          <StatsLabel>24 Hours</StatsLabel>
          <StatsLabel>7 Days</StatsLabel>
          <StatsLabel>Total</StatsLabel>
          <LabelIconCell>
            <LabelIconWithTip icon={eyeIcon} tip="Impressions" />
          </LabelIconCell>
          <ValueCell>
            {last24hoursImps.length}
            {changeBadge(last24hoursImpsChange)}
          </ValueCell>
          <ValueCell>
            {lastWeekImps.length}
            {changeBadge(lastWeekImpsChange)}
          </ValueCell>
          <ValueCell>{reduceValue(totalImpressions)}</ValueCell>
          <LabelIconCell>
            <LabelIconWithTip icon={fingerIcon} tip="Interactions" />
          </LabelIconCell>
          <ValueCell>
            {last24hoursInts.length}
            {changeBadge(last24hoursIntsChange)}
          </ValueCell>
          <ValueCell>
            {lastWeekInts.length}
            {changeBadge(lastWeekIntsChange)}
          </ValueCell>
          <ValueCell>{reduceValue(totalInteractions)}</ValueCell>
          <LabelIconCell>
            <LabelIconCell>
              <LabelIconWithTip
                icon={percentIcon}
                tip="Click-through rate (CTR) is the ratio of clicks (interactions) a post receives to the number of impressions."
              />
            </LabelIconCell>
          </LabelIconCell>
          <ValueCell>{last24hoursCTR}%</ValueCell>
          <ValueCell>{lastWeekhoursCTR}%</ValueCell>
          <ValueCell>{totalCTR}%</ValueCell>
        </StatsBox>
        {!dashList && (
          <SmallBoxes>
            <WalletBox>
              <BoxTitleCell>
                <BoxTitleIcon>
                  <img src={walletIcon} style={{ width: "100%" }} />
                </BoxTitleIcon>
              </BoxTitleCell>
              <BoxTitleCell></BoxTitleCell>
              <LabelIconCell>
                <LabelIconWithTip icon={coinsIcon} tip="Total coins spent" />
              </LabelIconCell>
              <ValueCell>{reduceValue(totalSpent)} Spent</ValueCell>
              <LabelIconCell>
                <LabelIconWithTip
                  icon={eyeGoldIcon}
                  tip="Average cost per impression"
                />
              </LabelIconCell>
              <ValueCell>{totalCPI} Avg. CPI</ValueCell>
              <LabelIconCell>
                <LabelIconWithTip
                  icon={fingerGoldIcon}
                  tip="Average cost per click (interaction)"
                />
              </LabelIconCell>
              <ValueCell>{totalCPC} Avg. CPC</ValueCell>
            </WalletBox>
            <CountriesBox>
              <BoxTitleCell>
                <BoxTitleIcon>
                  <img src={eyeIcon} style={{ width: "100%" }} />
                </BoxTitleIcon>
              </BoxTitleCell>
              <BoxTitleCell></BoxTitleCell>
              <BoxTitleCell style={{ justifySelf: "flex-end" }}>
                <BoxTitleInfo>7 days</BoxTitleInfo>
              </BoxTitleCell>
              {countryList}
            </CountriesBox>
          </SmallBoxes>
        )}
      </PostBoxes>
      {mobileScreen && !dashList && (
        <ControlsComponent
          postId={id}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          loadingDelete={loadingDelete}
          onDuplicateClick={onDuplicateClick}
        />
      )}
    </PostBox>
  );
};
