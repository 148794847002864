import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

const PageTitle = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};
  margin-bottom: ${(props) =>
    props.marginbottom ? props.marginbottom : "16px"};

  font-size: 36px;
  font-weight: 500;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 28px;
  }
`;

export default ({ children, marginBottom }) => {
  return <PageTitle marginbottom={marginBottom}>{children}</PageTitle>;
};
