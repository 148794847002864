import React from "react";
import styled from "styled-components";

import * as css from "styles/CssVariables.js";

const Icon = styled.div`
  width: 14px;
  min-width: 14px;
  height: 14px;

  transition: 0.2s;

  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Button = styled.div`
  box-sizing: border-box;
  height: 28px;
  padding: 0 15px;

  background: ${css.BRAND_YELLOW};
  border-radius: 14px;
  border: 0;

  font-size: 13px;
  font-weight: 500;
  color: ${css.DARK_BLUE};
  white-space: nowrap;
  line-height: 1;

  transition: 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  ${(props) =>
    !props.disabled &&
    `&:hover {
    cursor: pointer;

    color: #fff;

    background: ${css.BRAND_GREEN};
  }`}

  &:hover ${Icon} {
    background-image: url(${(props) => props.iconhover});
  }
`;

export default (props) => {
  const { onClick, parameters, id, children, disabled, icon, iconHover } =
    props;
  return (
    <Button
      onClick={() => {
        if (disabled) return;
        if (onClick) onClick(parameters);
        return;
      }}
      id={id}
      disabled={disabled}
      iconhover={iconHover}
    >
      <Icon icon={icon} />
      {children}
    </Button>
  );
};
