import styled from "styled-components";

import * as css from "styles/CssVariables.js";

const LoadingBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
`;

export default ({ children }) => {
  return <LoadingBox>{children}</LoadingBox>;
};
