import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import PostContent from "./PostContent";

const SidePostPreview = styled.div`
  width: 360px;
  box-sizing: border-box;

  min-height: 200px;

  margin: 0;
  padding: ${css.BOX_PADDING_PRIMARY};

  border-radius: ${css.BOX_RADIUS_PRIMARY};
  background: ${css.BLUE_BOX_GRADIENT};

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    width: 100%;
    margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};
  }
`;

const Title = styled.div`
  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  margin-bottom: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

export default ({ avatar, username, text, image, imageLink }) => {
  return (
    <SidePostPreview>
      <Title>Post Preview</Title>
      <PostContent
        isPreview={true}
        avatar={avatar}
        username={username}
        text={text}
        image={image}
        imageLink={imageLink}
      />
    </SidePostPreview>
  );
};
