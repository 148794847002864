import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
  HttpLink,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

import { AUTH_TOKEN, AUTH_WS_TOKEN, GRAPHQL_SERVER_URL } from "./constants";

const token = localStorage.getItem(AUTH_TOKEN);
//console.log({ token });

const authorizationHeader = token ? `Bearer ${token}` : null;

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      authorization: authorizationHeader,
      ...headers,
    },
  }));
  return forward(operation);
});

const httpLink = new HttpLink({
  uri: GRAPHQL_SERVER_URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: "wss://dear-panther-41.hasura.app/v1/graphql",
    connectionParams: async () => {
      const wsToken = localStorage.getItem(AUTH_WS_TOKEN);

      return wsToken
        ? {
            headers: {
              Authorization: `Bearer ${wsToken}`,
            },
          }
        : {};
    },
  })
);

const linkChain = from([authLink, httpLink]);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  linkChain
);

export default new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink,
});
