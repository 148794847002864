import { gql, useQuery } from "@apollo/client";

import { AUTH_ID } from "constants";
import DashboardContest from "components/dashboard/DashboardContest";

export default () => {
  // Fetch data
  const GET_CONTEST_DATA = gql`
    query GetContestData {
      contestData {
        ongoingPrizes
        ongoingEnds
        ongoingPoints
        rankingGroup
      }
    }
  `;

  const { loading, data, error } = useQuery(GET_CONTEST_DATA, {
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching contest data error", error);
    //captureException(error);
  }

  console.log({ data });

  return <DashboardContest data={data} />;
};
