import { useEffect, useState, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import { useSearchParams, useNavigate } from "react-router-dom";

import { AUTH_ID } from "constants";
import Messages from "./Messages";

export default () => {
  // Fetch data
  const GET_CHATS_DATA = gql`
    query GetChatsData {
      chats {
        id
        lastMessageCreatedAt
        lastMessage
        lastMessageRead
        lastSenderMe
        username
        profileImage
      }
    }
  `;

  const { loading, data, error } = useQuery(GET_CHATS_DATA, {
    fetchPolicy: "network-only",
    pollInterval: 60000,
  });

  if (error) {
    console.log("error fetching chats", error);
    //captureException(error);
  }

  console.log("data", data);

  return <Messages data={data} />;
};
