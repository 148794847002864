import {
  OPEN_MESSAGE_MODAL,
  CLOSE_MESSAGE_MODAL,
  OPEN_HIDING_MENU,
  CLOSE_HIDING_MENU,
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_HIDING_MENU_PUBLIC,
  CLOSE_HIDING_MENU_PUBLIC,
} from "./actionTypes";

export const openMessageModal = (data) => ({
  type: OPEN_MESSAGE_MODAL,
  payload: data,
});

export const closeMessageModal = (data) => ({
  type: CLOSE_MESSAGE_MODAL,
  payload: data,
});

export const openHidingMenu = () => ({
  type: OPEN_HIDING_MENU,
});

export const closeHidingMenu = () => ({
  type: CLOSE_HIDING_MENU,
});

export const openHidingMenuPublic = () => ({
  type: OPEN_HIDING_MENU_PUBLIC,
});

export const closeHidingMenuPublic = () => ({
  type: CLOSE_HIDING_MENU_PUBLIC,
});

export const openModal = (data) => ({
  type: OPEN_MODAL,
  payload: data,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});
