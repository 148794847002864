import styled from "styled-components";
import { Tooltip } from "react-tooltip";

import tipIcon from "assets/img/icons/icons8-info.svg";

const TipIcon = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;

  &:hover {
    cursor: help;
  }
`;

export default ({ tip }) => {
  return (
    <>
      <TipIcon
        data-tooltip-id="linkTip"
        data-tooltip-content={tip}
        data-tooltip-place="top"
        data-tooltip-delay-show={500}
      >
        <img src={tipIcon} style={{ width: "100%", paddingTop: "1px" }} />
      </TipIcon>
      <Tooltip id="linkTip" className="tooltip" />
    </>
  );
};
