import styled from "styled-components";
import { useState, useEffect } from "react";

import { timeSince, sortArrayByProperty } from "modules/functions";
import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";

import eyeIcon from "assets/img/icons/eye-icon.svg";
import fingerIcon from "assets/img/icons/finger-icon.svg";
import coinsWhiteIcon from "assets/img/icons/coins-white-icon.svg";
import emptyIcon from "assets/img/icons/empty-icon.svg";
import referralsQualifiedIcon from "assets/img/icons/referral-qualified-icon.svg";

const SideEvents = styled.div`
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
`;

const EventList = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const EventBox = styled.div`
  padding: 5px 16px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: ${css.VERTICAL_BOX_MARGIN};

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  align-items: center;
  gap: 13px;
`;

const LeftPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EventIcon = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;
`;

const RightPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;

  flex: 1;
`;

const SmallContentBox = styled.div`
  height: 15px;

  font-size: 11px;
  color: ${css.TEXT_GREY};

  display: flex;
  justify-content: flex-end;
`;

const Content = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  font-size: 13px;
`;

const Empty = styled.div`
  padding: 85px 0 135px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const EmptyIcon = styled.div`
  width: 26px;
  min-width: 26px;
  height: 26px;
`;

const EmptyText = styled.div`
  text-align: center;
`;

export default ({ data, subscribeToNewEvents }) => {
  console.log("data", data);

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    subscribeToNewEvents();
  }, []);

  let events = [];

  if (data) {
    for (const impression of data.events.impressions) {
      events.push({
        type: "impression",
        text: "A user viewed " + impression.post.name,
        displayTime: timeSince(new Date(impression.registered_at)),
        date: impression.registered_at,
      });
    }

    for (const interaction of data.events.interactions) {
      events.push({
        type: "interaction",
        text: "A user interacted with " + interaction.post.name,
        displayTime: timeSince(new Date(interaction.registered_at)),
        date: interaction.registered_at,
      });
    }

    for (const payout of data.events.rewardsPayouts) {
      events.push({
        type: "rewardsPayout",
        text: "You found " + payout.amount + " coins",
        displayTime: timeSince(new Date(payout.created_at)),
        date: payout.created_at,
      });
    }

    for (const referralReward of data.events.referralRewards) {
      events.push({
        type: "referralReward",
        text:
          referralReward.data.referral_username + " is now qualified referral",
        displayTime: timeSince(new Date(referralReward.created_at)),
        date: referralReward.created_at,
      });
    }
  }

  console.log("events", events);

  const eventsSorted = sortArrayByProperty(events, "date", true).slice(0, 5);
  console.log("eventsSorted", eventsSorted);

  const list = eventsSorted.map((event) => {
    const { type, text, displayTime, date } = event;

    let icon;

    switch (type) {
      case "impression":
        icon = eyeIcon;
        break;
      case "interaction":
        icon = fingerIcon;
        break;
      case "rewardsPayout":
        icon = coinsWhiteIcon;
        break;
      case "referralReward":
        icon = referralsQualifiedIcon;
        break;
    }

    return (
      <EventBox key={date + "-" + type}>
        <LeftPart>
          <EventIcon>
            <img src={icon} style={{ width: "100%" }} />
          </EventIcon>
        </LeftPart>
        <RightPart>
          <SmallContentBox></SmallContentBox>
          <Content>{text}</Content>
          <SmallContentBox>{displayTime}</SmallContentBox>
        </RightPart>
      </EventBox>
    );
  });

  //force component to update every 1 second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <SideEvents>
      {!data && <BoxLoadingSpinner height="200px" padding="0 0 38px 0" />}
      {data && <EventList>{list}</EventList>}
      {data && eventsSorted.length === 0 && (
        <Empty>
          <EmptyIcon>
            <img src={emptyIcon} style={{ width: "100%" }} />
          </EmptyIcon>
          <EmptyText>No events yet</EmptyText>
        </Empty>
      )}
    </SideEvents>
  );
};
