import styled from "styled-components";
import { useEffect, useRef } from "react";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";
import Dropdown from "components/forms/Dropdown";
import RollingBox from "components/other/RollingBox";
import DropdownSelection from "components/forms/DropdownSelection";
import SelectionItem from "components/forms/SelectionItem";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import InputWithLabel from "components/forms/InputWithLabel";
import InputText from "components/forms/InputText";
import Tip from "components/other/Tip";

import bankIcon from "assets/img/icons/icons8-bank.png";
import bitcoinIcon from "assets/img/icons/icons8-bitcoin.svg";
import skrillIcon from "assets/img/icons/icons8-skrill.svg";
import bulbIcon from "assets/img/icons/tip-bulb.svg";

const PayoutSettings = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;
  /* min-height: 360px; */

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_LARGE};
`;

const TitleText = styled.div`
  margin-top: 6px;
`;

const PayoutSettingsForms = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_XLARGE};
`;

const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const SettinsgItemTitle = styled.div`
  width: 100%;
  padding-left: 3px;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  align-items: center;
  gap: 6px;
`;

const DropdownWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 250px;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const SettingsItemLine = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${css.VERTICAL_BOX_MARGIN};

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SettingsItemValue = styled.div`
  padding: 4px 0 4px 3px;
`;

const SettingsItemButtonWrap = styled.div``;

const MethodHidingBox = styled.div`
  margin-top: 10px;
  padding-bottom: 10px;
  /* min-height: 165px; */

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const MethodSelectionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const MethodSelectionIcon = styled.div`
  width: 26px;
  min-width: 26px;
  height: 26px;
`;

const MethodSelectionText = styled.div``;

const ButtonWrap = styled.div`
  display: flex;
`;

const PaymentMethodDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const MethodDetailsHidingBox = styled.div`
  margin-top: 10px;
  padding-bottom: 10px;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const MethodDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (max-width: ${globalConstants.switchMobile}) {
    max-width: 100%;
  }
`;

const MethodDetailsItem = styled.div`
  display: flex;

  @media (max-width: 750px) {
    max-width: 100%;
    flex-direction: column;
  }
`;

const MethodDetailsLabel = styled.div`
  padding: 4px 0 4px 3px;

  white-space: nowrap;
`;

const MethodDetailsValue = styled.div`
  width: 100%;
  max-width: 260px;
  padding: 4px 0 4px 3px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 750px) {
    padding: 0px 0 4px 3px;
  }

  @media (max-width: ${globalConstants.switchMobile}) {
    max-width: 100%;
  }
`;

const TipBox = styled.div`
  /* max-width: 315px; */
  padding: ${css.BOX_PADDING_SECONDARY};
  width: 100%;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  flex: 0.5;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_LARGE};
`;

const TipWrap = styled.div`
  display: flex;
`;

const TipIcon = styled.div`
  width: 22px;
  min-width: 22px;
  height: 22px;
`;

const TipText = styled.div`
  padding-left: 12px;

  flex: 1;
`;

export default ({
  data,
  changePaymentMethodForm,
  changePaymentMethodFormToggle,
  methodSelectionOpened,
  toggleMethodSelection,
  changeMethod,
  onMethodSelect,
  methodSelected,
  warnings,
  loadingChangeMethod,
  methodDetails,
  handleDetailsInputChange,
  methodDetailsFormToggle,
  methodDetailsForm,
  updateMethodDetails,
  loadingUpdateMethodDetails,
}) => {
  const { payment_method, payment_method_details } = data.payoutSettingsData;
  const {
    bank_account_number,
    bank_routing_number,
    bank_iban,
    bank_swift,
    bank_account_name,
    bank_street,
    bank_city,
    bank_zip,
    bank_country,
    skrill_email,
    bitcoin_address,
  } = payment_method_details || {};

  const methodDropdownRef = useRef();
  const methodSelectionRef = useRef();
  const formButtonRef = useRef();

  function useSelectionOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        console.log("handleClickOutside");
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !methodDropdownRef.current.contains(event.target) &&
          !formButtonRef.current.contains(event.target)
        ) {
          toggleMethodSelection();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useSelectionOutsideClick(methodSelectionRef);

  const methodOptions = {
    bank: {
      name: "Bank Transfer",
      icon: bankIcon,
    },
    skrill: {
      name: "Skrill",
      icon: skrillIcon,
    },
    bitcoin: {
      name: "Bitcoin",
      icon: bitcoinIcon,
    },
  };

  let methodOptionsList = [];

  Object.keys(methodOptions).forEach((method) => {
    methodOptionsList.push(
      <SelectionItem onClick={() => onMethodSelect(method)}>
        <MethodSelectionItem>
          <MethodSelectionIcon>
            <img
              src={methodOptions[method].icon}
              alt={methodOptions[method].name}
              style={{ width: "100%" }}
            />
          </MethodSelectionIcon>
          <MethodSelectionText>
            {methodOptions[method].name}
          </MethodSelectionText>
        </MethodSelectionItem>
      </SelectionItem>
    );
  });

  const bankDetailsNotProvided =
    !bank_account_number &&
    !bank_routing_number &&
    !bank_iban &&
    !bank_swift &&
    !bank_account_name &&
    !bank_street &&
    !bank_city &&
    !bank_zip &&
    !bank_country;

  return (
    <PayoutSettings>
      <TitleText>
        Choose how you want to receive payouts for cash bonuses and referral
        contest prizes.
      </TitleText>
      <RollingBox>
        <PaymentMethod>
          <SettinsgItemTitle>
            Payment Method
            <Tip tip="If the minimum payout amount for the selected method is not reached by the end of the month, the sum will be carried over to the next month." />
          </SettinsgItemTitle>
          <SettingsItemLine>
            <SettingsItemValue>
              {!payment_method && "Not selected"}
              {payment_method && (
                <MethodSelectionItem>
                  <MethodSelectionIcon>
                    <img
                      src={methodOptions[payment_method].icon}
                      alt={methodOptions[payment_method].name}
                      style={{ width: "100%" }}
                    />
                  </MethodSelectionIcon>
                  <MethodSelectionText>
                    {methodOptions[payment_method].name}
                  </MethodSelectionText>
                </MethodSelectionItem>
              )}
            </SettingsItemValue>
            <SettingsItemButtonWrap ref={formButtonRef}>
              <ButtonPrimarySmall onClick={changePaymentMethodFormToggle}>
                {changePaymentMethodForm ? "Close" : "Change Payment Method"}
              </ButtonPrimarySmall>
            </SettingsItemButtonWrap>
          </SettingsItemLine>
          {changePaymentMethodForm && (
            <MethodHidingBox>
              <DropdownWrap>
                <Dropdown
                  onClick={toggleMethodSelection}
                  dropdownRef={methodDropdownRef}
                  warning={warnings.includes("paymentMethod")}
                >
                  {!methodSelected && "Select payment method"}
                  {methodSelected && (
                    <MethodSelectionItem>
                      <MethodSelectionIcon>
                        <img
                          src={methodOptions[methodSelected].icon}
                          alt={methodOptions[methodSelected].name}
                          style={{ width: "100%" }}
                        />
                      </MethodSelectionIcon>
                      <MethodSelectionText>
                        {methodOptions[methodSelected].name}
                      </MethodSelectionText>
                    </MethodSelectionItem>
                  )}
                </Dropdown>
                {methodSelectionOpened && (
                  <DropdownSelection
                    width="250px"
                    top="48px"
                    selectionRef={methodSelectionRef}
                  >
                    {methodOptionsList}
                  </DropdownSelection>
                )}
                <ButtonWrap>
                  <ButtonPrimary
                    onClick={changeMethod}
                    disabled={loadingChangeMethod}
                  >
                    Save
                  </ButtonPrimary>
                </ButtonWrap>
              </DropdownWrap>
              <TipBox>
                <TipWrap>
                  <TipIcon>
                    <img src={bulbIcon} alt="Tip" style={{ width: "100%" }} />
                  </TipIcon>
                  <TipText>
                    The payment method set at the end of the month will be used
                    to pay out your balance.
                  </TipText>
                </TipWrap>
              </TipBox>
            </MethodHidingBox>
          )}
        </PaymentMethod>
      </RollingBox>
      {payment_method === "bank" && (
        <RollingBox>
          <PaymentMethodDetails>
            <SettinsgItemTitle>Bank Transfer Details</SettinsgItemTitle>
            <SettingsItemLine>
              <MethodDetails>
                {bankDetailsNotProvided && (
                  <MethodDetailsValue>
                    Banking details not provided
                  </MethodDetailsValue>
                )}
                {bank_account_number && (
                  <MethodDetailsItem>
                    <MethodDetailsLabel>Bank account #:</MethodDetailsLabel>
                    <MethodDetailsValue>
                      {bank_account_number}
                    </MethodDetailsValue>
                  </MethodDetailsItem>
                )}
                {bank_routing_number && (
                  <MethodDetailsItem>
                    <MethodDetailsLabel>Routing #:</MethodDetailsLabel>
                    <MethodDetailsValue>
                      {bank_routing_number}
                    </MethodDetailsValue>
                  </MethodDetailsItem>
                )}
                {bank_iban && (
                  <MethodDetailsItem>
                    <MethodDetailsLabel>IBAN: </MethodDetailsLabel>
                    <MethodDetailsValue>{bank_iban}</MethodDetailsValue>
                  </MethodDetailsItem>
                )}
                {bank_swift && (
                  <MethodDetailsItem>
                    <MethodDetailsLabel>SWIFT Code:</MethodDetailsLabel>
                    <MethodDetailsValue>{bank_swift}</MethodDetailsValue>
                  </MethodDetailsItem>
                )}
                {bank_account_name && (
                  <MethodDetailsItem>
                    <MethodDetailsLabel>Bank Account Name:</MethodDetailsLabel>
                    <MethodDetailsValue>{bank_account_name}</MethodDetailsValue>
                  </MethodDetailsItem>
                )}
                {bank_street && (
                  <MethodDetailsItem>
                    <MethodDetailsLabel>Street: </MethodDetailsLabel>
                    <MethodDetailsValue>{bank_street}</MethodDetailsValue>
                  </MethodDetailsItem>
                )}
                {bank_city && (
                  <MethodDetailsItem>
                    <MethodDetailsLabel>City:</MethodDetailsLabel>
                    <MethodDetailsValue>{bank_city}</MethodDetailsValue>
                  </MethodDetailsItem>
                )}
                {bank_zip && (
                  <MethodDetailsItem>
                    <MethodDetailsLabel>ZIP Code:</MethodDetailsLabel>
                    <MethodDetailsValue>{bank_zip}</MethodDetailsValue>
                  </MethodDetailsItem>
                )}
                {bank_country && (
                  <MethodDetailsItem>
                    <MethodDetailsLabel>Country:</MethodDetailsLabel>
                    <MethodDetailsValue>{bank_country}</MethodDetailsValue>
                  </MethodDetailsItem>
                )}
              </MethodDetails>
              <SettingsItemButtonWrap style={{ alignSelf: "flex-start" }}>
                <ButtonPrimarySmall onClick={methodDetailsFormToggle}>
                  {methodDetailsForm ? "Close" : "Update Payment Details"}
                </ButtonPrimarySmall>
              </SettingsItemButtonWrap>
            </SettingsItemLine>
            {methodDetailsForm && (
              <MethodDetailsHidingBox>
                <InputWithLabel label="Bank Account #" width="290px">
                  <InputText
                    type="text"
                    name="bankAccountNumber"
                    value={methodDetails.bankAccountNumber}
                    placeholder="Enter bank account number"
                    onChange={handleDetailsInputChange}
                    maxLength={100}
                    warning={warnings.includes("bankAccountNumber")}
                  />
                </InputWithLabel>
                <InputWithLabel label="Routing #" width="200px">
                  <InputText
                    type="text"
                    name="bankRoutingNumber"
                    value={methodDetails.bankRoutingNumber}
                    placeholder="Enter routing number"
                    onChange={handleDetailsInputChange}
                    maxLength={30}
                    warning={warnings.includes("bankRoutingNumber")}
                  />
                </InputWithLabel>
                <InputWithLabel label="IBAN" width="290px">
                  <InputText
                    type="text"
                    name="bankIban"
                    value={methodDetails.bankIban}
                    placeholder="Enter IBAN"
                    onChange={handleDetailsInputChange}
                    maxLength={50}
                    warning={warnings.includes("bankIban")}
                  />
                </InputWithLabel>
                <InputWithLabel label="SWIFT Code" width="200px">
                  <InputText
                    type="text"
                    name="bankSwift"
                    value={methodDetails.bankSwift}
                    placeholder="Enter SWIFT code"
                    onChange={handleDetailsInputChange}
                    maxLength={20}
                    warning={warnings.includes("bankSwift")}
                  />
                </InputWithLabel>
                <InputWithLabel label="Bank Account Name" width="290px">
                  <InputText
                    type="text"
                    name="bankAccountName"
                    value={methodDetails.bankAccountName}
                    placeholder="Enter bank account name"
                    onChange={handleDetailsInputChange}
                    maxLength={100}
                    warning={warnings.includes("bankAccountName")}
                  />
                </InputWithLabel>
                <InputWithLabel label="Street" width="290px">
                  <InputText
                    type="text"
                    name="bankAccountStreet"
                    value={methodDetails.bankAccountStreet}
                    placeholder="Account holder's street"
                    onChange={handleDetailsInputChange}
                    maxLength={100}
                    warning={warnings.includes("bankAccountStreet")}
                  />
                </InputWithLabel>
                <InputWithLabel label="City" width="200px">
                  <InputText
                    type="text"
                    name="bankAccountCity"
                    value={methodDetails.bankAccountCity}
                    placeholder="Account holder's city"
                    onChange={handleDetailsInputChange}
                    maxLength={100}
                    warning={warnings.includes("bankAccountCity")}
                  />
                </InputWithLabel>
                <InputWithLabel label="ZIP Code" width="200px">
                  <InputText
                    type="text"
                    name="bankAccountZip"
                    value={methodDetails.bankAccountZip}
                    placeholder="Account holder's ZIP code"
                    onChange={handleDetailsInputChange}
                    maxLength={100}
                    warning={warnings.includes("bankAccountZip")}
                  />
                </InputWithLabel>
                <InputWithLabel label="Country" width="290px">
                  <InputText
                    type="text"
                    name="bankAccountCountry"
                    value={methodDetails.bankAccountCountry}
                    placeholder="Account holder's Country"
                    onChange={handleDetailsInputChange}
                    maxLength={100}
                    warning={warnings.includes("bankAccountCountry")}
                  />
                </InputWithLabel>
                <ButtonWrap>
                  <ButtonPrimary
                    onClick={updateMethodDetails}
                    disabled={loadingUpdateMethodDetails}
                  >
                    Save
                  </ButtonPrimary>
                </ButtonWrap>
              </MethodDetailsHidingBox>
            )}
          </PaymentMethodDetails>
        </RollingBox>
      )}
      {payment_method === "skrill" && (
        <RollingBox>
          <PaymentMethodDetails>
            <SettinsgItemTitle>Skrill Details</SettinsgItemTitle>
            <SettingsItemLine>
              <MethodDetails>
                {!skrill_email && (
                  <MethodDetailsValue>
                    Skrill details not provided
                  </MethodDetailsValue>
                )}
                {skrill_email && (
                  <MethodDetailsItem>
                    <MethodDetailsLabel>
                      Skrill email address:
                    </MethodDetailsLabel>
                    <MethodDetailsValue>{skrill_email}</MethodDetailsValue>
                  </MethodDetailsItem>
                )}
              </MethodDetails>
              <SettingsItemButtonWrap style={{ alignSelf: "flex-start" }}>
                <ButtonPrimarySmall onClick={methodDetailsFormToggle}>
                  {methodDetailsForm ? "Close" : "Update Payment Details"}
                </ButtonPrimarySmall>
              </SettingsItemButtonWrap>
            </SettingsItemLine>
            {methodDetailsForm && (
              <MethodDetailsHidingBox>
                <InputWithLabel label="Skrill Email Address" width="290px">
                  <InputText
                    type="text"
                    name="skrillEmail"
                    value={methodDetails.skrillEmail}
                    placeholder="Enter your Skrill account email"
                    onChange={handleDetailsInputChange}
                    maxLength={100}
                    warning={warnings.includes("skrillEmail")}
                  />
                </InputWithLabel>
                <ButtonWrap>
                  <ButtonPrimary
                    onClick={updateMethodDetails}
                    disabled={loadingUpdateMethodDetails}
                  >
                    Save
                  </ButtonPrimary>
                </ButtonWrap>
              </MethodDetailsHidingBox>
            )}
          </PaymentMethodDetails>
        </RollingBox>
      )}
      {payment_method === "bitcoin" && (
        <RollingBox>
          <PaymentMethodDetails>
            <SettinsgItemTitle>Bitcoin Details</SettinsgItemTitle>
            <SettingsItemLine>
              <MethodDetails>
                {!bitcoin_address && (
                  <MethodDetailsValue>
                    Bitcoin details not provided
                  </MethodDetailsValue>
                )}
                {bitcoin_address && (
                  <MethodDetailsItem>
                    <MethodDetailsLabel>Bitcoin address:</MethodDetailsLabel>
                    <MethodDetailsValue>{bitcoin_address}</MethodDetailsValue>
                  </MethodDetailsItem>
                )}
              </MethodDetails>
              <SettingsItemButtonWrap style={{ alignSelf: "flex-start" }}>
                <ButtonPrimarySmall onClick={methodDetailsFormToggle}>
                  {methodDetailsForm ? "Close" : "Update Payment Details"}
                </ButtonPrimarySmall>
              </SettingsItemButtonWrap>
            </SettingsItemLine>
            {methodDetailsForm && (
              <MethodDetailsHidingBox>
                <InputWithLabel label="Bitcoin Address" width="290px">
                  <InputText
                    type="text"
                    name="bitcoinAddress"
                    value={methodDetails.bitcoinAddress}
                    placeholder="Enter your Bitcoin address"
                    onChange={handleDetailsInputChange}
                    maxLength={100}
                    warning={warnings.includes("bitcoinAddress")}
                  />
                </InputWithLabel>
                <ButtonWrap>
                  <ButtonPrimary
                    onClick={updateMethodDetails}
                    disabled={loadingUpdateMethodDetails}
                  >
                    Save
                  </ButtonPrimary>
                </ButtonWrap>
              </MethodDetailsHidingBox>
            )}
          </PaymentMethodDetails>
        </RollingBox>
      )}
    </PayoutSettings>
  );
};
