import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

const Item = styled.div`
  width: 100%;
  height: 32px;
  padding: 5px 15px;
  box-sizing: border-box;

  border-radius: 16px;

  transition: 0.2s;

  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background: #3e5265;
    cursor: pointer;
  }
`;

const ItemIcon = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;
  box-sizing: border-box;

  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const ItemText = styled.div`
  font-size: 13px;
  letter-spacing: 0.2px;
  white-space: nowrap;
`;

export default ({ icon, children, onClick }) => {
  return (
    <Item onClick={onClick}>
      <ItemIcon icon={icon} />
      <ItemText>{children}</ItemText>
    </Item>
  );
};
