import { gql, useQuery } from "@apollo/client";

import Contest from "./Contest";

export default () => {
  // Fetch data
  const GET_CONTEST_DATA = gql`
    query GetContestData {
      contestData {
        ongoingPrizes
        ongoingStarts
        ongoingEnds
        ongoingPoints
        rankingGroup
        upcomingPrizes
        upcomingStarts
        upcomingEnds
        contestResults {
          id
          started_at
          ended_at
          points
          rank
          prize
        }
      }
    }
  `;

  const { loading, data, error } = useQuery(GET_CONTEST_DATA, {
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching contest data error", error);
    //captureException(error);
  }

  console.log({ data });

  return <Contest data={data} />;
};
