import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

const Item = styled(Link)`
  text-decoration: none;
`;

const ItemText = styled.div`
  font-size: 14px;
  color: ${css.TEXT_GREY};

  transition: 0.2s;

  &:hover {
    color: #e9e9e9;
  }
`;

export default ({ to, children }) => {
  return (
    <Item to={to}>
      <ItemText>{children}</ItemText>
    </Item>
  );
};
