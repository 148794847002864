import { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import * as css from "styles/CssVariables.js";

import ReferralList from "./ReferralList";
import LoadingBox from "components/other/LoadingBox";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";

export default () => {
  const [page, setPage] = useState(1);

  // Fetch data
  const GET_REFERRAL_LIST = gql`
    query GetReferralList($page: Int!) {
      referralList(page: $page) {
        referrals {
          id
          created_at
          data
          qualified_at
          referral_reward
        }
        numOfResults
      }
    }
  `;

  const { loading, data, error } = useQuery(GET_REFERRAL_LIST, {
    variables: {
      page,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching referral list error", error);
    //captureException(error);
  }

  console.log({ data });

  const limit = 10;

  if (data) {
    if (data.referralList.numOfResults > 0)
      if (page > Math.ceil(data.referralList.numOfResults / limit)) setPage(1);
  }

  const paginate = (where) => {
    if (where === "previous") setPage((prevState) => prevState - 1);
    if (where === "next") setPage((prevState) => prevState + 1);
  };

  return (
    <>
      {!data && (
        <LoadingBox>
          <BoxLoadingSpinner height="230px" />
        </LoadingBox>
      )}
      {data && (
        <ReferralList
          data={data}
          page={page}
          limit={limit}
          paginate={paginate}
        />
      )}
    </>
  );
};
