import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import globalConstants from "../../constants/globalConstants";

const DropdownSelection = styled.div`
  position: absolute;
  top: ${(props) => (props.top ? props.top : "46px")};
  left: 0;
  width: ${(props) => (props.width ? props.width : "100%")};
  z-index: 1000;
  transform: ${(props) => (props.transform ? props.transform : 0)};
  box-sizing: border-box;

  padding: 0px;
  background: ${css.DARK_BOX_BACKGROUND};
  border-radius: 15px;
  box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.4);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 800px) {
    left: ${(props) => (props.right ? "auto" : props.left)};
    right: ${(props) => (props.right ? props.right : "auto")};
    width: ${(props) => props.width};
    min-width: ${(props) => props.width};
  }
`;

export default ({ width, top, children, transform, selectionRef }) => {
  return (
    <DropdownSelection
      ref={selectionRef}
      width={width}
      top={top}
      transform={transform}
    >
      {children}
    </DropdownSelection>
  );
};
