import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import globalConstants from "constants/globalConstants";
import MenuItem from "./MenuItem";
import ButtonPrimaryIcon from "components/buttons/ButtonPrimaryIcon";

import homeIcon from "assets/img/icons/menu-home.svg";
import discoverIcon from "assets/img/icons/menu-discover.svg";
import messagesIcon from "assets/img/icons/menu-messages.svg";
import postsIcon from "assets/img/icons/menu-posts.svg";
import referralsIcon from "assets/img/icons/menu-referrals.svg";
import walletIcon from "assets/img/icons/menu-wallet.svg";
import settingsIcon from "assets/img/icons/menu-settings.svg";
import homeCurrentIcon from "assets/img/icons/menu-home-current.svg";
import discoverCurrentIcon from "assets/img/icons/menu-discover-current.svg";
import messagesCurrentIcon from "assets/img/icons/menu-messages-current.svg";
import postsCurrentIcon from "assets/img/icons/menu-posts-current.svg";
import referralsCurrentIcon from "assets/img/icons/menu-referrals-current.svg";
import walletCurrentIcon from "assets/img/icons/menu-wallet-current.svg";
import settingsCurrentIcon from "assets/img/icons/menu-settings-current.svg";
import logoutIcon from "assets/img/icons/menu-logout.svg";
import plusIcon from "assets/img/icons/plus-sign.svg";
import plusWhiteIcon from "assets/img/icons/plus-sign-white.svg";
import trophyIcon from "assets/img/icons/trophy-icon.svg";
import trophyCurrentIcon from "assets/img/icons/trophy-icon-current.svg";
import helpIcon from "assets/img/icons/help-icon.svg";

const Menu = styled.div`
  margin-bottom: ${(props) => (props.tablet ? "40px" : "0")};

  display: flex;
  flex-direction: column;
`;

const ButtonWrap = styled.div`
  margin-top: ${(props) => (props.tablet ? "40px" : "60px")};
  margin-bottom: 5px;
  padding-left: ${(props) => (props.tablet ? "40px" : "0")};

  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.tablet ? "flex-start" : "stretch")};
`;

export default () => {
  const navigate = useNavigate();

  const tabletScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchTablet})`,
  });

  return (
    <Menu tablet={tabletScreen ? 1 : 0}>
      <MenuItem to="/home" icon={homeIcon} iconCurrent={homeCurrentIcon}>
        Home
      </MenuItem>
      <MenuItem
        to="/discover"
        icon={discoverIcon}
        iconCurrent={discoverCurrentIcon}
        emphasized={true}
      >
        Discover
      </MenuItem>
      <MenuItem
        to="/messages"
        icon={messagesIcon}
        iconCurrent={messagesCurrentIcon}
      >
        Messages
      </MenuItem>
      <MenuItem to="/posts" icon={postsIcon} iconCurrent={postsCurrentIcon}>
        Posts
      </MenuItem>
      <MenuItem
        to="/referrals"
        icon={referralsIcon}
        iconCurrent={referralsCurrentIcon}
      >
        Referrals
      </MenuItem>
      <MenuItem to="/contest" icon={trophyIcon} iconCurrent={trophyCurrentIcon}>
        Contest
      </MenuItem>
      <MenuItem to="/wallet" icon={walletIcon} iconCurrent={walletCurrentIcon}>
        Wallet
      </MenuItem>
      <MenuItem
        to="/settings"
        icon={settingsIcon}
        iconCurrent={settingsCurrentIcon}
      >
        Settings
      </MenuItem>
      <MenuItem to="https://help.scalelup.com" icon={helpIcon} target="_blank">
        Help
      </MenuItem>
      <MenuItem to="/logout" icon={logoutIcon}>
        Logout
      </MenuItem>
      <ButtonWrap tablet={tabletScreen ? 1 : 0}>
        <ButtonPrimaryIcon
          icon={plusIcon}
          iconHover={plusWhiteIcon}
          onClick={() => {
            navigate("/modify-post");
          }}
        >
          Create Post
        </ButtonPrimaryIcon>
      </ButtonWrap>
    </Menu>
  );
};
