import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ScrollToTop from "./components/other/ScrollToTop";
//import * as Sentry from "@sentry/react";
import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";

import store from "./store";
import client from "./client";
import "react-tooltip/dist/react-tooltip.css";

// Sentry.init({
//   dsn: "https://ad2dc15a052e16dda85b83fe78e6ee95@o4506354325848064.ingest.sentry.io/4506354328076288",
//   integrations: [],
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HttpsRedirect>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <GoogleOAuthProvider clientId="860017276357-1lu4q3k2pjm6hsvoj93fl4hhn6oq0lq5.apps.googleusercontent.com">
          <BrowserRouter>
            <ScrollToTop />
            <App />
          </BrowserRouter>
        </GoogleOAuthProvider>
      </ApolloProvider>
    </Provider>
  </HttpsRedirect>
);

reportWebVitals();

if (module.hot) {
  module.hot.accept();
}
