import styled from "styled-components";

import globalConstants from "constants/globalConstants";
import MenuFooterItem from "./MenuFooterItem";

const MenuSmall = styled.div`
  padding-left: 40px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default () => {
  return (
    <MenuSmall>
      <MenuFooterItem to="/terms">Terms</MenuFooterItem>
      <MenuFooterItem to="/privacy-policy">Privacy Policy</MenuFooterItem>
    </MenuSmall>
  );
};
