// @flow
import { SAVE_PROFILE_DATA, SAVE_IP_ADDRESS } from "./actionTypes";

const INIT_STATE = {
  profileData: null,
  ipAddress: null,
};
const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_PROFILE_DATA:
      return {
        ...state,
        profileData: action.payload,
      };

    case SAVE_IP_ADDRESS:
      return {
        ...state,
        ipAddress: action.payload,
      };

    default:
      return state;
  }
};

export default User;
