import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import ModalWindow from "components/other/ModalWindow";
import InputText from "components/forms/InputText";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import InputWithLabel from "components/forms/InputWithLabel";

import scalelupLogo from "assets/img/logo/scalelup.svg";

const ForgotPassword = styled.div`
  width: 100%;

  color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ForgotForm = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoWrap = styled.div`
  width: 150px;
  padding: 5px 0 20px;
`;

const Heading = styled.div`
  font-size: 24px;
`;

const Details = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};

  text-align: center;
`;

const EmailForm = styled.div`
  width: 100%;
  margin-top: 25px;

  display: flex;
  flex-direction: column;
`;

const EmailFormControls = styled.div`
  width: 100%;
  margin-top: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LoginLink = styled.span`
  padding-left: 1px;

  font-weight: 500;
  color: ${css.LIGHT_BLUE};

  transition: 0.2;

  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }
`;

const ErrorText = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};
  max-width: 340px;

  font-weight: 500;
  color: ${css.ERROR_RED};
`;

const SentInfo = styled.div`
  margin-top: 15px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessText = styled.div`
  margin-top: 10px;

  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 125%;
`;

const CloseButtonWrap = styled.div`
  margin-top: 35px;
`;

const ResendLink = styled.div`
  margin-top: 10px;

  font-weight: 500;
  color: ${css.LIGHT_BLUE};

  transition: 0.2s;

  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }
`;

export default (props) => {
  const {
    handleModalClose,
    handleInputChange,
    email,
    loading,
    warnings,
    forgotPasswordMessage,
    forgotPasswordSuccess,
    onSubmit,
    openLogin,
    handleKeyDown,
    showResendLink,
    onResendVerification,
  } = props;

  return (
    <ModalWindow handleModalClose={handleModalClose} fullScreenHeight={true}>
      <ForgotPassword>
        {!forgotPasswordSuccess && (
          <ForgotForm>
            <LogoWrap>
              <img src={scalelupLogo} style={{ width: "100%" }} />
            </LogoWrap>
            <Heading>Forgot Password</Heading>
            <Details>
              Forgot your Scalelup password? Please enter your email below and
              we will help you reset it.
            </Details>
            <EmailForm>
              <InputWithLabel label="E-Mail Address" width="100%">
                <InputText
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Enter your email..."
                  onChange={handleInputChange}
                  maxLength={100}
                  warning={warnings.includes("email")}
                  onKeyDown={handleKeyDown}
                />
              </InputWithLabel>
              {forgotPasswordMessage && (
                <ErrorText>{forgotPasswordMessage}</ErrorText>
              )}
              {showResendLink && (
                <ResendLink onClick={onResendVerification}>
                  Resend email
                </ResendLink>
              )}
              <EmailFormControls>
                <ButtonPrimary onClick={onSubmit} disabled={loading}>
                  Submit
                </ButtonPrimary>
                <LoginLink onClick={() => openLogin()}>Log In</LoginLink>
              </EmailFormControls>
            </EmailForm>
          </ForgotForm>
        )}
        {forgotPasswordSuccess && (
          <SentInfo>
            <LogoWrap>
              <img src={scalelupLogo} style={{ width: "100%" }} />
            </LogoWrap>
            <SuccessText>
              We have just sent you an email that will help you log into your
              account and change your password.
            </SuccessText>
            <CloseButtonWrap>
              <ButtonPrimary onClick={handleModalClose}>Close</ButtonPrimary>
            </CloseButtonWrap>
          </SentInfo>
        )}
      </ForgotPassword>
    </ModalWindow>
  );
};
