import styled from "styled-components";
import Linkify from "linkify-react";
import { Link } from "react-router-dom";
import { isURL } from "validator";
import { useMediaQuery } from "react-responsive";

import * as css from "styles/CssVariables.js";
import { AWS_USER_UPLOAD_URL } from "constants";
import globalConstants from "constants/globalConstants";
import { timeSince } from "modules/functions";

import linkIcon from "assets/img/icons/icons8-link.svg";
import messageIcon from "assets/img/icons/icons8-new-message.svg";
import messageWhiteIcon from "assets/img/icons/icons8-new-message-white.svg";
import heartIcon from "assets/img/icons/icons8-heart.svg";
import heartRedIcon from "assets/img/icons/icons8-heart-red.svg";
import bookmarkIcon from "assets/img/icons/icons8-bookmark.svg";
import alertIcon from "assets/img/icons/icons8-alert.svg";

const PostContent = styled.div`
  width: 100%;
  padding: 0;

  display: flex;
  flex-direction: column;
`;

const PostHeader = styled.div`
  display: flex;
  gap: ${(props) => (props.mobile ? "8px" : "12px")};
`;

const AvatarIconWrap = styled.div`
  width: ${(props) => (props.mobile ? "48px" : "56px")};
  min-width: ${(props) => (props.mobile ? "48px" : "56px")};
  aspect-ratio: 1;
`;

const AvatarIcon = styled.img`
  width: 100%;
  border-radius: 50%;
`;

const PostHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Username = styled.div`
  padding-top: ${(props) => (props.mobile ? "7px" : "9px")};

  font-size: ${(props) => (props.mobile ? "16px" : "18px")};
  font-weight: 500;
`;

const TimePosted = styled.div`
  padding-top: ${(props) => (props.mobile ? "1px" : "2px")};

  font-size: ${(props) => (props.mobile ? "13px" : "15px")};
  color: ${css.TEXT_GREY};
`;

const Main = styled.div``;

const Text = styled.div`
  margin-top: 8px;

  font-family: "Roboto";
  font-size: ${(props) => (props.mobile ? "15px" : "16px")};
  line-height: 135%;
  white-space: pre-line;

  > a {
    color: ${css.LIGHT_BLUE};
    text-decoration: none;
  }
`;

const ImageLinkWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ImageBox = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
  width: 100%;
  overflow: hidden;

  text-align: center;

  border-radius: ${css.BOX_RADIUS_SECONDARY};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-height: 320px;
  object-fit: contain;
`;

const ImageLink = styled(Link)`
  margin-top: 2px;
  width: 100%;

  text-decoration: none;

  display: flex;
  align-items: center;
`;

const ImageLinkIcon = styled.div`
  width: ${(props) => (props.mobile ? "15px" : "18px")};
  min-width: ${(props) => (props.mobile ? "15px" : "18px")};
  aspect-ratio: 1;
`;

const ImageLinkText = styled.div`
  padding-left: 2px;
  padding-bottom: 2px;
  max-width: 200px;

  font-size: ${(props) => (props.mobile ? "11px" : "13px")};
  color: ${css.TEXT_GREY};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: 0.2s;

  &:hover {
    color: #e9e9e9;
  }
`;

const Controls = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NewMessageIcon = styled.div`
  width: ${(props) => (props.mobile ? "18px" : "24px")};
  height: ${(props) => (props.mobile ? "18px" : "24px")};

  transition: 0.2s;

  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const NewMessageCircle = styled.div`
  width: ${(props) => (props.mobile ? "40px" : "50px")};
  min-width: ${(props) => (props.mobile ? "40px" : "50px")};
  height: ${(props) => (props.mobile ? "40px" : "50px")};

  border-radius: ${(props) => (props.mobile ? "20px" : "25px")};
  background: ${css.BRAND_YELLOW};

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;

    background: ${css.BRAND_GREEN};
  }

  &:hover ${NewMessageIcon} {
    background-image: url(${(props) => props.iconhover});
  }
`;

const SocialControls = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SocialCircle = styled.div`
  /* width: ${(props) => (props.mobile ? "30px" : "36px")};
  min-width: ${(props) => (props.mobile ? "30px" : "36px")};
  height: ${(props) => (props.mobile ? "30px" : "36px")}; */
  width: 36px;
  min-width: 36px;
  height: 36px;

  /* border-radius: ${(props) => (props.mobile ? "15px" : "18px")}; */
  border-radius: 18px;
  background: ${css.ROUND_ICON_BACKGROUND};

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    !props.disabled &&
    `&:hover {
    filter: brightness(120%);
    cursor: pointer;
  }`}
`;

const SocialIcon = styled.div`
  /* width: ${(props) => (props.mobile ? "18px" : "22px")}; */
  width: 22px;

  display: flex;

  align-items: center;
`;

const PreviewInfo = styled.div`
  margin-top: 8px;

  color: ${css.TEXT_GREY};
`;

const LikesWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Likes = styled.div``;

export default ({
  isPreview = false,
  isPostMessagePreview = false,
  avatar,
  username,
  createdAt,
  text,
  image,
  imageLink,
  linkClicked,
  onNewMessageClick,
  likePostClick,
  postLiked,
  likes,
}) => {
  const mobileScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchMobile})`,
  });

  const mobile = mobileScreen || isPreview;

  const postCreated = createdAt ? new Date(createdAt) : new Date();
  const published =
    isPreview && !isPostMessagePreview
      ? "Just now"
      : timeSince(postCreated) + " ago";

  const hasValidImageLink = imageLink
    ? isURL(imageLink, {
        protocols: ["http", "https"],
        require_valid_protocol: true,
        require_protocol: true,
      })
    : false;

  // Resize image link box to fit the image width
  const alignImageLink = () => {
    const imageImg = document.getElementById("postImage");
    const imageLink = document.getElementById("imageLink");

    if (imageImg && imageLink) {
      imageLink.setAttribute("style", `width:${imageImg.offsetWidth}px`);
    }
  };

  let imageLinkHostname;

  if (imageLink && hasValidImageLink) {
    try {
      const imageLinkUrl = new URL(imageLink);
      imageLinkHostname = imageLinkUrl.hostname;
    } catch (error) {
      // image link invalid
    }
  }

  return (
    <PostContent>
      <PostHeader>
        <AvatarIconWrap mobile={mobile ? 1 : 0}>
          <AvatarIcon src={avatar} />
        </AvatarIconWrap>
        <PostHeaderContent>
          <Username mobile={mobile ? 1 : 0}>{username}</Username>
          <TimePosted mobile={mobile ? 1 : 0}>{published}</TimePosted>
        </PostHeaderContent>
      </PostHeader>
      <Main>
        {isPreview && text.length === 0 && !image && (
          <PreviewInfo>
            Here you will see your new amazing post. Remember that posts with an
            image have a significantly higher engagement. 🙌
          </PreviewInfo>
        )}
        <Linkify
          as={Text}
          options={{
            target: "_blank",
            attributes: {
              onClick: (e) => {
                if (isPreview) return;
                linkClicked(e);
              },
            },
          }}
          mobile={mobile ? 1 : 0}
        >
          {text}
        </Linkify>
        <ImageLinkWrap>
          {image && (
            <>
              {hasValidImageLink ? (
                <Link
                  to={imageLink}
                  target="_blank"
                  onClick={(e) => {
                    if (isPreview) return;
                    linkClicked(e);
                  }}
                >
                  <ImageBox>
                    <Image
                      src={AWS_USER_UPLOAD_URL + image}
                      id="postImage"
                      onLoad={alignImageLink}
                    />
                  </ImageBox>
                </Link>
              ) : (
                <div>
                  <ImageBox>
                    <Image src={AWS_USER_UPLOAD_URL + image} id="postImage" />
                  </ImageBox>
                </div>
              )}
            </>
          )}
          {image && hasValidImageLink && imageLinkHostname && (
            <ImageLink
              to={imageLink}
              target="_blank"
              id="imageLink"
              onClick={(e) => linkClicked(e)}
            >
              <ImageLinkIcon mobile={mobile ? 1 : 0}>
                <img src={linkIcon} style={{ width: "100%" }} />
              </ImageLinkIcon>
              <ImageLinkText mobile={mobile ? 1 : 0}>
                {imageLinkHostname}
              </ImageLinkText>
            </ImageLink>
          )}
        </ImageLinkWrap>
        {!isPostMessagePreview && (
          <Controls>
            <NewMessageCircle
              iconhover={messageWhiteIcon}
              mobile={mobile ? 1 : 0}
              onClick={onNewMessageClick}
            >
              <NewMessageIcon icon={messageIcon} mobile={mobile ? 1 : 0} />
            </NewMessageCircle>
            <SocialControls>
              <LikesWrap>
                <SocialCircle
                  mobile={mobile ? 1 : 0}
                  onClick={likePostClick}
                  disabled={postLiked ? 1 : 0}
                >
                  <SocialIcon mobile={mobile ? 1 : 0}>
                    <img
                      src={postLiked ? heartRedIcon : heartIcon}
                      alt="Like"
                      style={{ width: "100%" }}
                    />
                  </SocialIcon>
                </SocialCircle>
                {likes > 0 && <Likes>{likes}</Likes>}
              </LikesWrap>
              {/* <SocialCircle mobile={mobile ? 1 : 0}>
                <SocialIcon mobile={mobile ? 1 : 0}>
                  <img
                    src={bookmarkIcon}
                    alt="Bookmark"
                    style={{ width: "100%" }}
                  />
                </SocialIcon>
              </SocialCircle> */}
              <SocialCircle mobile={mobile ? 1 : 0}>
                <SocialIcon mobile={mobile ? 1 : 0}>
                  <img src={alertIcon} alt="Report" style={{ width: "100%" }} />
                </SocialIcon>
              </SocialCircle>
            </SocialControls>
          </Controls>
        )}
      </Main>
    </PostContent>
  );
};
