import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

const Item = styled(Link)`
  text-decoration: none;
`;

const ItemIcon = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  box-sizing: border-box;

  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: cover;

  padding: ${(props) => (props.padding ? props.padding : "0")};
`;

export default ({ to, icon, iconCurrent, padding, onClick }) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      {to !== "#" && (
        <Item to={to} onClick={onClick}>
          <ItemIcon
            icon={pathname === to ? iconCurrent : icon}
            padding={padding}
          />
        </Item>
      )}
      {to === "#" && (
        <ItemIcon
          onClick={onClick}
          icon={pathname === to ? iconCurrent : icon}
          padding={padding}
        />
      )}
    </>
  );
};
