import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import SideContestContainer from "./SideContestContainer";

import trophyCurrentIcon from "assets/img/icons/trophy-icon-current.svg";

const SideContestBox = styled.div`
  /* width: 260px; */
  box-sizing: border-box;

  margin: 0;
  padding: 25px;

  border-radius: ${css.BOX_RADIUS_PRIMARY};
  background: ${css.BLUE_BOX_GRADIENT};

  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  display: flex;
  align-items: center;
  gap: 9px;
`;

const HeaderIcon = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;
`;

const HeaderText = styled.div`
  font-weight: 500;
`;

export default () => {
  return (
    <SideContestBox>
      <Header>
        <HeaderIcon>
          <img src={trophyCurrentIcon} style={{ width: "100%" }} />
        </HeaderIcon>
        <HeaderText>Scalelup Contest</HeaderText>
      </Header>
      <SideContestContainer />
    </SideContestBox>
  );
};
