import { combineReducers } from "redux";

// Layout
import Layout from "./layout/reducer";

// User
import User from "./user/reducer";

const rootReducer = combineReducers({
  Layout,
  User,
});

export default rootReducer;
