import styled from "styled-components";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import { AWS_PROFILE_IMAGES_URL } from "constants";
import { dateFormatted } from "modules/functions";

import coinsIcon from "assets/img/icons/coins-icon.svg";
import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";
import blockIcon from "assets/img/icons/icons8-unavailable.svg";

const ReferralListBox = styled.div`
  width: 100%;
  min-height: 280px;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
`;

const TableWrap = styled.div`
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: -5px;

  overflow-x: auto;
`;

const ListTable = styled.table`
  width: 100%;
  min-width: 485px;
  max-width: 700px;
`;

const TableHead = styled.thead``;

const Label = styled.th`
  padding-bottom: 12px;

  font-size: 18px;
  font-weight: 400;
  text-align: left;
`;

const TableBody = styled.tbody``;

const Cell = styled.td``;

const AvatarNameBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AvatarIcon = styled.div`
  margin: 4px 0;

  width: 32px;
  min-width: 32px;
  height: 32px;

  border-radius: 16px;

  overflow: hidden;
`;

const Name = styled.div``;

const Badge = styled.div`
  height: 22px;
  width: 50px;

  font-size: 13px;

  border-radius: 11px;
  background: ${(props) =>
    props.green ? css.POSITIVE_GREEN : css.NEGATIVE_RED};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const RewardBox = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

const CoinsIcon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const Reward = styled.div``;

const Pagination = styled.div`
  width: 100%;
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  display: flex;
  justify-content: center;
  gap: ${css.BUTTONS_GAP_SMALL};
`;

const NoResults = styled.div`
  width: 100%;
  margin-top: 75px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const NoResultsIcon = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
`;

const NoResultsText = styled.div``;

export default ({ data, page, limit, paginate }) => {
  const { referrals, numOfResults } = data.referralList;

  const list = referrals.map((referral) => {
    return (
      <tr key={referral.id}>
        <Cell>
          <AvatarNameBox>
            <AvatarIcon>
              <img
                src={AWS_PROFILE_IMAGES_URL + referral.data.profile_picture}
                style={{ width: "100%", borderRadius: "50%" }}
              />
            </AvatarIcon>
            <Name>{referral.data.username}</Name>
          </AvatarNameBox>
        </Cell>
        <Cell>{dateFormatted(new Date(referral.created_at))}</Cell>
        <Cell>
          <Badge green={referral.qualified_at ? 1 : 0}>
            {referral.qualified_at ? "Yes" : "No"}
          </Badge>
        </Cell>
        <Cell>
          {referral.referral_reward && (
            <RewardBox>
              <CoinsIcon>
                <img src={coinsIcon} style={{ width: "100%" }} />
              </CoinsIcon>
              <Reward>{referral.referral_reward} Coins</Reward>
            </RewardBox>
          )}
          {!referral.referral_reward && <Reward>-</Reward>}
        </Cell>
      </tr>
    );
  });

  let showPagination = false;

  if (numOfResults > limit) showPagination = true;

  const maxPage = Math.ceil(numOfResults / limit);

  return (
    <ReferralListBox>
      <TableWrap className="table-wrap">
        {list.length > 0 && (
          <ListTable>
            <TableHead>
              <tr>
                <Label>Name</Label>
                <Label>Registered</Label>
                <Label>Qualified</Label>
                <Label>Reward</Label>
              </tr>
            </TableHead>
            <TableBody>{list}</TableBody>
          </ListTable>
        )}
        {list.length === 0 && (
          <NoResults>
            <NoResultsIcon>
              <img src={blockIcon} style={{ width: "100%" }} />
            </NoResultsIcon>
            <NoResultsText>You don't have any referrals yet.</NoResultsText>
          </NoResults>
        )}
      </TableWrap>
      {showPagination && (
        <Pagination>
          {page > 1 && (
            <ButtonPrimarySmall onClick={() => paginate("previous")}>
              Previous
            </ButtonPrimarySmall>
          )}
          {page < maxPage && (
            <ButtonPrimarySmall onClick={() => paginate("next")}>
              Next
            </ButtonPrimarySmall>
          )}
        </Pagination>
      )}
    </ReferralListBox>
  );
};
