import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import TestimonialPost from "./TestimonialPost";
import globalConstants from "constants/globalConstants";

import starIcon from "assets/img/icons/icons8-star.svg";
import quotesWhiteIcon from "assets/img/icons/icons8-quotes-white.svg";
import quotesDarkIcon from "assets/img/icons/icons8-quotes-dark.svg";

const Testimonial = styled.div`
  max-width: 400px;
  min-width: 380px;
  padding: 35px 35px 0 35px;
  box-sizing: border-box;

  background: ${(props) => props.background};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  font-family: "Barlow", sans-serif;
  color: ${(props) => props.textcolor};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;

  @media (max-width: ${globalConstants.switchMobile}) {
    min-width: 340px;
    padding: 30px 28px 0 28px;
  }
`;

const TopPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const HeaderTitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const HeaderName = styled.div`
  font-size: 28px;
  font-weight: 500;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 24px;
  }
`;

const HeaderStars = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`;

const StarIcon = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
`;

const Date = styled.div`
  color: ${(props) => props.textcolor};
  font-size: 18px;
  font-weight: 400;
`;

const HeaderQuotesIcon = styled.div`
  width: 56px;
  min-width: 56px;
  height: 56px;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 48px;
    min-width: 48px;
    height: 48px;
  }
`;

const Text = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;

  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;

  @media (max-width: ${globalConstants.switchMobile}) {
    margin-top: 12px;
    margin-bottom: 12px;

    font-size: 16px;
  }
`;

export default ({
  white = false,
  name,
  date,
  text,
  avatar,
  username,
  postPublished,
  postText,
  postImage,
}) => {
  return (
    <Testimonial
      className="testimonial"
      background={white ? css.BRAND_BLUSH : css.FLAT_BOX_BACKGROUND}
      textcolor={white ? css.DARK_BLUE : "#fff"}
    >
      <TopPart>
        <Header>
          <HeaderLeft>
            <HeaderTitleRow>
              <HeaderName>{name}</HeaderName>
              <HeaderStars>
                <StarIcon>
                  <img src={starIcon} style={{ width: "100%" }} />
                </StarIcon>
                <StarIcon>
                  <img src={starIcon} style={{ width: "100%" }} />
                </StarIcon>
                <StarIcon>
                  <img src={starIcon} style={{ width: "100%" }} />
                </StarIcon>
                <StarIcon>
                  <img src={starIcon} style={{ width: "100%" }} />
                </StarIcon>
                <StarIcon>
                  <img src={starIcon} style={{ width: "100%" }} />
                </StarIcon>
              </HeaderStars>
            </HeaderTitleRow>
            <Date textcolor={white ? css.DARK_BLUE : css.TEXT_GREY}>
              {date}
            </Date>
          </HeaderLeft>
          <HeaderQuotesIcon>
            <img
              src={white ? quotesDarkIcon : quotesWhiteIcon}
              style={{ width: "100%" }}
            />
          </HeaderQuotesIcon>
        </Header>
        <Text>{text}</Text>
      </TopPart>
      <TestimonialPost
        avatar={avatar}
        username={username}
        published={postPublished}
        text={postText}
        image={postImage}
      />
    </Testimonial>
  );
};
