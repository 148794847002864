import Confetti from "react-confetti";
import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import coinsIcon from "assets/img/icons/coins-icon.svg";
import bulbIcon from "assets/img/icons/tip-bulb.svg";

const Reward = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
`;

const RewardNote = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const RewardBox = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  gap: 30px;

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const RewardIconCircle = styled.div`
  width: 150px;
  min-width: 150px;
  height: 150px;

  border-radius: 50%;
  background: ${css.FLAT_BOX_BACKGROUND};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const RewardIcon = styled.div`
  width: 70px;
  min-width: 70px;
  height: 70px;
`;

const RewardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const RewardTitle = styled.div`
  font-size: 36px;
  font-weight: 500;
`;

const RewardSubTitle = styled.div`
  color: ${css.BRAND_GREEN};
`;

const RewardNoteIcon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const RewardNoteText = styled.div`
  font-size: 13px;
`;

export default ({ reward }) => {
  return (
    <Reward>
      <RewardNote />
      <RewardBox>
        <RewardIconCircle>
          <RewardIcon>
            <img src={coinsIcon} alr="Reward" style={{ width: "100%" }} />
          </RewardIcon>
        </RewardIconCircle>
        <RewardContent>
          <RewardTitle>You found coins!</RewardTitle>
          <RewardSubTitle>
            {reward} coins have been added to your wallet
          </RewardSubTitle>
        </RewardContent>
      </RewardBox>
      <RewardNote>
        <RewardNoteIcon>
          <img src={bulbIcon} alt="Tip" style={{ width: "100%" }} />
        </RewardNoteIcon>
        <RewardNoteText>
          Continue browsing to get even more coins.
        </RewardNoteText>
      </RewardNote>
      <Confetti />
    </Reward>
  );
};
