import { useEffect, useState, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";

import ReferralStats from "./ReferralStats";
import LoadingBox from "components/other/LoadingBox";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";

export default () => {
  // Fetch data
  const GET_REFERRAL_STATS = gql`
    query GetReferralStats {
      referralStats {
        userStats {
          id
          to
          referrals
          qualified_referrals
          referral_rewards
          total_referrals
          total_qualified_referrals
          total_referral_rewards
        }
        referrals {
          id
          created_at
          referral_reward
        }
        qualifiedReferrals {
          id
          qualified_at
          referral_reward
        }
      }
    }
  `;

  const { loading, data, error } = useQuery(GET_REFERRAL_STATS, {
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching referral stats error", error);
    //captureException(error);
  }

  //console.log({ data });

  return (
    <>
      {!data && (
        <LoadingBox>
          <BoxLoadingSpinner height="161px" />
        </LoadingBox>
      )}
      {data && <ReferralStats data={data} />}
    </>
  );
};
