import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

const Item = styled(Link)`
  width: 100%;
  margin-top: 5px;

  color: #fff;
  text-decoration: none;
`;

const ItemBackground = styled.div`
  width: 100%;
  height: 42px;
  padding-left: ${(props) => (props.wide ? "40px" : "25px")};
  box-sizing: border-box;

  border-radius: ${(props) => (props.wide ? "0" : "21px")};
  background: ${(props) =>
    props.current ? css.FLAT_BOX_BACKGROUND : "transparent"};

  transition: 0.2s;

  &:hover {
    background: ${css.FLAT_BOX_BACKGROUND};
  }
`;

const ItemBox = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
`;

const ItemIcon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  box-sizing: border-box;

  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const ItemText = styled.div`
  padding-left: 15px;

  font-size: 18px;
  font-weight: ${(props) =>
    props.current || props.emphasized ? "500" : "inherit"};
  color: ${(props) => (props.emphasized ? css.BRAND_GREEN : "inherit")};
  letter-spacing: 0.2px;
`;

export default ({
  to,
  icon,
  iconCurrent,
  children,
  emphasized,
  target = "_self",
}) => {
  const location = useLocation();
  const { pathname } = location;

  const tabletScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchTablet})`,
  });

  return (
    <Item to={to} target={target}>
      <ItemBackground
        current={pathname === to ? 1 : 0}
        wide={tabletScreen ? 1 : 0}
      >
        <ItemBox>
          <ItemIcon icon={pathname === to ? iconCurrent : icon} />
          <ItemText
            current={pathname === to ? 1 : 0}
            emphasized={emphasized ? 1 : 0}
          >
            {children}
          </ItemText>
        </ItemBox>
      </ItemBackground>
    </Item>
  );
};
