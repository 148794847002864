import { useEffect, useState, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import axios from "axios";
import { isURL } from "validator";
import { useSearchParams, useNavigate } from "react-router-dom";

import { BACKEND_SERVER_URL } from "constants";
import { POST_TEMPLATES } from "constants/postTemplates";

import {
  getCountriesByPLevels,
  getCpiEstimate,
  getRandomArrayItem,
} from "modules/functions";

import CreatePost from "./CreatePost";

export default () => {
  const pickerRef = useRef();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const postId = searchParams.get("id") ? searchParams.get("id") : null;
  const duplicate = searchParams.get("duplicate") === "true" ? true : false;

  const [allCountries, setAllCountries] = useState([]);
  const [text, setText] = useState("");
  const [image, setImage] = useState(null);
  const [imageWarning, setImageWarning] = useState(null);
  const [imageLink, setImageLink] = useState("");
  const [name, setName] = useState("");
  const [pickerOpened, setPickerOpened] = useState(false);
  const [targetingOpened, setTargetingOpened] = useState(false);
  const [countries, setCountries] = useState([]);
  const [pLevel, setPLevel] = useState("l3");
  const [cpiEstimate, setCpiEstimate] = useState({});
  const [cpiFormOpened, setCpiFormOpened] = useState(false);
  const [cpi, setCpi] = useState("");
  const [warnings, setWarnings] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [textEdited, setTextEdited] = useState(false);
  const [confirmTemplate, setConfirmTemplate] = useState(false);

  const onTextInputChange = (e) => {
    setText(e.target.value);
    if (!textEdited) setTextEdited(true);
  };

  const onSmileyClick = () => {
    setPickerOpened(!pickerOpened);
  };

  const onEmojiClick = (emoji) => {
    console.log("onEmojiClick emoji", emoji);

    setText(text + emoji.emoji);
  };

  function usePickerOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPickerOpened(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  // handle post image cloud upload
  const axiosApi = axios.create({
    baseURL: BACKEND_SERVER_URL,
  });

  async function postFile(url, data, config = {}) {
    return axiosApi
      .post(url, data, { ...config })
      .then((response) => response.data);
  }

  const onImageInputChange = async (e) => {
    try {
      console.log("e.target.files[0]", e.target.files[0]);
      if (e.target.files[0]) {
        const maxFileSize = 4000;

        if (e.target.files[0].size > maxFileSize * 1000) {
          setImageWarning("tooLargeFile");
          return;
        }

        setImage(null);
        setImageWarning(null);

        const formData = new FormData();
        formData.append("file", e.target.files[0]);

        const result = await postFile("/upload/post-image", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        console.log("UPLOAD result", result);

        if (result.error) {
          console.log("File upload failed!");
          return;
        }

        if (result.fileName) {
          setImage(result.fileName);
        }
      }
    } catch (error) {
      console.log("onImageInputChange error", error);
    }
  };

  const removeImage = () => {
    setImage(null);
  };

  const openTargeting = () => {
    setTargetingOpened(true);
  };

  const closeTargeting = () => {
    setTargetingOpened(false);
  };

  // handle Image link input
  const onImageLinkInputChange = (e) => {
    setImageLink(e.target.value);
  };

  // handle Name input
  const onNameInputChange = (e) => {
    setName(e.target.value);
  };

  // get User profile data
  const GET_USER_PROFILE_DATA = gql`
    query UserProfileData {
      userProfileData {
        id
        username
        profile_image
      }
    }
  `;

  const { loading: loadingUserProfile, data: userProfileData } = useQuery(
    GET_USER_PROFILE_DATA
  );

  console.log({ userProfileData });

  // load Vountries data
  const GET_COUNTRIES = gql`
    query CountriesData {
      countriesData {
        id
        country
        cpi
      }
    }
  `;

  const { loading: loadingCountries, data: countriesData } = useQuery(
    GET_COUNTRIES,
    {
      fetchPolicy: "network-only",
    }
  );

  // save all countries to state
  useEffect(() => {
    if (!loadingCountries && countriesData) {
      const countries = countriesData.countriesData.map(
        (country) => country.country
      );

      setAllCountries(countries);
    }
  }, [loadingCountries]);

  // get existing post data if editing
  const GET_POST_DATA = gql`
    query GetPostData($postId: ID!) {
      postData(postId: $postId) {
        id
        text
        image
        image_link
        targeting
        max_cpi
        name
      }
    }
  `;

  const {
    loading: loadingPost,
    data: receivedPostData,
    error,
  } = useQuery(GET_POST_DATA, {
    variables: { postId },
    skip: !postId,
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("error", error);
    navigate("/modify-post");
  }

  useEffect(() => {
    if (!loadingPost && receivedPostData) {
      console.log("EXISTING POST DATA", receivedPostData);
      if (!receivedPostData.postData) {
        // post id not found or invalid
        navigate("/modify-post");
        return;
      }

      // populate state with existing post data
      const { postData } = receivedPostData;

      setText(postData.text);
      setImage(postData.image);
      setImageLink(postData.image_link ? postData.image_link : "");
      setCountries(postData.targeting);
      setCpi(postData.max_cpi !== 10000 ? postData.max_cpi : "");
      setName(postData.name);
    }
  }, [loadingPost]);

  // empty state values when navigated here with no postId
  useEffect(() => {
    if (!postId) {
      setText("");
      setImage(null);
      setImageLink("");
      setCountries(allCountries);
      setCpi("");
      setName("");
    }
  }, [postId]);

  // set all countries as initial if creating new post
  useEffect(() => {
    if (!postId) {
      setCountries(allCountries);
    }
  }, [allCountries]);

  // update countries on change
  const updateCountries = (countries) => {
    setCountries(countries);
  };

  // update CPI data on countries change
  useEffect(() => {
    if (countriesData) {
      const selectedCountriesData = countriesData.countriesData.filter(
        (country) => countries.includes(country.country)
      );

      const countriesByPLevels = getCountriesByPLevels(selectedCountriesData);

      //console.log({ countriesByPLevels });

      const cpiEstimate = getCpiEstimate(countriesByPLevels, pLevel);

      setCpiEstimate(cpiEstimate);
    }
  }, [countries, countriesData]);

  // handle CPI form
  const openCpiForm = () => {
    setCpiFormOpened(true);
  };

  const closeCpiForm = () => {
    setCpiFormOpened(false);
  };

  const onCpiInputChange = (e) => {
    if (/^\d*$/.test(e.target.value)) {
      let newValue = e.target.value !== "0" ? e.target.value : "";

      setCpi(newValue);
    }
  };

  // handle post submit
  const SUBMIT_POST = gql`
    mutation SubmitPost(
      $postId: ID
      $text: String!
      $image: String
      $imageLink: String
      $countries: [String!]!
      $cpi: Int
      $name: String!
    ) {
      submitPost(
        postId: $postId
        text: $text
        image: $image
        imageLink: $imageLink
        countries: $countries
        cpi: $cpi
        name: $name
      ) {
        resultCode
      }
    }
  `;

  const [submitPost] = useMutation(SUBMIT_POST);

  const onPostSubmit = async () => {
    try {
      setSubmitError(null);

      if (submitLoading) return;

      let newWarnings = [];

      if (text.length < 20) newWarnings.push("text");
      if (
        imageLink.length > 0 &&
        !isURL(imageLink, {
          protocols: ["http", "https"],
          require_valid_protocol: true,
          require_protocol: true,
        })
      )
        newWarnings.push("imageLink");
      if (countries.length === 0) newWarnings.push("targeting");

      if (newWarnings.length !== 0) {
        setWarnings(newWarnings);
        return;
      }

      setWarnings([]);
      setSubmitLoading(true);

      const submitPostId = duplicate ? null : postId;

      console.log("POSTING");

      const result = await submitPost({
        variables: {
          postId: submitPostId,
          text,
          image,
          imageLink,
          countries,
          cpi: Number(cpi),
          name,
        },
      });

      console.log("POST RESULT", result);

      setSubmitLoading(false);

      if (result.data.submitPost.resultCode !== 0) {
        const errorText = submitPostId
          ? "Failed to edit the post. Please try again later or contact support."
          : "Failed to create the post. Please try again later or contact support.";

        setSubmitError(errorText);

        return;
      }

      navigate("/posts");
    } catch (error) {
      console.log("Submit post error", error);
      setSubmitLoading(false);
      setSubmitError(
        "Failed to create or edit the post. Please try again later or contact support."
      );
    }
  };

  // handle Post template generation
  const addTemplateText = () => {
    const randomTemplate = getRandomArrayItem(POST_TEMPLATES);

    setText(randomTemplate);
    setTextEdited(false);

    if (confirmTemplate) setConfirmTemplate(false);
  };

  const getPostTemplate = () => {
    if (textEdited && text.length !== 0) setConfirmTemplate(true);
    else addTemplateText();
  };

  const closeConfirmModal = () => {
    setConfirmTemplate(false);
  };

  console.log({ postId });
  //console.log({ allCountries });

  console.log({ countries });
  // console.log({ cpiFormOpened });
  // console.log({ cpi });
  // console.log({ image });
  // console.log({ imageWarning });
  //console.log({ warnings });
  //console.log({ imageLink });

  return (
    <CreatePost
      form={postId && !duplicate ? "edit" : "create"}
      text={text}
      onTextInputChange={onTextInputChange}
      onSmileyClick={onSmileyClick}
      pickerOpened={pickerOpened}
      onEmojiClick={onEmojiClick}
      pickerRef={pickerRef}
      usePickerOutsideClick={usePickerOutsideClick}
      onImageInputChange={onImageInputChange}
      image={image}
      onImageLinkInputChange={onImageLinkInputChange}
      imageLink={imageLink}
      onNameInputChange={onNameInputChange}
      name={name}
      warnings={warnings}
      openTargeting={openTargeting}
      closeTargeting={closeTargeting}
      targetingOpened={targetingOpened}
      loading={loadingCountries || loadingUserProfile}
      allCountries={allCountries || []}
      countries={countries}
      updateCountries={updateCountries}
      cpiEstimate={cpiEstimate}
      openCpiForm={openCpiForm}
      closeCpiForm={closeCpiForm}
      cpiFormOpened={cpiFormOpened}
      cpi={cpi}
      onCpiInputChange={onCpiInputChange}
      removeImage={removeImage}
      imageWarning={imageWarning}
      onPostSubmit={onPostSubmit}
      submitLoading={submitLoading}
      submitError={submitError}
      userProfileData={userProfileData?.userProfileData}
      getPostTemplate={getPostTemplate}
      confirmTemplate={confirmTemplate}
      closeConfirmModal={closeConfirmModal}
      addTemplateText={addTemplateText}
    />
  );
};
