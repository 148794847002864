import { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import * as css from "styles/CssVariables.js";

import CashBonus from "./CashBonus";
import LoadingBox from "components/other/LoadingBox";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";

export default () => {
  const [page, setPage] = useState(1);

  // Fetch data
  const GET_CASH_BONUS_DATA = gql`
    query GetCashBonusData {
      cashBonusData {
        monthInProgress
        thisMonthQualified
      }
    }
  `;

  const { loading, data, error } = useQuery(GET_CASH_BONUS_DATA, {
    variables: {
      page,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching cash bonus error", error);
    //captureException(error);
  }

  console.log({ data });

  return (
    <>
      {!data && (
        <LoadingBox>
          <BoxLoadingSpinner height="230px" />
        </LoadingBox>
      )}
      {data && <CashBonus data={data} />}
    </>
  );
};
