import styled from "styled-components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";

import { AUTH_ID, AUTH_TOKEN, AUTH_WS_TOKEN, SHARD_NUMBER } from "constants";
import LoadingPage from "components/other/LoadingPage";

const AuthContent = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const AuthMiddleware = (props) => {
  const [authenticated, setAuthenticated] = useState(false);

  const navigate = useNavigate();

  const shardNumber = Number(localStorage.getItem(SHARD_NUMBER));

  if (!shardNumber || typeof shardNumber === NaN) {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_WS_TOKEN);
    localStorage.removeItem(SHARD_NUMBER);
    navigate("/login");
  }

  const AUTHENTICATED = gql`
    query Authenticated($shardNumber: Int!) {
      authenticated(shardNumber: $shardNumber)
    }
  `;

  const { loading, error, data } = useQuery(AUTHENTICATED, {
    variables: { shardNumber },
  });

  useEffect(() => {
    if (!loading && data) {
      if (data.authenticated) {
        setAuthenticated(true);
      } else {
        localStorage.removeItem(AUTH_ID);
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(AUTH_WS_TOKEN);
        localStorage.removeItem(SHARD_NUMBER);
        navigate("/login");
      }
    }

    if (error) {
      console.log("Auth error", error);
      navigate("/login");
    }
  }, [loading]);

  return authenticated ? (
    <AuthContent>{props.children}</AuthContent>
  ) : (
    <LoadingPage />
  );
};
