import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import ButtonPrimary from "components/buttons/ButtonPrimary";

import bulbIcon from "assets/img/icons/tip-bulb.svg";

const TipBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  justify-content: space-between;
  gap: 4vw;

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
  }
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
`;

const Title = styled.div`
  width: 100%;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  flex-direction: column;
`;

const TipWrap = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  display: flex;
`;

const TipIcon = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
`;

const TipText = styled.div`
  padding-left: 12px;

  line-height: 1.3;

  flex: 1;
`;

const ButtonWrap = styled(Link)`
  margin-top: ${css.VERTICAL_BOX_MARGIN_XLARGE};

  text-decoration: none;

  display: flex;
  align-self: flex-start;

  @media (max-width: ${globalConstants.switchMobile}) {
    margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
  }
`;

const SecondColumn = styled.div`
  padding-right: 2vw;

  display: flex;
  align-items: center;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding-right: 0;
    margin-top: ${css.VERTICAL_BOX_MARGIN};

    justify-content: center;
  }
`;

const TipImage = styled.div`
  max-width: 90px;
`;

const Image = styled.img`
  width: 100%;
`;

export default ({ title, text, icon, buttonText, to }) => {
  const mobileScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchMobile})`,
  });

  return (
    <TipBox>
      <FirstColumn>
        <Title>{title}</Title>
        <TipWrap>
          <TipIcon>
            <img src={bulbIcon} alt="Tip" style={{ width: "100%" }} />
          </TipIcon>
          <TipText>{text}</TipText>
        </TipWrap>
        {!mobileScreen && (
          <ButtonWrap to={to}>
            <ButtonPrimary>{buttonText}</ButtonPrimary>
          </ButtonWrap>
        )}
      </FirstColumn>
      <SecondColumn>
        <TipImage>
          <Image src={icon} alt={title} />
        </TipImage>
      </SecondColumn>
      {mobileScreen && (
        <ButtonWrap to={to}>
          <ButtonPrimary>{buttonText}</ButtonPrimary>
        </ButtonWrap>
      )}
    </TipBox>
  );
};
