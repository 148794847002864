import styled from "styled-components";
import { useEffect, useState, useRef } from "react";
import { gql, useQuery, useMutation, useSubscription } from "@apollo/client";
import { useSearchParams, useNavigate } from "react-router-dom";

import globalConstants from "constants/globalConstants";
import Chat from "./Chat";
import PostPreview from "./PostPreview";

const PostPreviewContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  transform: translateX(-50%) translateY(-50%);

  max-width: 442px;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 200;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 100%;
  }
`;

export default ({
  postId,
  postSnapshotId,
  postShardNumber,
  snapshot,
  handleModalClose,
}) => {
  console.log({ postId, postSnapshotId, postShardNumber, snapshot });

  useEffect(() => {
    if (!postId || !postShardNumber) {
      // missing Post ID or Post shard number
    }
  }, []);

  // Fetch post data
  const GET_POST_PREVIEW_DATA = gql`
    query GetPostPreviewData(
      $postId: ID
      $postSnapshotId: ID
      $shardNumber: Int!
    ) {
      postPreviewData(
        postId: $postId
        postSnapshotId: $postSnapshotId
        shardNumber: $shardNumber
      ) {
        id
        created_at
        text
        image
        image_link
        user {
          id
          username
          profile_image
        }
      }
    }
  `;

  const { loading, data, error } = useQuery(GET_POST_PREVIEW_DATA, {
    variables: {
      postId,
      postSnapshotId,
      shardNumber: Number(postShardNumber),
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("error fetching post preview data", error);
    //captureException(error);
  }

  // console.log("data", data);

  return (
    <PostPreviewContainer>
      <PostPreview handleModalClose={handleModalClose} data={data} />
    </PostPreviewContainer>
  );
};
