import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";

import padlockIcon from "assets/img/icons/padlock-icon.svg";
import padlockGreyIcon from "assets/img/icons/padlock-icon-grey.svg";
import referralsQualifiedGreyIcon from "assets/img/icons/referral-qualified-icon-grey.svg";
import referralsQualifiedIcon from "assets/img/icons/referral-qualified-icon.svg";
import dollarIcon from "assets/img/icons/dollar-icon.svg";
import cashGreenIcon from "assets/img/icons/cash-icon-green.svg";
import { useNavigate } from "react-router-dom";

const CashBonusBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const BoxTitle = styled.div`
  width: 100%;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  align-items: center;
  gap: 8px;
`;

const DollarIcon = styled.div`
  width: 22px;
  min-width: 22px;
  height: 22px;
`;

const Text = styled.div`
  line-height: 125%;
`;

const CashBonusSummary = styled.div`
  width: 100%;
  padding: 15px 20px;
  margin-top: 18px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const MonthBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Month = styled.div`
  font-size: 18px;
`;

const Badge = styled.div`
  height: 22px;
  padding: 0 12px;

  font-size: 13px;

  border-radius: 11px;
  background: ${css.POSITIVE_GREEN};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const BarLines = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 7px;
`;

const BarLine = styled.div`
  width: 100%;

  color: ${(props) => (props.grey ? "#9F9F9F" : "#fff")};

  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: ${globalConstants.switchMobile}) {
    gap: 12px;
  }
`;

const BonusBox = styled.div`
  min-width: 104px;

  display: flex;
  align-items: center;
  gap: 5px;

  @media (max-width: ${globalConstants.switchMobile}) {
    min-width: 94px;
  }
`;

const CounterBox = styled.div`
  min-width: 63px;

  display: flex;
  align-items: center;
  gap: 5px;

  @media (max-width: ${globalConstants.switchMobile}) {
    min-width: 58px;
  }
`;

const IconTextBoxIcon = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
`;

const IconTextBoxText = styled.div`
  font-weight: 500;
  color: ${(props) => (props.green ? css.BRAND_GREEN : "inherit")};

  display: flex;
  align-items: center;
  gap: 5px;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 14px;
  }
`;

const Bar = styled.div`
  position: relative;
  height: 20px;

  overflow: hidden;

  border-radius: 10px;
  background: ${(props) => (props.grey ? "#617489" : css.BRAND_INDIGO)};

  flex: 1;
`;

const BarProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${(props) => props.progressPercent}%;
  box-sizing: border-box;

  background: ${css.BRAND_GREEN};
`;

const ButtonWrap = styled.div`
  margin-top: 18px;

  display: flex;
`;

const CashBonusSmallInfo = styled.div`
  margin-top: 10px;

  font-size: 13px;
  color: ${css.TEXT_GREY};
  line-height: 125%;
`;

export default ({ data }) => {
  const navigate = useNavigate();

  const { monthInProgress, thisMonthQualified } = data.cashBonusData;

  const bonusAmounts = [50, 100, 200, 400];

  const activeLevel = Math.floor(thisMonthQualified / 50) + 1;
  console.log({ activeLevel });

  const barLines = bonusAmounts.map((amount, index) => {
    const lineNumber = index + 1;

    const greyed = lineNumber > activeLevel;

    let progress = 0;
    let progressPercent = 0;
    let unlocked = false;

    if (lineNumber < activeLevel) {
      unlocked = true;
      progress = 50;
      progressPercent = 100;
    }

    if (lineNumber === activeLevel) {
      progress = thisMonthQualified - index * 50;
      progressPercent = (100 / 50) * (thisMonthQualified % 50);
    }

    console.log({ greyed, progressPercent, progress });

    return (
      <BarLine grey={greyed ? 1 : 0}>
        <BonusBox>
          <IconTextBoxIcon>
            {!unlocked && (
              <img
                src={greyed ? padlockGreyIcon : padlockIcon}
                style={{ width: "100%" }}
              />
            )}
            {unlocked && (
              <img
                src={cashGreenIcon}
                style={{ width: "100%", paddingTop: "1px" }}
              />
            )}
          </IconTextBoxIcon>
          <IconTextBoxText green={unlocked ? 1 : 0}>
            ${amount} Bonus
          </IconTextBoxText>
        </BonusBox>
        <CounterBox>
          <IconTextBoxIcon>
            <img
              src={greyed ? referralsQualifiedGreyIcon : referralsQualifiedIcon}
              style={{ width: "100%" }}
            />
          </IconTextBoxIcon>
          <IconTextBoxText>{progress}/50</IconTextBoxText>
        </CounterBox>
        <Bar grey={greyed ? 1 : 0}>
          <BarProgress progressPercent={progressPercent} />
        </Bar>
      </BarLine>
    );
  });

  return (
    <CashBonusBox>
      <BoxTitle>
        <DollarIcon>
          {" "}
          <img src={dollarIcon} style={{ width: "100%" }} />
        </DollarIcon>
        Earn Money With Our Referral Program
      </BoxTitle>
      <Text>
        Refer enough users in one month and receive a generous bonus. Unlock
        bonuses one by one and earn up to $750 per month.
      </Text>
      <CashBonusSummary>
        <MonthBox>
          <Month>{monthInProgress}</Month>
          <Badge>In progress</Badge>
        </MonthBox>
        <BarLines>{barLines}</BarLines>
      </CashBonusSummary>
      <ButtonWrap>
        <ButtonPrimarySmall onClick={() => navigate("/settings")}>
          Payout Setup
        </ButtonPrimarySmall>
      </ButtonWrap>
      <CashBonusSmallInfo>
        * Only qualified referrals count towards the calculation of the cash
        bonus. The referred user must view at least 40 posts to become
        qualified.
      </CashBonusSmallInfo>
    </CashBonusBox>
  );
};
