import styled from "styled-components";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

import { AUTH_TOKEN } from "constants";
import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import ButtonPrimary from "./buttons/ButtonPrimary.js";
import ButtonSecondary from "./buttons/ButtonSecondary.js";

import menuIcon from "assets/img/icons/icons8-menu.svg";
import scalelupLogo from "assets/img/logo/scalelup.svg";

// Actions
import { openModal, openHidingMenuPublic } from "store/actions";

const HeaderPublic = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  margin: 0;
  z-index: 10;
  backdrop-filter: ${(props) => (props.blur ? "blur(6px)" : "none")};

  visibility: hidden;
`;

const HeaderContent = styled.div`
  padding-top: ${(props) => (props.topOfScreen ? "30px" : "15px")};
  padding-right: 40px;
  padding-bottom: ${(props) => (props.topOfScreen ? "20px" : "6px")};
  padding-left: 40px;
  margin: 0 auto;
  max-width: 1600px;

  transition: 0.2s;

  display: flex;
  justify-content: space-between;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding-top: ${(props) => (props.topOfScreen ? "20px" : "15px")};
    padding-right: 20px;
    padding-bottom: ${(props) => (props.topOfScreen ? "20px" : "6px")};
    padding-left: 20px;
  }
`;

const LogoWrap = styled.div`
  width: 160px;

  transition: 0.2s;
  transform: ${(props) => `scale(${props.scale})`};
  transform-origin: top left;

  @media (max-width: ${globalConstants.switchMobile}) {
    transform: scale(1);
  }

  &:hover {
    cursor: pointer;
  }
`;

const RightPart = styled.div`
  display: flex;
  align-items: center;
`;

const SignInButtons = styled.div`
  transition: 0.2s;
  transform: ${(props) => `scale(${props.scale})`};
  transform-origin: top right;

  display: flex;
  align-items: center;
  gap: ${css.BUTTONS_GAP};
`;

const MenuIcon = styled.div`
  width: 28px;
  aspect-ratio: 1;
`;

export default ({ topOfScreen }) => {
  const container = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mobileScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchMobile})`,
  });

  const hasToken = localStorage.getItem(AUTH_TOKEN) ? true : false;

  const openLogin = () => {
    dispatch(openModal("login"));
  };

  const openSignup = () => {
    dispatch(openModal("signup"));
  };

  const openDashboard = () => {
    window.location = "/home";
  };

  //landing page entry animation
  useGSAP(
    () => {
      const headerPublic = document.getElementById("header-public");

      gsap
        .timeline()
        .to(headerPublic, { autoAlpha: 1 })
        .from(headerPublic, { yPercent: -100 });
    },
    { dependencies: [] }
  );

  const openMenu = () => {
    dispatch(openHidingMenuPublic());
  };

  return (
    <HeaderPublic blur={topOfScreen ? 0 : 1} ref={container} id="header-public">
      <HeaderContent topOfScreen={topOfScreen}>
        <LogoWrap
          onClick={() => navigate("/")}
          scale={topOfScreen ? "1" : "0.9"}
        >
          <img src={scalelupLogo} style={{ width: "100%" }} />
        </LogoWrap>
        <RightPart>
          {!mobileScreen && (
            <>
              {!hasToken && (
                <SignInButtons scale={topOfScreen ? "1" : "0.9"}>
                  <ButtonSecondary onClick={openLogin}>Log In</ButtonSecondary>
                  <ButtonPrimary onClick={openSignup}>Sign Up</ButtonPrimary>
                </SignInButtons>
              )}
              {hasToken && (
                <SignInButtons scale={topOfScreen ? "1" : "0.9"}>
                  <ButtonPrimary onClick={openDashboard}>
                    Open Dashboard
                  </ButtonPrimary>
                </SignInButtons>
              )}
            </>
          )}
          {mobileScreen && (
            <MenuIcon onClick={openMenu}>
              <img src={menuIcon} alt="Menu" style={{ width: "100%" }} />
            </MenuIcon>
          )}
        </RightPart>
      </HeaderContent>
    </HeaderPublic>
  );
};
