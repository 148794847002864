import React from "react";
import styled from "styled-components";

const Backdrop = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: ${(props) =>
    props.background ? props.background : "rgba(0, 0, 0, 0.2)"};
  z-index: ${(props) => (props.zIndex ? props.zIndex : "190")};
`;

export default (props) => {
  const { onClick, parameters, background, zIndex } = props;

  return (
    <Backdrop
      onClick={() => onClick(parameters)}
      background={background}
      zIndex={zIndex}
    />
  );
};
