import styled from "styled-components";
import AvatarEditor from "react-avatar-editor";
import Slider from "@mui/material/Slider";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import Layout from "components/Layout";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import SectionTitle from "components/text/SectionTitle";
import InputWithLabel from "components/forms/InputWithLabel";
import InputText from "components/forms/InputText";
import ButtonIndigoIcon from "components/buttons/ButtonIndigoIcon";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import ButtonWhite from "components/buttons/ButtonWhite";
import RoundActionIcon from "components/buttons/RoundActionIcon";
import ButtonPrimarySmallIcon from "components/buttons/ButtonPrimarySmallIcon";
import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";
import ButtonWhiteSmall from "components/buttons/ButtonWhiteSmall";

import aiEditIcon from "assets/img/icons/ai-edit-icon.svg";
import expandArrowLeftIcon from "assets/img/icons/icons8-expand-arrow-left.svg";
import expandArrowRightIcon from "assets/img/icons/icons8-expand-arrow-right.svg";
import uploadIcon from "assets/img/icons/icons8-upload.svg";
import uploadWhiteIcon from "assets/img/icons/icons8-upload-white.svg";

const UpdateProfile = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

const Box = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
  font-size: 24px;
`;

const Instructions = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};
  max-width: 380px;

  line-height: 125%;
  text-align: center;
`;

const Buttons = styled.div`
  margin-top: 80px;
  margin-bottom: 12px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: ${(props) => (props.wrapOnMobile ? "column" : "row")};
    align-items: ${(props) => (props.wrapOnMobile ? "flex-start" : "center")};
    gap: ${(props) => (props.wrapOnMobile ? css.VERTICAL_BOX_MARGIN : 0)};
  }
`;

const PictureSelector = styled.div`
  width: 100%;
  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PictureGallery = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const PictureArrowBox = styled.div``;

const PictureImageBox = styled.div`
  position: relative;
  width: 112px;
  height: 112px;
  margin: 0 15px;
`;

const PictureCircle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 114px;
  min-width: 114px;
  height: 114px;
`;

const PictureAvatar = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  min-width: 100px;
  height: 100px;

  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;

  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const PictureUploadButtonWrap = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const Picker = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const SliderWrap = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};
`;

const PickerButtons = styled.div`
  width: 100%;
  margin-top: ${css.VERTICAL_BOX_MARGIN};
  margin-bottom: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${css.BUTTONS_GAP_SMALL};
`;

export default ({
  isOnboarding,
  step,
  username,
  defaultUsername,
  handleInputChange,
  loading,
  onFirstContinueClick,
  onBackClick,
  onGenerateClick,
  profilePicture,
  onMoveClick,
  cropperOpen,
  picture,
  setEditorRef,
  handleFileChange,
  handleUploadSlider,
  handleUploadCancel,
  handleUploadSave,
  onSecondContinueClick,
  onboarding,
}) => {
  const title = isOnboarding ? "Prepare Your Account" : "Update Your Profile";
  const usernameInfo = isOnboarding
    ? "Enter your name, business name, or nickname. You will be able to change it later."
    : "Enter your name, business name, or nickname. It will be visible to others.";

  return (
    <Layout noColumns={isOnboarding}>
      <UpdateProfile>
        <SectionTitle>
          {title}: Step {step} of 2
        </SectionTitle>
        <Box>
          {!loading && (
            <>
              {step === 1 && (
                <>
                  <Heading>What Should People Call You?</Heading>
                  <Instructions>{usernameInfo}</Instructions>
                  <InputWithLabel
                    label="Username"
                    width="300px"
                    marginTop={css.VERTICAL_BOX_MARGIN_MEDIUM}
                  >
                    <InputText
                      type="text"
                      name="username"
                      value={username}
                      placeholder={defaultUsername}
                      onChange={handleInputChange}
                      maxLength={21}
                      autoFocus={onboarding === 0 && true}
                    />
                  </InputWithLabel>
                  <Buttons wrapOnMobile={true}>
                    <ButtonIndigoIcon
                      icon={aiEditIcon}
                      iconHover={aiEditIcon}
                      iconSize="18px"
                      onClick={onGenerateClick}
                    >
                      Generate Username
                    </ButtonIndigoIcon>
                    <ButtonPrimary onClick={onFirstContinueClick}>
                      Continue
                    </ButtonPrimary>
                  </Buttons>
                </>
              )}
              {step === 2 && (
                <>
                  <Heading>Pick a Profile Picture</Heading>
                  {!cropperOpen && (
                    <>
                      <Instructions>
                        Choose an image or upload your own photo.
                      </Instructions>
                      <PictureSelector>
                        <PictureGallery>
                          <PictureArrowBox>
                            <RoundActionIcon
                              icon={expandArrowLeftIcon}
                              onClick={() => onMoveClick("left")}
                            />
                          </PictureArrowBox>
                          <PictureImageBox>
                            <PictureCircle>
                              <svg
                                viewBox="0 0 114 114"
                                style={{ height: "114px", width: "114px" }}
                              >
                                <circle
                                  cx="56"
                                  cy="56"
                                  r="55"
                                  style={{
                                    fill: "transparent",
                                    stroke: "rgb(255, 255, 255)",
                                    strokeWidth: 1,
                                    strokeDasharray: "10, 8",
                                  }}
                                />
                              </svg>
                            </PictureCircle>
                            <PictureAvatar image={profilePicture} />
                          </PictureImageBox>
                          <PictureArrowBox>
                            <RoundActionIcon
                              icon={expandArrowRightIcon}
                              onClick={() => onMoveClick("right")}
                            />
                          </PictureArrowBox>
                        </PictureGallery>
                        <PictureUploadButtonWrap>
                          <input
                            id="uploadInput"
                            style={{ display: "none" }}
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={handleFileChange}
                          />
                          <ButtonPrimarySmallIcon
                            icon={uploadIcon}
                            iconHover={uploadWhiteIcon}
                            onClick={() =>
                              document.getElementById("uploadInput").click()
                            }
                          >
                            Upload
                          </ButtonPrimarySmallIcon>
                        </PictureUploadButtonWrap>
                      </PictureSelector>
                      <Buttons>
                        <ButtonWhite onClick={onBackClick}>Back</ButtonWhite>
                        <ButtonPrimary onClick={onSecondContinueClick}>
                          Continue
                        </ButtonPrimary>
                      </Buttons>
                    </>
                  )}
                  {cropperOpen && (
                    <Picker>
                      <AvatarEditor
                        ref={setEditorRef}
                        image={picture.img}
                        width={150}
                        height={150}
                        border={50}
                        borderRadius={150}
                        color={[255, 255, 255, 0.6]} // RGBA
                        rotate={0}
                        scale={picture.zoom}
                      />

                      <SliderWrap>
                        <Slider
                          aria-label="raceSlider"
                          value={picture.zoom}
                          min={1}
                          max={10}
                          step={0.1}
                          onChange={handleUploadSlider}
                          sx={{ color: css.BRAND_INDIGO }}
                        ></Slider>
                      </SliderWrap>
                      <PickerButtons>
                        <ButtonWhiteSmall onClick={handleUploadCancel}>
                          Cancel
                        </ButtonWhiteSmall>
                        <ButtonPrimarySmall onClick={handleUploadSave}>
                          Save
                        </ButtonPrimarySmall>
                      </PickerButtons>
                    </Picker>
                  )}
                </>
              )}
            </>
          )}
          {loading && <BoxLoadingSpinner height="320px" />}
        </Box>
      </UpdateProfile>
    </Layout>
  );
};
