import styled from "styled-components";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import * as css from "../styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import BalanceBoxContainer from "./other/BalanceBoxContainer.js";

import scalelupLogo from "assets/img/logo/scalelup.svg";

const Header = styled.div`
  width: 100%;
  margin: 0;

  position: relative;
  backdrop-filter: blur(6px);
  z-index: 10;
`;

const HeaderContent = styled.div`
  padding: 30px 40px;
  margin: 0 auto;
  max-width: 1600px;

  display: flex;
  justify-content: space-between;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding: 20px;
  }
`;

const LogoWrap = styled.div`
  width: 170px;

  &:hover {
    cursor: pointer;
  }
`;

const RightPart = styled.div``;

export default () => {
  // set page Title
  useEffect(() => {
    document.title = "Bizmatcher";
  }, []);

  const navigate = useNavigate();

  return (
    <Header>
      <HeaderContent>
        <LogoWrap onClick={() => navigate("/")}>
          <img src={scalelupLogo} style={{ width: "100%" }} />
        </LogoWrap>
        <RightPart>
          <BalanceBoxContainer />
        </RightPart>
      </HeaderContent>
    </Header>
  );
};
