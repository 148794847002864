import React from "react";
import styled from "styled-components";
import MoonLoader from "react-spinners/MoonLoader";

const BoxLoadingSpinner = styled.div`
  width: 100%;
  height: ${(props) => (props.height ? props.height : "400px")};
  padding: ${(props) => (props.padding ? props.padding : "0")};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ({ height, padding }) => {
  return (
    <BoxLoadingSpinner height={height} padding={padding}>
      <MoonLoader color="rgba(255,255,255,1)" size={30} />
    </BoxLoadingSpinner>
  );
};
