import styled from "styled-components";

import * as css from "styles/CssVariables.js";

import Tip from "components/other/Tip";

const InputWithLabel = styled.div`
  width: ${(props) => props.width};
  margin-top: ${(props) => (props.margintop ? props.margintop : "0px")};

  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  padding-left: 4px;

  display: flex;
  align-items: center;
  gap: 6px;
`;

const LabelText = styled.div`
  font-weight: 600;
`;

const InputWrap = styled.div`
  margin-top: 8px;

  display: flex;
  flex-direction: column;
`;

export default ({ label, children, width, marginTop, tip }) => {
  return (
    <InputWithLabel width={width} margintop={marginTop}>
      <Label>
        <LabelText>{label}</LabelText>
        {tip && <Tip tip={tip} />}
      </Label>
      <InputWrap>{children}</InputWrap>
    </InputWithLabel>
  );
};
