import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import linkIcon from "assets/img/icons/icons8-link.svg";

const PostBox = styled.div`
  position: relative;
  width: 100%;
  height: 310px;
  padding: 25px 30px 0px 30px;
  box-sizing: border-box;
  z-index: 1;

  color: #fff;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.4);

  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding: 20px 25px 0px 25px;
  }
`;

const PostContent = styled.div`
  width: 100%;
  padding: 0;

  display: flex;
  flex-direction: column;
`;

const PostHeader = styled.div`
  display: flex;
  gap: 8px;
`;

const AvatarIconWrap = styled.div`
  width: 48px;
  min-width: 48px;
  aspect-ratio: 1;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 40px;
    min-width: 40px;
  }
`;

const AvatarIcon = styled.img`
  width: 100%;
  border-radius: 50%;
`;

const PostHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Username = styled.div`
  padding-top: 7px;

  font-size: 16px;
  font-weight: 500;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding-top: 2px;
  }
`;

const TimePosted = styled.div`
  padding-top: 1px;

  font-size: 13px;
  color: ${css.TEXT_GREY};
`;

const Main = styled.div``;

const Text = styled.div`
  margin-top: 8px;

  font-family: "Roboto";
  font-size: 15px;
  line-height: 135%;
  white-space: pre-line;

  > a {
    color: ${css.LIGHT_BLUE};
    text-decoration: none;
  }
`;

const ImageLinkWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ImageBox = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
  width: 100%;
  overflow: hidden;

  text-align: center;

  border-radius: 10px 10px 0 0;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-height: 320px;
  object-fit: contain;
`;

const ImageLink = styled.div`
  margin-top: 2px;
  width: 100%;

  text-decoration: none;

  display: flex;
  align-items: center;
`;

const ImageLinkIcon = styled.div`
  width: 15px;
  min-width: 15px;
  aspect-ratio: 1;
`;

const ImageLinkText = styled.div`
  padding-left: 2px;
  padding-bottom: 2px;
  max-width: 200px;

  font-size: 11px;
  color: ${css.TEXT_GREY};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: 0.2s;

  &:hover {
    color: #e9e9e9;
  }
`;

export default ({ avatar, username, published, text, image, imageLink }) => {
  return (
    <PostBox>
      <PostContent>
        <PostHeader>
          <AvatarIconWrap>
            <AvatarIcon src={avatar} />
          </AvatarIconWrap>
          <PostHeaderContent>
            <Username>{username}</Username>
            <TimePosted>{published} ago</TimePosted>
          </PostHeaderContent>
        </PostHeader>
        <Main>
          <Text>{text}</Text>
          <ImageLinkWrap>
            {image && (
              <ImageBox>
                <Image src={image} id="postImage" />
              </ImageBox>
            )}
            {image && imageLink && (
              <ImageLink>
                <ImageLinkIcon>
                  <img src={linkIcon} style={{ width: "100%" }} />
                </ImageLinkIcon>
                <ImageLinkText>{imageLink}</ImageLinkText>
              </ImageLink>
            )}
          </ImageLinkWrap>
        </Main>
      </PostContent>
    </PostBox>
  );
};
