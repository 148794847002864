import styled from "styled-components";
import { useEffect, useRef } from "react";
import EmojiPicker from "emoji-picker-react";
import { useMediaQuery } from "react-responsive";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import ButtonRoundIcon from "components/buttons/ButtonRoundIcon";

import smileyIcon from "assets/img/icons/icons8-smiley.svg";
import sendIcon from "assets/img/icons/icons8-send.svg";
import sendWhiteIcon from "assets/img/icons/icons8-send-white.svg";

const FormWrap = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputLine = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MessageForm = styled.div`
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  background: ${css.DARK_BLUE};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  align-items: flex-end;
`;

const TextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  padding: 12px 20px 10px;
  height: 42px;

  font-family: "Roboto";
  font-weight: 300;
  font-size: 15px;
  color: #fff;
  line-height: 125%;

  background: ${css.DARK_BLUE};
  border: 0px;
  border-radius: ${css.BOX_RADIUS_SECONDARY};
  resize: none;
`;

const EmojiBox = styled.div`
  padding-bottom: 3px;
  padding-right: 6px;
`;

const EmojiBoxIconCircle = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;

  border-radius: 18px;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgb(255, 255, 255, 0.07);
    cursor: pointer;
  }
`;

const EmojiIcon = styled.div`
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  height: ${(props) => props.width};

  &:hover {
    cursor: pointer;
  }
`;

const SendIconBox = styled.div``;

const PickerWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ErrorMessage = styled.div`
  margin-top: -10px;

  font-weight: 500;
  color: ${css.NEGATIVE_RED};
  text-align: center;
`;

export default ({
  text,
  onTextInputChange,
  onSmileyClick,
  pickerOpened,
  pickerRef,
  onEmojiClick,
  usePickerOutsideClick,
  onSendClick,
  onKeyDown,
  onKeyUp,
  loading,
  onInputFocus,
  error,
}) => {
  const textArea = useRef();

  const tabletScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchTablet})`,
  });

  // handle Textarea height
  useEffect(() => {
    const requestedHeight = Math.max(textArea.current.scrollHeight, 30);

    textArea.current.style.height = `${Math.min(requestedHeight, 600)}px`;
  }, [text]);

  usePickerOutsideClick(pickerRef);

  return (
    <FormWrap>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <InputLine>
        <MessageForm>
          <TextArea
            name="message"
            ref={textArea}
            value={text}
            placeholder="Your message"
            maxLength={1500}
            onChange={onTextInputChange}
            onFocus={onInputFocus}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            autoFocus
          />
          <EmojiBox>
            <EmojiBoxIconCircle onClick={onSmileyClick}>
              <EmojiIcon width="20px">
                <img src={smileyIcon} style={{ width: "100%" }} />
              </EmojiIcon>
            </EmojiBoxIconCircle>
          </EmojiBox>
        </MessageForm>
        <SendIconBox>
          <ButtonRoundIcon
            icon={sendIcon}
            hoverIcon={sendWhiteIcon}
            background={css.BRAND_YELLOW}
            hoverBackground={css.BRAND_GREEN}
            iconSize="15px"
            onClick={onSendClick}
            disabled={loading}
          />
        </SendIconBox>
      </InputLine>
      <PickerWrap ref={pickerRef}>
        <EmojiPicker
          open={pickerOpened}
          onEmojiClick={onEmojiClick}
          autoFocusSearch={false}
          theme="dark"
          width={tabletScreen ? 280 : 480}
          height={260}
        />
      </PickerWrap>
    </FormWrap>
  );
};
